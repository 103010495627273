import { css } from "@emotion/css";

export const useaddUserStyle = () => {
  return {
    root: css`
      width: 100%;
      padding-bottom: 20px;
 
      .react-tel-input .form-control {
        height: 48px;
        border-top: none;
        border-left: none;
        border-right: none;
        width: 100%;
        font-size: 16px;
      }
      .react-tel-input .flag-dropdown {
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: white;
        border-radius: none;
      }
    `,
  };
};
