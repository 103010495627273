import AddAdvertisement from "@/screens/AddAdvertisement";
import AddAgents from "@/screens/AddAgents";
import AddBazaar from "@/screens/AddBazaar";
import AddBranch from "@/screens/AddBranch";
import AddNewPlan from "@/screens/AddNewPlan";
import AddProduct from "@/screens/AddProduct";
import AddRetailers from "@/screens/AddRetailers";
import AddWholeseller from "@/screens/AddWholeseller";
import MasterList from "@/screens/Admin/MasterList";
import Advertisement from "@/screens/Advertisement";
import Agent from "@/screens/Agent";
import AgentKYC from "@/screens/AgentKYC";
import AgentEDIT from "@/screens/AgentEDIT";
import BazaarReport from "@/screens/BazaarReport";
import Bazaars from "@/screens/Bazars";
import Branch from "@/screens/Branch";
import BranchPlan from "@/screens/BranchPlan";
import BulkUpload from "@/screens/BulkUpload";
import CreateOffer from "@/screens/CreateOffer";
import CreateOrder from "@/screens/CreateOrder";
import Dashboard from "@/screens/Dashboard";
import EditOrder from "@/screens/EditOrder";
import ElectronicsBazaar from "@/screens/ElectronicsBazaar";
import ItemWisePlan from "@/screens/ItemWisePLan";
import Login from "@/screens/Login";
import Mwb from "@/screens/Mwb/Mwb";
import Offers from "@/screens/Offers";
import Order from "@/screens/Order";
import PhotoOrder from "@/screens/PhotoOrder";
import Plans from "@/screens/Plans";
import PlanEDIT from "@/screens/PlanEDIT"
import Products from "@/screens/Products";
import Retailers from "@/screens/Retailers";
import RevenewPlan from "@/screens/RevenewPlan";
import ReviewPlan from "@/screens/ReviewPlan";
import SubCategories from "@/screens/SubCategories";
import TrackOrder from "@/screens/TrackOrder";
import Wholeseller from "@/screens/Wholeseller";
import WholesellerDashboard from "@/screens/WholesellerDashboard";
import WholesellerKYC from "@/screens/WholesellerKYC";
import WholesellerLis from "@/screens/WholesellerList";
import WholesellerMart from "@/screens/WholesellerMart";
import RolePermission from "@/screens/RolePermission";
import RolesPermission from "@/screens/RolePermission/RolesPermission";
import AddRols from "@/screens/RolePermission/addRols";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { routePaths } from "./routePaths";
import AgentCommissionRedeemModel from "@/screens/AgentCommissionRedeemModel";
import AgentCommissionRedeemModelForm from "@/screens/AgentCommissionRedeemModelForm";
import AgentDetails from "@/screens/AgentDetails";
import PaymentDetails from "@/components/molecules/PaymentDetails/PaymentDetails";
import PaymentRequest from "@/screens/PaymentRequest";
import MakePayment from "@/screens/MakePayment";
import WholesalerDetails from "@/screens/WholesalerDetails/WholesalerDetails";
import CreateAdsnewplan from "@/screens/AddnewAdsplan";
import InvoiceViewData from "@/screens/InvoiceViewData";
import InvoiceView from "@/screens/invoiceAds";
import CreateClientAd from "@/screens/CreateClientAd";
import PayInvoice from "@/screens/Advertisement/PayInvoice/PayInvoice";
import { useAuth } from "@/context/authContext";
import NotFoundPage from "@/static/stylesheets/screens/404";
import UnAuthorisedPage from "@/static/stylesheets/screens/unAuthorised";
import ProtectedRoute from "./privateRoutes";
import AddonPlans from "@/screens/AddonPlans";
import AddAddonPlan from "@/screens/AddAddonPlan";
import SelectAddonPlans from "@/screens/WholesalerDetails/WholesalerPlan/SelectAddons";
import PlanPaymentDetails from "@/screens/WholesalerDetails/WholesalerPlan/BuyAddon";
import UpdateBranch from "@/screens/UpdateBranch";
import SelectPlans from "@/screens/WholesalerDetails/WholesalerPlan/SelectPlans";
import BuyPlay from "@/screens/WholesalerDetails/WholesalerPlan/UpgradePlan";
import React, { useEffect } from "react";
import CheckRoute from "@/components/layouts/CheckRoute";

const AppRoutes = (props: any) => {
  const { isAuthenticated, checkLogin, permissions } = useAuth();

 

  // Redirect to the first page the user has access to
 


  // const defaultRoute = getDefaultRoute();



  return (
    <>
      <Router>
        <Routes>
          {
            !isAuthenticated ? (
              <>
                <Route path={routePaths.root} element={<Login />} />
                <Route path={routePaths.login} element={<Login />} />
                <Route path="*" element={<Navigate
                  to='/login'
                />} />
              </>
            ) : (
              <>
                {/* <Route path={routePaths.root} element={<Login />} />
          <Route path={routePaths.login} element={<Login />} /> */}
                <Route path="404" element={<NotFoundPage/>} />
                <Route path="401" element={<UnAuthorisedPage/>} />
                {/* <Route path={routePaths.mwb} element={<Mwb />} /> */}
                <Route path={routePaths.dashboard} element={<ProtectedRoute requiredPermission="dashboard.summary" element={<Dashboard />}/>} />
                <Route path={routePaths.newbazaars} element={<ProtectedRoute requiredPermission="bazaar.create" element={<AddBazaar />}/>} />
                <Route path={routePaths.updatebazaars} element={<ProtectedRoute requiredPermission="bazaar.update" element={<AddBazaar />}/>} />
                {/* <Route path={routePaths.wholeseller} element={<ProtectedRoute requiredPermission="bazaar.create" element={<Wholeseller />}/>} /> */}
                <Route
                  path={routePaths.wholesellerlist}
                  element={<ProtectedRoute requiredPermission="wholeseller.list" element={<WholesellerLis handleModalBackdrop={props.handleModalBackdrop} />}/>}
                />
                {/* <Route
                  path={routePaths.wholesellerdashboard}
                  element={<WholesellerDashboard />}
                />
                <Route
                  path={routePaths.wholesellermart}
                  element={<WholesellerMart />}
                /> */}
                {/* <Route path={routePaths.wholesellerproducts} element={<Products handleModalBackdrop={props.handleModalBackdrop} />} /> */}
                <Route path={routePaths.agents} element={<ProtectedRoute requiredPermission="agent.list" element={<Agent handleModalBackdrop={props.handleModalBackdrop} />}/>} />
                <Route path={routePaths.addagent} element={<ProtectedRoute requiredPermission="agent.create" element={<AddAgents />}/>} />
                <Route path={routePaths.addagentId} element={<ProtectedRoute requiredPermission="agent.update" element={<AddAgents />}/>} />
                <Route path={routePaths.updatebranch} element={<ProtectedRoute requiredPermission="agent.update" element={<UpdateBranch />}/>} />
                <Route path={routePaths.CreateAdclient} element={<ProtectedRoute requiredPermission="ads_client.create" element={<CreateClientAd />}/>} />
                <Route path={routePaths.editAdclient} element={<ProtectedRoute requiredPermission="ads_client.update" element={<CreateClientAd type="edit"/>}/>} />
                <Route path={routePaths.viewAdclient} element={<ProtectedRoute requiredPermission="ads_client.list" element={<CreateClientAd type="view" />}/>} />
                <Route path={routePaths.CreateAdsplan} element={<ProtectedRoute requiredPermission="ads_plan.create" element={<CreateAdsnewplan />}/>} />
                <Route path={routePaths.EditAdsplan} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<CreateAdsnewplan type="edit" />}/>} />
                <Route path={routePaths.ViewAdsplan} element={<ProtectedRoute requiredPermission="ads_plan.list" element={<CreateAdsnewplan type="view" />}/>} />
                <Route path={routePaths.Createinvoiceview} element={<ProtectedRoute requiredPermission="ads_invoices.list" element={<InvoiceView />}/>} />
                <Route path={routePaths.CreateinvoiceviewData} element={<ProtectedRoute requiredPermission="ads_invoices.list" element={<InvoiceViewData />}/>} />
                <Route path={routePaths.agentkyc} element={<ProtectedRoute requiredPermission="agent.approve" element={<AgentKYC />}/>} />
                <Route path={routePaths.agentedit} element={<ProtectedRoute requiredPermission="agent.update" element={<AgentEDIT />}/>} />
                <Route path={routePaths.plans} element={<ProtectedRoute requiredPermission="plans.list" element={<Plans />}/>} />
                {/* <Route path={routePaths.planedit} element={<ProtectedRoute requiredPermission="plans.update" element={<PlanEDIT />}/>} /> */}
                <Route path={routePaths.addnewplan} element={<ProtectedRoute requiredPermission="plans.create" element={<AddNewPlan />}/>} />
                <Route path={routePaths.updateplans} element={<ProtectedRoute requiredPermission="plans.update" element={<AddNewPlan />}/>} />

                <Route path={routePaths.addonPlans} element={<ProtectedRoute requiredPermission="plans.list" element={<AddonPlans />}/>} />
                <Route path={routePaths.addAddonPlans} element={<ProtectedRoute requiredPermission="plans.list" element={<AddAddonPlan />}/>} />
                <Route path={routePaths.editAddonPlans} element={<ProtectedRoute requiredPermission="plans.list" element={<AddAddonPlan />}/>} />

                <Route path={routePaths.Editwholeseller} element={<ProtectedRoute requiredPermission="wholeseller.update" element={<AddWholeseller />}/>} />
                <Route path={routePaths.updateplan} element={<ProtectedRoute requiredPermission="plans.update" element={<AddNewPlan />}/>} />
                <Route path={routePaths.reviewplan} element={<ProtectedRoute requiredPermission="plans.update" element={<ReviewPlan />}/>} />
                {/* <Route path={routePaths.makepayment} element={<ProtectedRoute requiredPermission="plans.update" element={<MakePayment />}/>} /> */}
                <Route path={routePaths.payInvoice} element={<ProtectedRoute requiredPermission="ads_invoices.list" element={<PayInvoice />}/>} />
                {/* <Route path={routePaths.retailers} element={<ProtectedRoute requiredPermission="retailers.list" element={<Retailers />}/>} />
                <Route path={routePaths.addretailers} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<AddRetailers />}/>} /> */}
                <Route path={routePaths.renewplan} element={<ProtectedRoute requiredPermission="wholeseller.create" element={<RevenewPlan />}/>} />
                {/* <Route path={routePaths.offers} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<Offers />}/>} /> */}
                {/* <Route path={routePaths.createoffer} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<CreateOffer />}/>} /> */}
                <Route
                  path={routePaths.addnewadvertisement}
                  element={<ProtectedRoute requiredPermission="ads.create" element={<AddAdvertisement />}/>}
                />
                <Route
                  path={routePaths.editadvertisement}
                  element={<ProtectedRoute requiredPermission="ads.update" element={<AddAdvertisement type="edit" />}/>}
                />
                <Route
                  path={routePaths.viewadvertisement}
                  element={<ProtectedRoute requiredPermission="ads.list" element={<AddAdvertisement type="view" />}/>}
                />
                {/* <Route path={routePaths.addbranch} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<AddBranch />}/>} /> */}
                <Route path={routePaths.advertisement} element={<ProtectedRoute requiredPermission="ads.list" element={<Advertisement />}/>} />
                {/* <Route path={routePaths.branch} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<Branch />}/>} />
                <Route path={routePaths.branchplan} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<BranchPlan />}/>} />
                <Route path={routePaths.createorder} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<CreateOrder />}/>} />
                <Route path={routePaths.editorder} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<EditOrder />}/>} />
                <Route path={routePaths.order} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<Order />}/>} />
                <Route path={routePaths.photoorder} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<PhotoOrder />}/>} />
                <Route path={routePaths.addproduct} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<AddProduct />}/>} />
              <Route path={routePaths.bulkupload} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<BulkUpload />}/>} /> */}
              <Route path={routePaths.trackorder} element={<ProtectedRoute requiredPermission="activity_tracking.list" element={<TrackOrder />}/>} />
                <Route path={routePaths.agentdetails} element={<ProtectedRoute requiredPermission="agent.list" element={<AgentDetails />}/>} />
                <Route path={routePaths.wholesalerdetails} element={<ProtectedRoute requiredPermission="wholeseller.list" element={<WholesalerDetails handleModalBackdrop={props.handleModalBackdrop} />}/>} />
                <Route path={routePaths.wholesalerdetailsAddons} element={<ProtectedRoute requiredPermission="wholeseller.list" element={<SelectAddonPlans/>}/>} />
                <Route path={routePaths.wholesalerPlanPay} element={<ProtectedRoute requiredPermission="wholeseller.list" element={<PlanPaymentDetails/>}/>} />


                <Route path={routePaths.wholesalerdetailsPlans} element={<ProtectedRoute requiredPermission="wholeseller.list" element={<SelectPlans/>}/>} />
                <Route path={routePaths.wholesalerPlanPlansPay} element={<ProtectedRoute requiredPermission="wholeseller.list" element={<BuyPlay/>}/>} />

                {/* <Route path={routePaths.paymentrequest} element={<ProtectedRoute requiredPermission="ads_plan.update" element={<PaymentRequest />}/>} /> */}
                {/* <Route
                  path={routePaths.subcategories}
                  element={
                    <ProtectedRoute requiredPermission="ads_plan.update" element={
                    <SubCategories
                      formData={{
                        bazaar_name: undefined,
                      }}
                      setFormData={function (arg0: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  }/>}
                /> */}
                <Route
                  path={routePaths.addwholeseller}
                  element={<ProtectedRoute requiredPermission="wholeseller.create" element={<AddWholeseller />}/>}
                />
                <Route
                  path={routePaths.wholesellerkyc}
                  element={<ProtectedRoute requiredPermission="wholeseller.approve" element={<WholesellerKYC />}/>}
                />
                <Route
                  path={routePaths.electronicsbazaar}
                  element={<ProtectedRoute requiredPermission="bazaar.list" element={<ElectronicsBazaar handleModalBackdrop={props.handleModalBackdrop} />}/>}
                />
                <Route path={routePaths.bazaarreport} element={<ProtectedRoute requiredPermission="bazaar.list" element={<BazaarReport />}/>} />
                {/* <Route path={routePaths.itemwiseplan} element={<ProtectedRoute requiredPermission="bazaar.update" element={<ItemWisePlan />}/>} /> */}
                <Route path={routePaths.admin.bazaars} element={<ProtectedRoute requiredPermission="bazaar.list" element={<Bazaars />}/>} />
                <Route path={routePaths.admin.masterList} element={<ProtectedRoute requiredPermission="master.list" element={<MasterList />}/>} />
                <Route
                  path={routePaths.agentCommissionRedeemModel}
                  element={<ProtectedRoute requiredPermission="reedem_model.list" element={<AgentCommissionRedeemModel />}/>}
                />
                <Route
                  path={routePaths.createAgentCommissionRedeemModel}
                  element={<ProtectedRoute requiredPermission="reedem_model.create" element={<AgentCommissionRedeemModelForm />}/>}
                />
                <Route
                  path={routePaths.rolePermission}
                  element={<ProtectedRoute requiredPermission="permissions.list" element={<RolePermission />}/>}
                />
                <Route
                  path={routePaths.rolesPermissions}
                  element={<ProtectedRoute requiredPermission="permissions.list" element={<RolesPermission />}/>}
                />
                <Route path={routePaths.AddRols} element={<ProtectedRoute requiredPermission="permissions.create" element={<AddRols />}/>} />
                <Route path={routePaths.EditRols} element={<ProtectedRoute requiredPermission="permissions.update" element={<AddRols />}/>} />
                <Route path="*" element={<CheckRoute/>} />
              </>
            )
          }


        </Routes>
      </Router>
    </>
  );
};

export { AppRoutes as Routes };
