import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import {
  BazaarReportHero,
  SectionHeader,
} from "@/components/molecules/Bazaars";
import { TopWholeseler } from "@/components/molecules/Wholesellers";
import { Grid, MenuItem, Select } from "@mui/material";
import LogoAdd from "@/static/icons/ic_report.png";
import LogoGo from "@/static/icons/ic_go.png";
import { useBazaarreportStyles } from "@/static/stylesheets/screens";
import { CityBuisness } from "@/components/molecules/cityBuisness";
import { NewWholeseller } from "@/components/molecules/NewWholeseller/NewWholeseller";
import { AiOutlineLeft, AiOutlineReload, AiOutlineEllipsis, AiOutlineArrowRight } from "react-icons/ai";
import { TopProducts } from "@/components/molecules/TopProducts/TopProducts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AppService } from "@/service/AllApiData.service";

const BazaarReport = () => {
  const classes = useBazaarreportStyles();
  const navigate = useNavigate();
  const [citywisedata, setcitywisedata] = useState<any>([])
  const [topwholedata, settopwholedata] = useState<any>([])
  const [topproducts, settopproducts] = useState<any>([])
  // const bazaar_Id = localStorage?.getItem('IDS');
  const { id } = useParams();
  const [bazaarDetails, setBazaardetails] = useState<any>();
  const [districts, setDistricts] = useState<any>([]);
  const [wholesellers, setWholesellers] = useState<any>([]);
  const [district, setDistrict] = useState<any>();
  const [viewAllTopWholesellers,setViewAllTopWholesellers] = useState(false);
  const [viewAllTopProducts,setViewAllTopProducts] = useState(false);

  const goToElectronicBazarpage = () => {
    if (id !== null) {
      navigate("/electronicsbazaar/" + id);
    }
  }
  const getcitywisedata = async (id: any,params:any={}) => {
    const responseJson = await AppService.getAllcitywise(id,params);
    setcitywisedata(responseJson?.data?.results)
  }
  const gettopwholewisedata = async (id: any) => {
    const responseJson = await AppService.getAlltopwholewise(id);
    settopwholedata(responseJson?.data?.results)
  }
  const gettopProductsdata = async (id: any) => {
    const responseJson = await AppService.getAlltopProducts(id);
    settopproducts(responseJson?.data?.results)
  }
  const getwholeseller = async () => {
    const responcejson = await AppService.getAllWholesellerListM({page:1})
    console.log(responcejson?.data?.results?.map((itm: any) => itm?.wholeseller_type_name))
    setWholesellers(responcejson?.data?.results?.slice(0,5));
  }

  const getSingleBazaarDataAPI = async () => {
    const res = await AppService.getBazars(id)
    if (res.status === 200) {
      setBazaardetails(res.data)
    }
  }

  const getDistricts = async (params: any = {}) => {
    try {
      const resposne = await AppService.getAllDistric();
      console.log("GET DISTRICT ===> ", resposne.data)
      setDistricts(resposne.data.results.map((item: any) => ({
        label: item.district,
        value: item.id
      })));
    } catch (err: any) {
      console.log("GET DISTRICT ERROR ====> ", err.message)
    }
  }

  useEffect(() => {
    if (id !== null) {
      getSingleBazaarDataAPI();
      gettopwholewisedata(id);
      gettopProductsdata(id);
      getcitywisedata(id);
      getDistricts();
    }
    getwholeseller()
  }, [])

  useEffect(()=>{
    if(district){
      getcitywisedata(id,{district});
    }
  },[district])

  return (
    <>
      <DashboardLayout>
        <SectionHeader />
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="headingTitle" style={{ display: "flex", alignItems: "center", paddingBottom: "15px" }} onClick={goToElectronicBazarpage}><AiOutlineLeft style={{ color: "#84818A", marginRight: "10px", fontSize: '15px' }} /> Reports- {bazaarDetails?.bazaar_name}</div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className={classes.container}>
                <img src={LogoAdd} alt={"Logo"} />
                <p className="reportButton">View Report</p>
                <img src={LogoGo} alt={"Logo"} />
              </div>
            </Grid> */}

            <Grid item xs={12}>
              <BazaarReportHero />
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div className="cityBuisness">
                <div className="cityTitle">
                  <p>City-Wise Business</p>
                  <Select
                    labelId="city-select-label"
                    id="city-select"
                    value={district}
                    onChange={(e: any) => setDistrict(e.target.value)}
                    // onSelect={(e:any)=>console.log("regrg",e)}
                    variant="standard"
                    className="city-selection"
                    style={{ paddingLeft: "5px", width: "120px" }}
                    placeholder="Select City"
                  >
                    {
                      districts?.map((item: any, index: number) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))
                    }

                  </Select>
                </div>
                <div>
                  <CityBuisness data={citywisedata} />
                </div>
              </div>
            </Grid>

            <Grid item lg={8} md={8} sm={8} xs={12}>
              <div className="cityBuisness">
                <div className="cityTitle">
                  <p>Top Wholesellers</p>
                  <p className="viewAllBtn" onClick={()=>setViewAllTopWholesellers(!viewAllTopWholesellers)}>{!viewAllTopWholesellers ? "View all" : "View less"}</p>
                </div>
                <div>
                  <TopWholeseler list={topwholedata} viewAll={viewAllTopWholesellers} />
                </div>
              </div>
            </Grid>

            <Grid item lg={8} md={8} sm={8} xs={12} style={{ marginBottom: "15px" }}>
              <div className="cityBuisness" style={{ position: "relative", padding: "20px 20px 54px" }}>
                <div className="cityTitle">
                  <p>Top Poducts</p>
                  <p className="viewAllBtn" onClick={()=>setViewAllTopProducts(!viewAllTopProducts)}>{!viewAllTopProducts ? "View all" : "View less"}</p>
                </div>
                <div>
                  <TopProducts list={topproducts} viewAll={viewAllTopProducts} />
                </div>
                <div style={{ borderTop: "1px solid #EBEAED", padding: "16px", textAlign: "center", position: "absolute", width: "100%", bottom: 0, left: 0 }}>
                  <button style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", fontWeight: 600, fontSize: "14px", color: "#4E2FA9" }} onClick={()=>setViewAllTopProducts(!viewAllTopProducts)}>
                  {!viewAllTopProducts ? "View More Products" : "View Less Products"}
                   <AiOutlineArrowRight style={{ marginLeft: "10px" }} /></button>
                </div>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={12} style={{ marginBottom: "15px" }}>
              <div className="cityBuisness" style={{ position: "relative", padding: "20px 20px 54px" }}>
                <div className="cityTitle">
                  <p>New Wholesellers</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AiOutlineReload style={{ marginRight: "10px", color: "#84818A" }} onClick={getwholeseller} />
                    {/* <AiOutlineEllipsis style={{ transform: "rotate(92deg)", color: "#84818A" }} /> */}
                  </div>
                </div>
                <div>
                  <NewWholeseller data={wholesellers} />
                </div>
                <div style={{ borderTop: "1px solid #EBEAED", padding: "16px", textAlign: "center", position: "absolute", width: "100%", bottom: 0, left: 0 }}>
                  <button style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", fontWeight: 600, fontSize: "14px", color: "#4E2FA9" }} onClick={()=>navigate("/wholesellerlist")} >View More Wholesellers <AiOutlineArrowRight style={{ marginLeft: "10px" }} /></button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    </>
  );
};

export default BazaarReport;