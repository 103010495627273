import React from "react";
import { useTopwholesellerStyle } from "@/static/stylesheets/molecules";
import LogoEcBazaar from "@/static/images/placeholder.jpg";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";

const TopProducts = (props:any) => {
  const { list=[], viewAll=false } = props;
  const classes = useTopwholesellerStyle();
  const data = [1, 2, 3, 4, 5];

  return (
    <div className={classes.root} style={{ overflowX: "auto" }}>
      <table className="topWholesalerTable">
        <tr>
          <th className="planTitle">ITEM</th>
          <th className="bazaarTitle">PRICE</th>
          <th className="stateTitle">SOLD</th>
          <th className="districtTitle" style={{ textAlign: "end", paddingRight: 0 }}>SALES</th>
        </tr>

        {
        viewAll ?
        list[0]?.item?.map((name: any,index:number) => (
          <tr>
            <td>
              <div className="brandData" style={{ alignItems: "center" }}>
                <img className="brandLogo" src={list[0].picture[index]} alt={"Logo"} />
                <p>{name}</p>
              </div>
            </td>
            <td>{formatIndianRupee(list[0]?.price[index])}</td>
            <td>{list[0]?.sold[index]}</td>
            <td style={{ textAlign: "end", paddingRight: 0 }}>{formatIndianRupee(list[0]?.sales[index])}</td>
          </tr>
        ))
      :
      list[0]?.item?.slice(0,5)?.map((name: any,index:number) => (
        <tr>
          <td>
            <div className="brandData" style={{ alignItems: "center" }}>
              <img className="brandLogo" src={list[0].picture[index]} alt={"Logo"} />
              <p>{name}</p>
            </div>
          </td>
          <td>{formatIndianRupee(list[0]?.price[index])}</td>
          <td>{list[0]?.sold[index]}</td>
          <td style={{ textAlign: "end", paddingRight: 0 }}>{formatIndianRupee(list[0]?.sales[index])}</td>
        </tr>
      ))
      }
      </table>
    </div>
  );
};

export { TopProducts };