import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { RoleSection } from "@/components/molecules/rulespermission/roleSection";
import { useNavigate, useParams } from "react-router-dom";
import arrow from "@/static/svg/ic_arrow.svg";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { BiChevronLeft } from "react-icons/bi";
import { AppService } from "@/service/AllApiData.service";
import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Alert } from "@/alert/Alert";

const AddRols = () => {
  const { id } = useParams();
  const [permissionData, setPermissionData] = useState<any>({});
  const [roles, setRoles] = useState<string[]>([]);
  const [name, setName] = useState<string>("");
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    roles: []
  })

  const navigate = useNavigate();
  useEffect(() => {
    getPermisisions();
    if (id) {
      getRole();
    }
  }, []);

  const getRole = async () => {
    let param = {
      role_id: id
    }
    const response = await AppService.getRoles(param);
    setInitialValues({
      name: response?.data?.roles?.name,
      roles: response?.data?.roles?.permissions
    })
    console.log("logger123...", response)
  }

  const getPermisisions = async () => {
    const res = await AppService.getPermissions();
    console.log("logger123...", res.data.permissions)
    setPermissionData(res.data.permissions);
  };
  const handleChangePermission = (e: any, name: string, setField: any, roles: any) => {
    const permissionName = e.target.name;
    const firstElement = permissionName.split(".")[0];

    if (permissionName.includes("list") && roles.includes(permissionName)) {
      // If "list" is unchecked, remove all permissions that start with the same group (prefix)
      const filteredRoles = roles.filter((role: any) => !role.startsWith(firstElement));
      setField(name, filteredRoles);
    } else if (permissionName.includes("list") && !roles.includes(permissionName)) {
      // If "list" is checked, allow adding it to the roles
      setField(name, [...roles, permissionName]);
    } else if (roles.includes(permissionName)) {
      // If any other permission is unchecked, remove only that permission
      const filteredRoles = roles.filter((role: any) => role !== permissionName);
      setField(name, filteredRoles);
    } else {
      // If any other permission is checked, add it to the roles
      setField(name, [...roles, permissionName]);

      // If bazaar.create is selected, also add bazaar.update if it's not already there
      if (permissionName === "bazaar.create" && !roles.includes("bazaar.update")) {
        setField(name, [...roles, permissionName, "bazaar.update"]);
      }
      if (permissionName === "plans.create" && !roles.includes("plans.update")) {
        setField(name, [...roles, permissionName, "plans.update"]);
      }
    }
  };

  const handleSubmit = async (values: any) => {
    console.log("logger123... ", values,"values")
    if(id){
      const data = {
        name: values?.name,
        permissions: values?.roles,
      };
      await AppService.updateRole(id,data);
      Alert("Role Updated Successfully")

      navigate("/rolespermissions");
    } else {
      const data = {
        name: values?.name,
        permissions: values?.roles,
      };
      await AppService.createRole(data);
      Alert("Role Created Successfully")
      navigate("/rolespermissions");
    }
  }


  const createRole = async () => {
   
  };

  const validationSchema = yup.object({
    name: yup.string().required("Role name is required"),
    roles: yup.array().min(1, "Please Select Permissions").required("Permissions are required")
  })

  const CheckContidions = (item: any,key:any) => {
    if(item==="bazaar" && key==="delete"){
      return false
    }
    return true

  }

  return (
    <DashboardLayout>
      {/* <Box className="w-full addRole">
          <Box className="breadCrumb"> <img src={arrow} alt="left arrow" /> <span>Create New Role</span></Box>
          <Box className="role">
            <TextField id="standard-basic" label="Role Name" variant="standard" className=" w-[300px]" />
          </Box>
          <Typography className="title pt-8">Permissions  Group Title</Typography>
          <Typography className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
          <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Permission Name" className="checkbox-addrole"/>
              <span className="pera-text">A short description of the permissions will be displayed here</span>
          </FormGroup>
          <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Permission Name" className="checkbox-addrole"/>
              <span className="pera-text">A short description of the permissions will be displayed here</span>
          </FormGroup>
          <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Permission Name" className="checkbox-addrole"/>
              <span className="pera-text">A short description of the permissions will be displayed here</span>
          </FormGroup>
          <Typography className="title pt-8">Permissions  Group Title</Typography>
          <Typography className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
          <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Permission Name" className="checkbox-addrole"/>
              <span className="pera-text">A short description of the permissions will be displayed here</span>
          </FormGroup>
          <Box className="pt-8 flex items-center gap-5">
            <ActionButton variant="default" title="Cancel" />
            <ActionButton variant="primary" title="Save" />  
          </Box>                
      </Box> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {
          ({ values, setFieldValue, handleChange, handleSubmit }) => (
            <div className="w-[604px]">
              <div className="w-full py-[20px]">
                <div className="container">
                  <div className="flex items-center gap-[24px]">
                    <button
                      className="text-[#84818A] text-[24px]"
                      onClick={() => {
                        navigate("/rolespermissions");
                      }}
                    >
                      <BiChevronLeft />
                    </button>
                    <div className="text-[#2e2c34] leading-[20px] text-[24px] font-[600] font-[Manrope]">
                      Create New Role
                    </div>
                  </div>
                  <div className="mt-[54px] mb-[37px]">
                    <div>
                      <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                        Role Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={values.name}
                        className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                        onChange={handleChange}
                      />
                      <div className="text-red-600 mt-2">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>

                  {
                    Object.keys(permissionData).map((item: any,i:number) => (
                      <div key={i}>
                        <div className="mb-[26px]">
                          <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                            {item==="bazaar"? "All Bazaars": item==="reedem_model"? "Agent Commission Redeem Model": item==="activity_tracking"? "Activity Tracking": item}
                          </p>
                          <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                        </div>

                        {
                          Object.keys(permissionData[item]).map((key: any, index:number) => (
                            CheckContidions(item,key) &&
                            <div className="flex gap-[15px] mb-[26px]" key={index}>
                              <>
                                <div className="my-[5px]">
                                  <input
                                    type="checkbox"
                                    name={item + "." + key}
                                    checked={values.roles.includes(item + "." + key)}
                                    className="accent-[#4E2FA9] w-[18px] h-[18px]"
                                    onChange={(e) => handleChangePermission(e, "roles", setFieldValue, values.roles)}
                                    disabled={key!=="list"? values.roles.includes(item + "." + "list")?false:true:false}
                                  />
                                </div>
                                <div>
                                  <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                                    {key==="list"? "View": key==="create"? "Add":key==="update"? "Edit":key==="delete"? "Delete":key}
                                  </p>
                                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                                    {permissionData[item][key]}
                                  </p>
                                </div>
                              </>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }

                  <div className="text-red-600 mt-2">
                    <ErrorMessage name="roles" />
                  </div>
                  {/* {permissionData?.ads && (
                    <>
                      <div className="mb-[26px]">
                        <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Ads
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                      </div>
                      <div className="flex gap-[15px] mb-[26px]">
                        {permissionData?.ads?.create && (
                          <>
                            <div className="my-[5px]">
                              <input
                                type="checkbox"
                                name="ads.create"
                                checked={values.roles.includes("ads.create")}
                                className="accent-[#4E2FA9] w-[18px] h-[18px]"
                                onChange={(e) => handleChangePermission(e, "ads.create", setFieldValue, values.roles)}
                              // disabled={!id}
                              />
                            </div>
                            <div>
                              <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                                Create
                              </p>
                              <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                                {permissionData?.ads?.create}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex gap-[15px] mb-[26px]">
                        {permissionData?.ads?.list && (
                          <>
                            <div className="my-[5px]">
                              <input
                                type="checkbox"
                                onChange={handleChange}
                                name="ads.list"
                                className="accent-[#4E2FA9] w-[18px] h-[18px]"
                              />
                            </div>
                            <div>
                              <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                                List
                              </p>
                              <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                                {permissionData?.ads?.list}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex gap-[15px] mb-[26px]">
                        {permissionData?.ads?.update && (
                          <>
                            <div className="my-[5px]">
                              <input
                                type="checkbox"
                                onChange={handleChange}
                                name="ads.update"
                                className="accent-[#4E2FA9] w-[18px] h-[18px]"
                              />
                            </div>
                            <div>
                              <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                                Update
                              </p>
                              <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                                {permissionData?.ads?.update}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )} */}

                  {/* {permissionData?.agency && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Agency
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agency?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="agency.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agency?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agency?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="agency.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agency?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agency?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="agency.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agency?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agency?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="agency.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agency?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.agent && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Agent
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agent?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="agent.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agent?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agent?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="agent.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agent?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agent?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="agent.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agent?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agent?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="agent.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agent?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.agent?.verify && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="agent.verify"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Verify
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.agent?.verify}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.bazaar && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Bazaar
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.bazaar?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="bazaar.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.bazaar?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.bazaar?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="bazaar.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.bazaar?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.bazaar?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="bazaar.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.bazaar?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.bazaar?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="bazaar.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.bazaar?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.category && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Category
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.category?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="category.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.category?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.category?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="category.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.category?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.category?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="category.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.category?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.category?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="category.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.category?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.offer && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Offer
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.offer?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="offer.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.offer?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.offer?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="offer.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.offer?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.offer?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="offer.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.offer?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.offer?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="offer.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.offer?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.parentcategory && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Parent Category
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.parentcategory?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="parentcategory.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.parentcategory?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.parentcategory?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="parentcategory.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.parentcategory?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.parentcategory?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="parentcategory.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.parentcategory?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.parentcategory?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="parentcategory.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.parentcategory?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.payment && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Payment{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.payment?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="payment.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.payment?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.payment?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="payment.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.payment?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.payment?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="payment.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.payment?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.payment?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="payment.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.payment?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.permissions && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Permissions{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.permissions?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="permissions.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.permissions?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.permissions?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="permissions.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.permissions?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.permissions?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="permissions.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.permissions?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.permissions?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="permissions.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.permissions?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.plans && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Plans{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.plans?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="plans.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.plans?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.plans?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="plans.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.plans?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.plans?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="plans.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.plans?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.plans?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="plans.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.plans?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.product && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Product{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.product?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="product.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.product?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.product?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="product.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.product?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.product?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="product.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.product?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.product?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="product.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.product?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.retailer && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Retailer{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.retailer?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="retailer.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.retailer?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.retailer?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="retailer.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.retailer?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.retailer?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="retailer.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.retailer?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.retailer?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="retailer.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.retailer?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.roles && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Roles{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.roles?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="roles.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.roles?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.roles?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="roles.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.roles?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.roles?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="roles.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.roles?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.roles?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="roles.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.roles?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.subcategory && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Sub Category{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.subcategory?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="subcategory.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.subcategory?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.subcategory?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="subcategory.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.subcategory?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.subcategory?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="subcategory.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.subcategory?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.subcategory?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="subcategory.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.subcategory?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {permissionData?.wholeseller && (
              <>
                <div className="mb-[26px]">
                  <p className="text-[16px] font-[Manrope] font-[600] text-[#2E2C34]">
                    Wholeseller{" "}
                  </p>
                  <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]"></p>
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.wholeseller?.create && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          name="wholeseller.create"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Create
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.wholeseller?.create}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.wholeseller?.list && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="wholeseller.list"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          List
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.wholeseller?.list}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.wholeseller?.update && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="wholeseller.update"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Update
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.wholeseller?.update}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-[15px] mb-[26px]">
                  {permissionData?.wholeseller?.delete && (
                    <>
                      <div className="my-[5px]">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="wholeseller.delete"
                          className="accent-[#4E2FA9] w-[18px] h-[18px]"
                        />
                      </div>
                      <div>
                        <p className="text-[14px] font-[Manrope] font-[600] text-[#2E2C34]">
                          Delete
                        </p>
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          {permissionData?.wholeseller?.delete}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )} */}
                  <div className="pt-8 flex items-center gap-5">
                    <ActionButton
                      variant="default"
                      title="Cancel"
                      onClick={() => navigate("/rolespermissions")}
                    />
                    <ActionButton
                      variant="primary"
                      title="Save"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Formik>
    </DashboardLayout>
  );
};

export default AddRols;
