import { ActionButton } from "@/components/atoms/Button/ActionButton";
import React, { useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import { Switch } from "@/components/atoms/Switch";
import { useParams } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import { useAgentStyles } from "@/static/stylesheets/molecules";
import { PlanCard } from "@/components/molecules/PlanCard";
import { Alert } from "@/alert/Alert";

interface Iprops {
  initialValues: any,
  setActiveStep?: any,
  getData?: any
}

function WholesellerDet(props: Iprops) {
  const classes = useAgentStyles();
  const { initialValues, getData } = props


  async function convertImageTobS4(imgUrl: string) {
    const imageToBase64 = require('image-to-base64/browser.js');
    let response = await imageToBase64(imgUrl);
    return "data:image/png;base64," + response;
  }

  const handleWholeSellerStatus = async (e: any, id: any, item: any) => {
    let textmsg = ""
    console.log(item, 'itemitem')
    if (e.target.checked) {
      console.log()
      textmsg = "Do you want to active wholeseller ?";
    } else {
      textmsg = "Do you want to inactive wholeseller ?";
    }
    // alert(index);

    if (window.confirm(textmsg)) {
      let wSellertData = initialValues;
      console.log(wSellertData, "wSellertData");
      wSellertData.wholeseller_active = e.target.checked;
      if (wSellertData.wholeseller_pan_card_image?.includes("https")) {
        wSellertData.wholeseller_pan_card_image = await convertImageTobS4(wSellertData.wholeseller_pan_card_image);
      }
      else {
        wSellertData.wholeseller_pan_card_image = undefined
      }
      if (wSellertData.wholeseller_adhar_front_image?.includes("https")) {
        wSellertData.wholeseller_adhar_front_image = await convertImageTobS4(wSellertData.wholeseller_adhar_front_image);
      }
      else {
        wSellertData.wholeseller_adhar_front_image = undefined
      }
      if (wSellertData.wholeseller_adhar_back_image?.includes("https")) {
        wSellertData.wholeseller_adhar_back_image = await convertImageTobS4(wSellertData.wholeseller_adhar_back_image);
      }
      else {
        wSellertData.wholeseller_adhar_back_image = undefined
      }
      if (wSellertData.wholeseller_image?.includes("https")) {
        wSellertData.wholeseller_image = await convertImageTobS4(wSellertData.wholeseller_image);
      }
      else {
        wSellertData.wholeseller_image = undefined
      }
      const responseJson = await AppService.updateWholeseller(id, wSellertData);
      if (responseJson.status == 200) {
        if (e.target.checked) {
          Alert('Wholeseller Inactive Successfully');
        }
        else {
          Alert('Wholeseller Active Successfully');
        }
        // getAllListss(currentPage);
        getData();
      }
    }
  }

  console.log(initialValues)
  return (
    <div className={classes.nativeDialog}>
      <div className="modalHead" style={{ padding: "30px 30px 15px" }}>
        <p className="modalTitle">{initialValues?.wholeseller_status}</p>
      </div>
      <div className="headTitle" style={{ padding: "0 30px" }}>
        <div>
          <p className="martTitle">{initialValues?.wholeseller_firm_name}</p>
          <p className="martDescription">{initialValues?.wholeseller_type_name}</p>
        </div>

        <div>
          <span style={{ fontSize: "16px", fontWeight: 600, marginRight: "10px" }}>
            {initialValues?.wholeseller_active === true ? "Active" : "Inactive"}
          </span>
          <Switch checked={initialValues.wholeseller_active} onChange={(e: any) => handleWholeSellerStatus(e, initialValues.id, initialValues)} />
          {/* {initialValues?.wholeseller_active === true ? (
            <Switch checked />
          ) : (
            <Switch disabled />
          )} */}
        </div>
      </div>
      <div className="datContainer" style={{ padding: "22px 30px" }}>
        <p className="dataTitle">Contact Detail</p>
        <div>
          <p className="metaData">{initialValues?.wholeseller_firm_name}</p>
          <p className="metaData flex gap-4 justify-end items-center py-[5px]">
            <HiPhone />
            {initialValues?.wholeseller_number}
          </p>
          <p className="metaData"> {initialValues?.wholeseller_email_id}</p>
        </div>
      </div>
      <div className="datContainer" style={{ padding: "22px 30px" }}>
        <p className="dataTitle"> Address</p>
        <div>
          <p className="metaData">
            {" "}
            {initialValues?.wholeseller_landmark},
            {initialValues?.wholeseller_city_name},{" "}
            {initialValues?.wholeseller_state_name}
          </p>
          <p className="dataDescription flex gap-4 justify-end items-center py-[5px]">
            <FaMapMarkerAlt />
            Show Directions
          </p>
        </div>
      </div>
      <div className="datContainer" style={{ padding: "22px 30px" }}>
        <p className="dataTitle">Bazaar</p>
        <div>
          <div className="flex gap-2">

            {
              initialValues?.wholeseller_bazaar_data?.map((item: any) => (
                <div className="group relative">
                  <button className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm">
                    {item?.bazaar_name}
                  </button>
                  <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                    {item?.bazaar_name}
                  </span>
                </div>

              ))
            }

          </div>
        </div>
      </div>
      <div className="datContainer" style={{ padding: "22px 30px" }}>
        <div>
          <p className="dataTitle" style={{ paddingBottom: "15px" }}>
            Aadhar
          </p>
          <p className="dataTitle">PAN</p>
        </div>
        <div>
          <p className="metaData" style={{ paddingBottom: "15px" }}>
            {initialValues?.wholeseller_adhar_no}
          </p>
          <p className="metaData">{initialValues?.wholeseller_firm_pan_no}</p>
        </div>
      </div>

      <div className="datContainer" style={{ padding: "22px 30px" }}>
        <div>
          <p className="dataTitle" style={{ paddingBottom: "15px" }}>
            Plan
          </p>
        </div>
        <div>
            {
              initialValues?.wholeseller_plan?.map((item:any,index:number)=>(
                <PlanCard item={item.plan} disable />
                ))
              }

        </div>
      </div>

      {
        initialValues?.wholeseller_payment ?
          <>
            <div className="datContainer" style={{ padding: "22px 30px" }}>
              <div>
                <p className="dataTitle">Payment Type</p>
                <p className="dataTitle" style={{ paddingBottom: "15px" }}>
                  Payment Status
                </p>
                <p className="dataTitle">Payment Id</p>
                <p className="dataTitle">Payment Date</p>
              </div>
              <div>
                <p className="metaData" style={{ paddingBottom: "15px" }}>
                  Online
                </p>
                <p className="metaData" style={{ paddingBottom: "15px" }}>
                  {initialValues?.wholeseller_payment?.payment_received ? "Success" : "NA"}
                </p>
                <p className="metaData">{initialValues?.wholeseller_payment?.razorpay_payment_id || "NA"}</p>
                <p className="metaData">{initialValues?.wholeseller_payment?.payment_date || "NA"}</p>
              </div>
            </div>
          </>
          :
          <>
            <div className="datContainer" style={{ padding: "22px 30px" }}>
              <div>
                <p className="dataTitle" style={{ paddingBottom: "15px" }}>
                  Payment Type
                </p>
                <p className="dataTitle" style={{ paddingBottom: "15px" }}>
                  Payment Amount
                </p>
                {/* <p className="dataTitle">Payment Id</p>
          <p className="dataTitle">Payment Date</p> */}
              </div>
              <div>
                <p className="metaData" style={{ paddingBottom: "15px" }}>
                  {initialValues?.wholeseller_plan_data?.amount ? "Cash" : "NA"}
                </p>
                <p className="metaData" style={{ paddingBottom: "15px" }}>
                  {initialValues?.wholeseller_plan_data?.amount}
                </p>
                {/* <p className="metaData">{initialValues?.wholeseller_payment?.razorpay_payment_id || "NA"}</p>
                <p className="metaData">{initialValues?.wholeseller_payment?.payment_date || "NA"}</p> */}
              </div>
            </div>
          </>
      }

    </div>
  );
}

export default WholesellerDet;
