import React, { useState, useEffect } from "react";
import { DashboardLayout } from "@/components/layouts";
import { useNavigate, useParams } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { AppService } from "@/service/AllApiData.service";
import { useAdNewPlan } from "@/static/stylesheets/screens/AddAdsPlan";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Alert } from "@/alert/Alert";


interface CreateAdsnewplanProps{
  type?:string
}

function CreateAdsnewplan({type}:CreateAdsnewplanProps) {
  const [isOpen, setisOpen] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const [isOpen3, setisOpen3] = useState(false);
  const [isOpen4, setisOpen4] = useState(false);
  const [isOpen5, setisOpen5] = useState(false);
  const [isOpen6, setisOpen6] = useState(false);
  const [isOpen7, setisOpen7] = useState(false);
  const [isOpen8, setisOpen8] = useState(false);
  const [statedata, setstatedata] = useState<any>(0);
  const [AllState, setAllState] = useState<any>([]);

  const [Alldistricts, setAlldistricts] = useState<any>([]);
  const [Allcity, setAllcity] = useState<any>([]);
  const [districtdata, setdistrictdata] = useState<any>([0]);
  const [citydata, setcitydata] = useState<any>([0]);
  const [bazaarData, setBazaarData] = useState<any>([0]);
  console.log(bazaarData, "adad");
  const [bazaarList, setBazaarList] = useState<any>([]);
  const [planName, setPlanName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState("");
  const [frequency, setFrequency] = useState("");
  const [price, setPrice] = useState("");
  const [timeSlot, setTimeSlot] = useState<any>('');
  const [customer, setCustomer] = useState(0);
  const TimeSlot = [
    {
      value: "9AM-6PM",
      label: "9AM-6PM",
    },
    {
      value: "6PM-12PM",
      label: "6PM-12PM",
    },
  ];
  const [places,setPlaces] = useState<any>([
    {
      label:"Home Top Banner",
      value: "Home Top Banner"
    },
    {
      label:"Group Cards",
      value: "Group Cards"
    },
    {
      label:"Home Bottom Banner",
      value: "Home Bottom Banner"
    },
  ])
  const [adPlacement,setAdPlacement] = useState<any>();
  const [initiaValues,setInitialValues] = useState({
    plan_name: "",
    max_customers: "",
      bazaar: "",
      state: "",
      district: "",
      city: "",
      plan_periods_in_days: "",
      frequency: "",
      time_slot: "",
      price: "",
      ad_placement: ""
  })
  const params = useParams();
  const classes = useAdNewPlan();
  const navigate = useNavigate();
  const getAllLists = async () => {
    const responseJson = await AppService.getTotalStates();
    let tempState = await responseJson.data.map((row: any) => {
      return {
        label: row.state,
        value: row.id,
      };
    });
    setAllState(tempState);
  };
  const getAllDisData = async () => {
    const responseJson = await AppService.getTotalDistrict();
    let tempState = await responseJson.data.map((row: any) => {
      return {
        label: row.district,
        value: row.id,
      };
    });
    setAlldistricts(tempState);
  };
  const getAllCityData = async () => {
    const responseJson = await AppService.getAllCity();
    let tempState = await responseJson.data.results.map((row: any) => {
      return {
        label: row.city,
        value: row.id,
      };
    });
    setAllcity(tempState);
  };
  const getAllBazar = async () => {
    const reportToJson = await AppService.getAllBazaar();

    setBazaarList(reportToJson.data.results);
  };
  const handleChangestate = (e: any) => {
    const { value } = e.target;

    setstatedata([value]);
  };
  const handleChangebazaar = (e: any) => {
    const { value } = e.target;
    setBazaarData(value);
  };
  const handleChangedistrict = (e: any) => {
    const { value } = e.target;

    setdistrictdata([value]);
  };
  const handleChangecity = (e: any) => {
    const { value } = e.target;

    setcitydata([value]);
  };

  const handletimeslot = (e: any) => {
    const { value } = e.target;

    setTimeSlot(value);
  };

  useEffect(() => {
    getAllLists();
    getAllDisData();
    getAllCityData();
    getAllBazar();
    if(type){
      getData();
    }
  }, []);

  const getData = async () => {
    try{
      const {data:values} = await AppService.getSingleAdsPlan(params.id)
      setInitialValues({
        plan_name: values.plan_name,
        bazaar: values?.bazaar,
        state: values?.state[0],
        district: values?.district[0],
        city: values?.city[0],
        plan_periods_in_days: values?.plan_periods_in_days,
        frequency: values.frequency,
        time_slot: values.time_slot,
        price: values.price,
        ad_placement: values.ad_placement,
        max_customers: values.max_customers
      })
      setBazaarData(values?.bazaar)
    } catch (err) {
      console.log("ERROR ===> ",err)
    }
  }

  const handleSubmit = async (values:any) => {
    if(type === "edit"){
      const data = {
        plan_name: values.plan_name,
        bazaar: values?.bazaar,
        state: [values?.state],
        district: [values?.district],
        city: [values?.city],
        plan_periods_in_days: values?.plan_periods_in_days,
        frequency: values.frequency,
        time_slot: values.time_slot,
        price: values.price,
        ad_placement: values.ad_placement,
        max_customers: values.max_customers
      };
      const res = await AppService.updateAdsPlan(params.id,data);
      console.log(res, "res");
      Alert("Ad Plan updated Successfully")
      navigate("/advertisement?active=2");
    } else {
      await createAdPlan(values)
    }
  }

  const createAdPlan = async (values:any) => {
    const data = {
      plan_name: values.plan_name,
      bazaar: values?.bazaar,
      state: [values?.state],
      district: [values?.district],
      city: [values?.city],
      plan_periods_in_days: values?.plan_periods_in_days,
      frequency: values.frequency,
      time_slot: values.time_slot,
      price: values.price,
      ad_placement: values.ad_placement,
      max_customers: values.max_customers
    };
    const res = await AppService.createAdsPlan(data);
    console.log(res, "res");
    Alert("Ad Plan created Successfully")
    navigate("/advertisement?active=2");
  };
  const selectedBazaar = bazaarList.find((item: any) => item.id === bazaarData);
  console.log(selectedBazaar, "sellee");


  const validationSchema = Yup.object({
    plan_name: Yup.string().required(" is required"),
      bazaar: Yup.number().required("Bazaar is required"),
      state: Yup.number().required("State is required"),
      district: Yup.number().required("District is required"),
      city: Yup.number().required("City is required"),
      plan_periods_in_days: Yup.number().required("Duration is required"),
      frequency: Yup.number().required("Frequency is required"),
      time_slot: Yup.string().required("Time slot is required"),
      price: Yup.number().required("Price is required"),
      ad_placement: Yup.string().required("Ad placement is required"),
      max_customers: Yup.number().required("Customer is required")
  })

  return (
    <DashboardLayout>
      <div className={classes.root}>
        <div className="container">
          <div className="flex items-center gap-[24px]">
            <button
              className="text-[#84818A] text-[24px]"
              onClick={() => {
                navigate("/advertisement?active=2");
              }}
            >
              <BiChevronLeft />
            </button>
            <div className="commonTitle">New Advertisement Plan</div>
          </div>
          <Formik
            initialValues={initiaValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {
              ({values,handleChange,handleSubmit,setFieldValue})=>(
          <div className="mt-[54px]">
            <div className="w-[50%]">
              <div className="w-full">
                <div className="flex gap-[32px] mb-[27px]">
                  <div>
                    <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                      Plan Name
                    </label>
                    <input
                      type="text"
                      disabled={type==="view"}
                      value={values.plan_name}
                      name="plan_name"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={handleChange}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="plan_name"/>
                    </div>
                  </div>
                  <div>
                    <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                      No. of Customer
                    </label>
                    <input
                      type="number"
                      disabled={type==="view"}
                      value={values.max_customers}
                      name="max_customers"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={handleChange}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="max_customers"/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-[32px] mb-[27px]">
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Select Bazaar
                    </label>
                    <Select
                      open={isOpen}
                      disabled={type==="view"}
                      onClose={() => setisOpen(false)}
                      onOpen={() => setisOpen(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.bazaar}
                      fullWidth={true}
                      name="bazaar"
                      onChange={(e:any)=>{
                        handleChangebazaar(e)
                        handleChange(e)
                      }}
                    >
                      {bazaarList?.map((items: any) => {
                        return (
                          <MenuItem key={items?.id} value={items?.id}>
                            <ListItemText primary={items?.bazaar_name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="bazaar"/>
                    </div>
                  </div>
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      State
                    </label>
                    <Select
                      open={isOpen2}
                      disabled={type==="view"}
                      onClose={() => setisOpen2(false)}
                      onOpen={() => setisOpen2(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.state}
                      name="state"
                      fullWidth={true}
                      onChange={(e)=>{
                        handleChangestate(e);
                        handleChange(e)
                      }}
                    >
                      {selectedBazaar?.bazaar_state_names.map((items: any) => {
                        return (
                          <MenuItem key={items?.id} value={items?.id}>
                            <ListItemText primary={items.state} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="state"/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-[32px] mb-[27px]">
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      District
                    </label>
                    <Select
                      open={isOpen3}
                      disabled={type==="view"}
                      onClose={() => setisOpen3(false)}
                      onOpen={() => setisOpen3(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.district}
                      name="district"
                      fullWidth={true}
                      onChange={(e:any)=>{
                        handleChangedistrict(e)
                        handleChange(e)
                      }}
                    >
                      {selectedBazaar?.bazaar_district_names.map(
                        (items: any) => {
                          return (
                            <MenuItem key={items?.id} value={items?.id}>
                              <ListItemText primary={items.district} />
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="district"/>
                    </div>
                  </div>
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      City
                    </label>

                    <Select
                      open={isOpen4}
                      disabled={type==="view"}
                      onClose={() => setisOpen4(false)}
                      onOpen={() => setisOpen4(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.city}
                      name="city"
                      fullWidth={true}
                      onChange={(e:any)=>{
                        handleChangecity(e)
                        handleChange(e)
                      }}
                    >
                      {selectedBazaar?.bazaar_city_names.map((items: any) => {
                        return (
                          <MenuItem key={items?.id} value={items?.id}>
                            <ListItemText primary={items?.city} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="city"/>
                    </div>
                  </div>
                </div>
                {/* <div className="flex gap-[32px] mb-[27px]">
                  <div>
                    <label className="text-[14px] font-[Manrope] block font-[500] text-[#84818A]">
                      Start Date
                    </label>
                    <input
                      type="date"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="text-[14px] font-[Manrope] block font-[500] text-[#84818A]">
                      Start Time
                    </label>
                    <input
                      type="time"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={(e) => setStartTime(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex gap-[32px] mb-[27px]">
                  <div>
                    <label className="text-[14px] font-[Manrope] block font-[500] text-[#84818A]">
                      End Date
                    </label>
                    <input
                      type="date"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="text-[14px] font-[Manrope] block font-[500] text-[#84818A]">
                      End Time
                    </label>
                    <input
                      type="time"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={(e) => setEndTime(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="flex gap-[32px] mb-[27px]">
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Duration (in days)
                    </label>
                    <input
                      type="number"
                      disabled={type==="view"}
                      value={values.plan_periods_in_days}
                      name="plan_periods_in_days"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={(e) => {
                        setDuration(e.target.value)
                        handleChange(e)
                      }}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="plan_periods_in_days"/>
                    </div>
                    {/* <Select
                      open={isOpen5}
                      onClose={() => setisOpen5(false)}
                      onOpen={() => setisOpen5(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={[]}
                      fullWidth={true}
                      onChange={handleduration}
                    ></Select> */}
                  </div>
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Time Slot
                    </label>
                    <Select
                      open={isOpen6}
                      disabled={type==="view"}
                      onClose={() => setisOpen6(false)}
                      onOpen={() => setisOpen6(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.time_slot}
                      name="time_slot"
                      fullWidth={true}
                      onChange={(e:any)=>{
                        handletimeslot(e)
                        handleChange(e)
                      }}
                    >
                      {TimeSlot.map((items: any) => {
                        return (
                          <MenuItem key={items.value} value={items.value}>
                            <ListItemText primary={items.label} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="time_slot"/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-[32px] mb-[45px]">
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Frequency (In mins)
                    </label>
                    <input
                      type="number"
                      disabled={type==="view"}
                      value={values.frequency}
                      name="frequency"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="frequency"/>
                    </div>
                    {/* <Select
                      open={isOpen7}
                      onClose={() => setisOpen7(false)}
                      onOpen={() => setisOpen7(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={[]}
                      fullWidth={true}
                      onChange={handlefrequency}
                    ></Select> */}
                  </div>
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Price
                    </label>
                    <input
                      type="number"
                      disabled={type==="view"}
                      value={values.price}
                      name="price"
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      onChange={(e) => handleChange(e)}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="price"/>
                    </div>
                    {/* <Select
                      open={isOpen8}
                      onClose={() => setisOpen8(false)}
                      onOpen={() => setisOpen8(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={[]}
                      fullWidth={true}
                      onChange={handleprice}
                    ></Select> */}
                  </div>
                </div>

                <div className="flex gap-[32px] mb-[45px]">
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Ad Placement
                    </label>
                   <Select
                   multiple={false}
                   disabled={type==="view"}
                   variant="standard"
                   sx={{
                     height: 20,
                     width: 288,
                     borderBottomColor: "#EBEAED",
                   }}
                   value={values.ad_placement}
                   name="ad_placement"
                   fullWidth={true}
                   onChange={(e)=>handleChange(e)}
                   >
                    {
                      places.map((item:any)=>(
                        <MenuItem value={item.value} >{item.label}</MenuItem>
                      ))
                    }
                   </Select>
                   <div className="text-red-600">
                      <ErrorMessage name="ad_placement"/>
                    </div>
                  </div>
                </div>
                
                <div className="flex gap-[12px]">
                  <button
                    className="h-[36px] border rounded bg-[#FFFFFF] px-[16px] py-[8px] capitalize text-[14px] flex justify-center items-center font-[Manrope] font-[600] text-[#2E2C34]"
                    onClick={() => {
                      navigate("/advertisement?active=2");
                    }}
                  >
                    cancel
                  </button>
                  {
                    type !== "view" &&
                  <button
                    className="h-[36px] border rounded bg-[#4E2FA9] px-[16px] py-[8px] capitalize text-[14px] flex justify-center items-center font-[Manrope] font-[600] text-[#fff]"
                    onClick={async () => {
                     handleSubmit()
                    }}
                  >
                    Create Ad Plan
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>
              )
            }
          </Formik>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CreateAdsnewplan;
