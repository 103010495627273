import { useAuth } from '@/context/authContext';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    element: React.ReactElement;
    requiredPermission: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    element,
    requiredPermission,
}: ProtectedRouteProps) => {
    const { isAuthenticated, permissions } = useAuth();

    useEffect(() => {
        console.log("logger1234...43", permissions, requiredPermission, permissions.includes(requiredPermission))
    }, [permissions])

    return <>
        {
            (!isAuthenticated) ?
                <Navigate to="/login" /> :
                (permissions.length && !permissions.includes(requiredPermission)) ?
                    <Navigate to="/401" /> :
                    element
        }
    </>;
};

export default ProtectedRoute;
