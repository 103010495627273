import React, { useEffect, useState } from "react";
import { useSummarycardStyle } from "@/static/stylesheets/molecules/summarycardStyle";
import { AppService } from "../../../../service/AllApiData.service";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";


interface SummaryProps {
  years:string,
  bazaars:any,
  states:any,
  districts:any,
  cities:any
}

const SummaryCard:React.FC<SummaryProps> = ({years,bazaars,states,districts,cities}) => {
  
  const [summary,setSummary] = useState<any>({});

  const getAllLists = async (params:any) => {
    const responseJson = await AppService.getAllSummerys(params);
    setSummary(responseJson.data);
  };
 
  useEffect(()=>{
    const states1 = states.filter((item:any)=>item.status === true).map((item:any)=>item.value)
    const bazaars1 = bazaars.filter((item:any)=>item.status === true).map((item:any)=>item.value)
    const dists1 = districts.filter((item:any)=>item.status === true).map((item:any)=>item.value)
    const cities1 = cities.filter((item:any)=>item.status === true).map((item:any)=>item.value)
    
    let params = {};
    if(states1?.length){
        params = {...params,state:states1.join(",")}
    }
    if(dists1?.length){
        params = {...params,district:dists1.join(",")}
    }
    if(cities1?.length){
        params = {...params,city:cities1.join(",")}
    }
    if(bazaars1?.length){
        params = {...params,bazaar:bazaars1.join(",")}
    }
    params = {
      ...params,
      y: years
    }
    getAllLists(params);
},[bazaars,states,districts,cities,years])

 
  const classes = useSummarycardStyle();

  return (
    <div className={classes.root}>
      <div className={classes.bazaarCard}>
        <p className="headTitle">Total Bazar</p>
        <p className="headSubtitle">{summary?.bazaar || "0"}</p>
      </div>

      <div className={classes.wholesellerCard}>
        <div className="wholesellerItem">
          <p className="headTitle">Total Wholesellers</p>
          <p className="headSubtitle">{summary?.wholeseller || "0"}</p>
        </div>
        <div>
          <p className="headTitle2" style={{ marginTop: 0 }}>Total Revenue Earned</p>
          <p className="headSubtitle2">{formatIndianRupee(summary?.revenue || 0)}</p>
        </div>
        <div>
          <p className="headTitle2" style={{ marginTop: 0 }}>No. of Orders</p>
          <p className="headSubtitle2">{summary?.bill}</p>
        </div>
      </div>

      <div className={classes.agentsCard}>
        <div className="agentItem">
          <p className="headTitle">Total Agents</p>
          <p className="headSubtitle">{summary?.agent}</p>
        </div>
        <div>
          <p className="headTitle2" style={{ marginTop: 0 }}>Commission Paid to Agent</p>
          <p className="headSubtitle2">{formatIndianRupee(summary?.commission || 0)}</p>
        </div>
        <div>
          <p className="headTitle2" style={{ marginTop: 0 }}>Customers Generated by Agent</p>
          <p className="headSubtitle2">{summary?.customer || "0"}</p>
        </div>
      </div>
    </div>
  );
};

export { SummaryCard };
