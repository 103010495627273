import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { DashboardLayout } from "@/components/layouts";
import { useaddAgentStyle } from "@/static/stylesheets/molecules/addagent";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SalesManIcon from "@/static/icons/icon_salesman.svg";
import indivitualIcon from "@/static/icons/icon_indivitual.svg";
import AgancyIcon from "@/static/icons/icon_agancy.svg";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import { AppService } from "@/service/AllApiData.service";
import { Alert, AlertError } from "@/alert/Alert";
import LogoPrev from "@/static/icons/ic_previous.png";
import { useNavigate, useParams } from "react-router-dom";
import { number } from "prop-types";
import { removeDuplicates } from "@/helper/util";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import { CityPickerNew } from "@/components/atoms/LocationPickers/CityPickerNew";

const AddAgents = () => {
  const classes = useaddAgentStyle();
  const navigate = useNavigate()
  const [paymentType, setPaymentType] = React.useState("individual");
  const [, setMasterType] = useState("Regional Wholeseller");
  const [AllState, setAllState] = React.useState([]);
  const [allDis, setAllDis] = React.useState([]);
  const [allCity, setAllCity] = React.useState([]);
  const [singleState, setSingleState] = React.useState<any>([]);
  const [singleDis, setSingleDis] = React.useState([]);
  const [bazaarWiseData, setBazaarWiseData] = React.useState<any>([]);
  const [initialValues, setInitialValues] = useState<any>({
    // agent_description: "",
    agent_name: "",
    agent_type: "INDIVIDUAL",
    agent_number: "",
    agent_firm_name: "",
    // agent_altranate_mobile_number: "",
    agent_email: "",
    // agent_gender: "MALE",
    // agent_date_of_birth: "",
    // agent_address: "",
    // agent_landmark: "",
    // agent_pincode: "",
    // agent_adharcard_no: "",
    // agent_adhar_front_image: null,
    // agent_adhar_back_image: null,
    // agent_pancard_image: null,
    // agent_pancard_no: "",
    // agent_image: null,
    agent_active: true,
    // agency: 2,
    agent_state: "",
    agent_city: "",
    agent_district: "",
    // agent_commision: 0,
    agent_bazaar: [],
    agent_assigned_state: "",
    agent_assigned_city: [],
    agent_assigned_district: [],
  });
  const [singleCity, setSingleCity] = React.useState([]);
  const [AllBazaar, setAllBazaar] = React.useState<any>([]);
  const [count, setCount] = useState<any>(1)
  const [bazaarList, setBazaarList] = useState<any>([]);
  const [bazaar, setBazaar] = useState<any>();
  const [stateList1, setStateList1] = useState<any[]>([]);
  const [states1, setStates1] = useState<any>();
  const [districts1, setDistricts1] = useState<any>([]);
  const [districtList1, setDistrictList1] = useState<any[]>([]);
  const [cityList1, setCityList1] = useState<any[]>([]);
  const [bazaarData, setBazaarData] = useState<any[]>([]);

  const { id } = useParams()
  const agency = JSON.parse(localStorage.getItem("agency") || '{}')
  const allowedBazaars = agency?.agent_bazaar_data?.map((item: any, index: number) => (
    {
      label: item.bazaar_name,
      value: item.id
    }
  ))
  const allowedStates = agency?.agent_assigned_state_names?.map((item: any, index: number) => (
    {
      label: item.state,
      value: item.id
    }
  ))
  const allowedDistrict = agency?.agent_assigned_district_names?.map((item: any, index: number) => (
    {
      label: item.district,
      value: item.id
    }
  ))
  const allowedCity = agency?.agent_assigned_city_names?.map((item: any, index: number) => (
    {
      label: item.city,
      value: item.id
    }
  ))

  // const superAdmin = localStorage.getItem("superAdmin") ? JSON.parse(localStorage.getItem("superAdmin") || "") : false;

  const superAdmin = true;



  const handleChangeMasterType = (event: SelectChangeEvent) => {
    setMasterType(event.target.value as string);
  };

  const validationSchema = Yup.object().shape({
    agent_name: Yup.string()
      .trim()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Agent name is required"),
    agent_firm_name: Yup.string().when("agent_type", {
      is: "AGENCY",
      then: (schema) => schema.trim().required("Agency name is required")
    }),
    // agent_firm_name: Yup.string().when("agent_type", (value: string) => {
    //   console.log("drdfhdfhdfh", value);
    //   // if (value == "Agent") {
    //   //   return Yup.string().required("jygsdyfg");
    //   // }
    // }),
    agent_email: Yup.string()
      .email("Invalid email format")
      .required("Email address is required*")
      .test('unique-email', 'Email already registered with mwb', async function (value) {
        const isDuplicate = await AppService.isAgentEmailExists(value);
        return !isDuplicate;
      }),
    agent_number: Yup.string()
      .trim()
      .required("Phone no is required")
      .matches(/^\d{2}[0-9]{10}$/, 'Invalid mobile number')
      .test('unique-mobile-number', 'Mobile number already registered with mwb', async function (value) {
        const isDuplicate = await AppService.isAgentNumberExists(value);
        return !isDuplicate;
      }),
    agent_state: Yup.string().required("State no is required"),
    agent_district: Yup.string().required("District is required"),
    agent_city: Yup.string().required("City is required"),
    agent_bazaar: Yup.array().min(1, "Pick at least 1 assigned bazaar"),
    // .required("Bazaar is required"),
    // agent_assigned_state: Yup.number().required("Pick at least 1 Assigned state"),
    // .of(Yup.string())
    // .required("Assigned State is required"),
    // agent_assigned_city: Yup.array().min(1, "Pick at least 1 Assigned city"),
    // agent_assigned_district: Yup.array().min(
    //   1,
    //   "Pick at least 1 Assigned  district"
    // ),
    // agent_type: Yup.string(),
    // agent_firm_name: Yup.string().when("agent_type",{
    //   is: "AGENCY",
    //   then: Yup.string().required("ewfwef")
    // })
  });

  // const {
  //   handleChange,
  //   handleSubmit,
  //   handleReset,
  //   values,
  //   errors,
  //   touched,
  //   setValues,
  //   setFieldValue,
  // } = useFormik({
  //   initialValues,
  //   // validationSchema,
  //   onSubmit: (values) => {
  //     console.log("val--on submit", values);
  //   },
  // });

  const handleFormSubmit = async (values: any) => {
    let error = false;
    bazaarWiseData.map((item: any) => {
      if (!item.state.length || !item.district.length || !item.city.length) {
        error = true
      }
    })

    if (error) {
      AlertError("Please fill all reaquired field *");
      return;
    }

    if (values.agent_type == "INDIVIDUAL") {
      delete values.agent_firm_name;
    } else if (values.agent_type == "SALESMAN") {
      delete values.agent_firm_name;
    } else if (values.agent_type == "AGENCY") {
      // values.wholeseller_agency_data = {
      //   firm_name: values.agent_firm_name,
      //   state: [values.agent_state],
      //   district: [values.agent_district],
      //   city: [values.agent_city]
      // }
    }

    delete values.agent_assigned_state;
    delete values.agent_assigned_district;
    delete values.agent_assigned_city;

    values.bazaar_assgined_data = bazaarWiseData;

    console.log("Valuessss---|||||||", values);
    values.agent_number = values.agent_number.includes(values.agent_number) ? values.agent_number : "+" + values.agent_number;
    // values.agent_assigned_state = [values.agent_assigned_state]
    delete values.agent_assigned_state;

    // return
    try {
      if (!id) {
        const response = await AppService.addAgent(values);
        if (response) {
          Alert("Successfully added!!!");
          navigate('/agents')
        }
      } else {
        const response = await AppService.patchAgentKyc(id, values);
        if (response) {
          Alert("Successfully Update!!!");
          navigate('/agents')
        }
      }
    } catch (error: any) {
      let message: any = [];
      error.response.data.errors?.map((x: any) => {
        message.push(x.detail)
      })
      AlertError(`1. ${message}`);
    }
  };

  const getAllLists = async () => {
    const responseJson = await AppService.getAllStates();
    // console.log(responseJson.data.bazaar);
    setAllState(responseJson.data.results);
    setSingleState(responseJson.data.results);
  };

  const getAllDis = async (param: any, type: any, index: number = 0) => {
    console.log("ppppppppparrararams", param);

    const responseJson = await AppService.getAllDistricByState(param);
    console.log("all Districtss and single-----");
    // console.log(responseJson.data.bazaar);
    // setAllDis(responseJson.data.results);

    if (type == "multiple") {
      console.log("all Districtss and single-----ALLL");
      if (bazaarWiseData[index]?.allDistricts) {
        bazaarWiseData[index].allDistricts = responseJson.data.results;
        setBazaarWiseData([...bazaarWiseData]);
      }
    } else {
      console.log("all Districtss and single-----SINGLL");
      setSingleDis(responseJson.data.results);
    }
  };

  const getAllCity = async (param: any, type: any, index: number = 0) => {
    const responseJson = await AppService.getAllCityByDis(param);
    if (type == "multiple") {
      bazaarWiseData[index].allCities = responseJson.data.results;
      setBazaarWiseData([...bazaarWiseData]);
    } else {
      setSingleCity(responseJson.data.results);
    }
  };

  useEffect(() => {
    getAllBazaar()
  }, [])

  useEffect(() => {
    if (bazaar?.join(",")) {
      getStatesByBazaar({ ids: bazaar?.join(",") })
    }
  }, [bazaar]);

  useEffect(() => {
    // if (states1?.join(",")) {
    // getDistrictByStates1({ ids: states1 });

    getAllDis(
      {
        ids: states1
      },
      "multiple"
    );
    // }
  }, [states1]);

  useEffect(() => {
    if (districts1?.join(",")) {
      // getAllCity1({ ids: districts1?.join(",") });
      getAllCity({ ids: districts1?.join(",") }, "multiple");
    }
  }, [districts1]);

  const getAllBazaar = async () => {
    const responseJson = await AppService.getAllBazaars();

    let tempBazaar = await responseJson.data.map((row: any) => {
      const alloweIds = allowedBazaars?.map((item: any) => item.value)
      if (alloweIds?.includes(row.id) || superAdmin) {
        return {
          label: row.bazaar_name,
          value: row.id,
          ...row
        }
      }
      return null
    }).filter((item: any) => item !== null)
    setBazaarList(tempBazaar);
  };


  const getStatesByBazaar = async (params: any, index: number = 0) => {
    try {
      const responseJson = await AppService.getAllStateByBazaar(params);

      const allowedIds = allowedStates?.map((item: any) => item.value)

      const tempState = responseJson.data.results.flatMap((row: any) =>
        row.bazaar_state.map((id: any) => {
          const stateItem = singleState?.find((item: any) => item.id === id);
          if ((stateItem && allowedIds?.includes(id)) || superAdmin) {
            return {
              bazaar_id: row.bazaar,
              label: stateItem?.state,
              value: id,
            };
          }
          return null // Handle cases where a matching state is not found
        })
      ).filter((item: any) => item !== null);

      console.log("logger123...", allowedIds, tempState);

      setStateList1(tempState);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };



  const getDistrictByStates1 = async (params: any) => {
    const responseJson = await AppService.getAllDistricByState(params);

    const allowedIds = allowedDistrict?.map((item: any) => item?.value);
    const dists = responseJson.data.results?.flatMap((ele: any) => {
      return ele?.district.map((item: any) => {
        if (allowedIds?.includes(item?.id) || superAdmin) {
          return {
            label: item?.district,
            value: item?.id
          };
        }
        return null;
      }).filter(Boolean);
    });

    const flattenedDists = dists.filter(Boolean);
    console.log("allowed123", flattenedDists, dists);

    setDistrictList1(flattenedDists);
  };



  const getAllCity1 = async (params: any) => {
    const responseJson = await AppService.getAllCityByDis(params);
    console.log("districts", responseJson.data.results);
    const allowedIds = allowedCity?.map((item: any) => item?.value);
    const cities = responseJson.data.results.flatMap((ele: any) => {
      return ele.city.map((item: any) => {
        if (allowedIds?.includes(item.id) || superAdmin) {
          return {
            label: item.city,
            value: item.id
          };
        }
        return null;
      }).filter(Boolean);
    });

    console.log("allowed", allowedIds, cities);
    setCityList1(cities);
  };


  const getOneAgent = async (id: any) => {
    try {
      const response = await AppService.getAgentById(id);
      console.log("Resss=====in agent edit", response.data);
      setInitialValues((prev: any) => ({
        ...prev,
        agent_name: response.data?.agent_name,
        agent_type: response.data?.agent_type,
        agent_number: response.data?.agent_number,
        agent_firm_name: response.data?.agent_firm_name || "",
        agent_email: response.data?.agent_email,
        agent_active: response.data?.agent_active,
        agent_state: response.data?.agent_state,
        agent_city: response.data?.agent_city,
        agent_district: response.data?.agent_district,
        agent_bazaar: response.data?.agent_bazaar,
        // agent_assigned_state: response.data?.agent_assigned_state[0],
        // agent_assigned_city: response.data?.agent_assigned_city,
        // agent_assigned_district: response.data?.agent_assigned_district,
      }))
      // console.log("LOGGER123 ====> ",response.data)
      setBazaarWiseData(response.data?.bazaar_assgined_data)
      setBazaarData(response.data?.bazaar_assgined_data)
      setBazaar(response.data?.agent_bazaar)
      setStates1(response.data?.agent_assigned_state)
      setDistricts1(response.data?.agent_assigned_district)
      getAllDis(
        {
          ids:
            response.data?.agent_state.length > 1
              ? response.data?.agent_state.join(",")
              : response.data?.agent_state,
        },
        "single"
      );
      getAllCity(
        {
          ids:
            response.data?.agent_district.length > 1
              ? response.data?.agent_district.join(",")
              : response.data?.agent_district,
        },
        "single"
      )
    } catch (error) {
      console.log("errrrrrr in kyc", error);
    }
  };

  useEffect(() => {
    getAllLists();
  }, []);

  useEffect(() => {
    if (id) {
      getOneAgent(id)
    }
  }, [id])


  console.log("all Bazaar of agent form------raw", AllBazaar);
  console.log("all Districtss of agent form------raw", allDis);
  console.log("Singleee Districtss of agent form------raw", singleDis);
  console.log("all Citiessss of agent form------raw", allCity);
  console.log("Sinnnngleee Citiessss of agent form------raw", singleCity);
  console.log("initialValuesinitialValuesinitialValues", initialValues);

  const handalchanges = (e: any) => {
    setInitialValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }


  useEffect(() => {
    console.log("BAZAAR WISE DATA ====> ", bazaarWiseData)
  }, [bazaarWiseData])

  const handleStateChange = (list: any, e: any, index: any, city = false, type: any = "") => {


    // const allLength = city ? allCity.flatMap((item:any)=>item.city.map((item:any)=>item.id)) : allDis.flatMap((item:any)=>item.district.map((item:any)=>item.id))
    // console.log("list.....123...",e, list, type, list.filter((item:any)=>!e.includes(item)) )

    function removeDuplicates<T>(array: T[]): T[] {
      return array.filter((value, index, self) => self.indexOf(value) === index);
    }

    if (e.length > 1) {
      if (type?.all === true) {
        if (city) {
          bazaarWiseData[index].city = removeDuplicates([...list, ...e]);
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = removeDuplicates([...list, ...e]);
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
        }
      } else {
        if (city) {
          bazaarWiseData[index].city = [...list.filter((item: any) => !e.includes(item))];
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = [...list.filter((item: any) => !e.includes(item))];
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
        }
      }
    } else {
      if (list.includes(e[0])) {
        if (city) {
          bazaarWiseData[index].city = [...list.filter((item: any) => item !== e[0])];
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = [...list.filter((item: any) => item !== e[0])];
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
        }
      } else {

        if (city) {
          bazaarWiseData[index].city = [...list, e[0]];
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = [...list, e[0]];
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity({ ids: bazaarWiseData[index].district?.join(",") }, "multiple", index);
        }

      }
    }


    console.log("logger123..", list)
  }



  React.useEffect(() => {
    console.log("BAZAAR_DATA", bazaarWiseData)
  }, [bazaarWiseData])

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setValues,
          touched,
          errors,
          resetForm,
          validateField,
          setFieldTouched
        }) => {
          return (
            <Form>
              <DashboardLayout>
                <div className={classes.root}>
                  <div className="container">
                    <div className="textContainer" style={{ alignItems: "center", display: "flex" }}>
                      <button onClick={() => navigate("/agents")} className="icon" style={{ marginRight: "15px" }}>
                        <img src={LogoPrev} alt={"Logo"} style={{ width: "7px", height: "12px" }} />
                      </button>
                      <p className="titleHead">{id ? "Edit" : "Add"} Agent</p>
                    </div>
                    <div className="radio-actionButton">
                      <div className={`${values.agent_type === 'INDIVIDUAL' ? "border border-[#4e2fa9] p-[10px] w-[205px] flex justify-center items-center h-[60px] rounded-md bg-[#f9fafb]" : "radio-button"}`}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="agent_type"
                            value={values.agent_type}
                            onChange={() => {
                              // resetForm();
                              setFieldValue("agent_type", "INDIVIDUAL");
                            }
                            }
                          >
                            <FormControlLabel
                              value="Cash "
                              control={<Radio sx={{
                                color: '#84818A',
                                '&.Mui-checked': {
                                  color: '#4e2fa9',
                                },
                              }} />}
                              checked={values.agent_type === "INDIVIDUAL"}
                              label={
                                <div className="flex gap-4 items-center">
                                  <img src={indivitualIcon} alt={"Logo"} />
                                  Individual
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      <div className={`${values.agent_type === 'AGENCY' ? "border border-[#4e2fa9] p-[10px] w-[205px] flex justify-center items-center h-[60px] rounded-md bg-[#f9fafb]" : "radio-button"}`}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="agent_type"
                            value={values.agent_type}
                            onChange={() => {
                              // resetForm();
                              setFieldValue("agent_type", "AGENCY");
                            }}
                          >
                            <FormControlLabel
                              value="Online"
                              control={<Radio sx={{
                                color: '#84818A',
                                '&.Mui-checked': {
                                  color: '#4e2fa9',
                                },
                              }} />}
                              checked={values.agent_type === "AGENCY"}
                              label={
                                <div className="flex gap-4 items-center">
                                  <img src={AgancyIcon} alt={"Logo"} /> Agency
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className={`${values.agent_type === 'SALESMAN' ? "border border-[#4e2fa9] p-[10px] w-[205px] flex justify-center items-center h-[60px] rounded-md bg-[#f9fafb]" : "radio-button"}`}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="agent_type"
                            value={values.agent_type}
                            onChange={() => {
                              // resetForm()
                              setFieldValue("agent_type", "SALESMAN")
                            }
                            }
                          >
                            <FormControlLabel
                              value="Online"
                              control={<Radio sx={{
                                color: '#84818A',
                                '&.Mui-checked': {
                                  color: '#4e2fa9',
                                },
                              }} />}
                              checked={values.agent_type === "SALESMAN"}
                              label={
                                <div className="flex gap-4 items-center">
                                  <img src={SalesManIcon} alt={"Logo"} /> Salesman
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    {values.agent_type === "INDIVIDUAL" ? (
                      <>
                        {console.log("vaaaaaaaaaaa=======>", values)}

                        {/* <Form onSubmit={handleSubmit}> */}
                        <div className={"w-[50%]"}>
                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Agent Person <span className=" text-red-600">*</span></p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                    // handalchanges(e);
                                  }}
                                  fullWidth={true}
                                  name="agent_name"
                                  value={values.agent_name}
                                  onBlur={handleBlur}

                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default behavior of the Enter key
                                      // Do not include navigate('/agents') here
                                    }
                                  }}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_name" />
                                </Box>
                              </div>
                              <div>
                                <PhoneInput
                                  country={"in"}
                                  value={values.agent_number}
                                  countryCodeEditable={false}
                                  onBlur={(e) => {
                                    validateField("agent_number");
                                    setFieldTouched("agent_number");
                                  }}
                                  onChange={(phone) =>
                                    setFieldValue("agent_number", phone)
                                  }
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_number" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">State <span className=" text-red-600">*</span></p>

                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  multiple={false}
                                  name="agent_state"
                                  value={values.agent_state}
                                  onBlur={handleBlur}

                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("agent_district", "")
                                    setFieldValue("agent_city", "")
                                    getAllDis(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleState?.map((items: any) => (
                                    <MenuItem key={items.id} value={items.id}>
                                      {items.state}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_state" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">District <span className=" text-red-600">*</span></p>

                                <Select
                                  label="Bazaar"
                                  multiple={false}
                                  variant={"standard"}
                                  fullWidth={true}
                                  name="agent_district"
                                  value={values.agent_district}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("agent_city", "")
                                    getAllCity(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleDis?.map((items: any) => {
                                    return items?.district.map((item: any) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.district}
                                        </MenuItem>
                                      );
                                    });
                                  })}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_district" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="w-[50%]">
                              <p className="title-main">City <span className=" text-red-600">*</span></p>

                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={false}
                                name="agent_city"
                                value={values.agent_city}
                                onBlur={handleBlur}
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                              >
                                {singleCity?.map((items: any) => {
                                  return items?.city.map((item: any) => {
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.city}
                                      </MenuItem>
                                    );
                                  });
                                })}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                <ErrorMessage name="agent_city" />
                              </Box>
                            </div>

                            <div className="w-[50%]">
                              <div>
                                <p className="title-main">Email Address <span className=" text-red-600">*</span></p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="agent_email"
                                  value={values.agent_email}
                                  onBlur={handleBlur}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default behavior of the Enter key
                                      // Do not include navigate('/agents') here
                                    }
                                  }}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_email" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Bazaar <span className=" text-red-600">*</span></p>
                                <Autocomplete
                                  multiple
                                  id="bazaar-autocomplete"
                                  options={bazaarList}
                                  disableCloseOnSelect
                                  value={bazaarList?.filter((ele: any) => values.agent_bazaar.includes(ele.id)) || []}
                                  onChange={(event: any, value: any) => {
                                    console.log("Bazaar_DATA", event, value, event?.target, event.target.value);
                                    const values = value.map((ele: any) => ele.id);
                                    // return;
                                    let data: any = [];
                                    values?.map((item: any, index: number) => {
                                      // if(data[index]?.bazaar_id !== item){
                                      data = [...data, {
                                        bazaar_id: item,
                                        state: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.state || [],
                                        district: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.district || [],
                                        city: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.city || [],
                                        allDistricts: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.allDistricts || [],
                                        allCities: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.allCities || []
                                      }]
                                      // }
                                    })
                                    setBazaarWiseData(data);
                                    // handleChange(event);
                                    setFieldValue("agent_bazaar", values)
                                    setBazaar(values);
                                    console.log("Bazaar_DATA", event.target.value)
                                    setFieldValue("agent_assigned_state", "")
                                    setFieldValue("agent_assigned_district", [])
                                    setFieldValue("agent_assigned_city", [])
                                  }}
                                  getOptionLabel={(option) => option.label}
                                  isOptionEqualToValue={(option, value) => option.value === value}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox checked={values.agent_bazaar.includes(option.value)} />
                                      <ListItemText primary={option.label} />
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Bazaar" variant="standard" fullWidth />
                                  )}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_bazaar" />
                                </Box>
                              </div>

                              {/* <div>
                                <p className="title-main">Assigned States</p>

                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  // multiple={true}
                                  name="agent_assigned_state"
                                  value={values.agent_assigned_state}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setStates1(e.target.value)
                                    setFieldValue("agent_assigned_district", [])
                                    setFieldValue("agent_assigned_city", [])
                                    setDistricts1([])
                                  }}
                                  renderValue={(selected) => {
                                    return stateList1.find((item: any) => item.value === selected).label;
                                    var data: any = [];
                                    stateList1.map((item: any) => {
                                      if (selected = item.value) {
                                        data.push(item.label)
                                      }
                                    })
                                    return data.join(', ')
                                  }
                                  }
                                >
                                  {stateList1?.map((items: any, index: number) => (
                                    <MenuItem key={index} value={items.value}>
                                      <Checkbox checked={values.agent_assigned_state === items.value} />
                                      <ListItemText primary={items.label} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_state" />
                                </Box>
                              </div> */}
                            </div>
                          </div>

                          {/* <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <div className="Selectdistrict">
                                  <DistrictPicker
                                    label={"Assigned District"}
                                    hint={"Search"}
                                    variant={"outlined"}
                                    states={stateList1.map((item: any) => ({ state: item.label, id: item.value }))}
                                    selectedStates={[states1]}
                                    districts={allDis}
                                    selectedDistricts={values.agent_assigned_district}
                                    onSelectItem={(e: any, type: any) => {
                                      handleStateChange(values.agent_assigned_district, e, handleChange, false, type)
                                      setFieldValue("agent_assigned_city", [])
                                    }}
                                  />

                                </div>

                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_district" />
                                </Box>
                              </div>
                              <div>

                                <CityPickerNew label={"Assigned Cities"}
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allDis}
                                  selectedStates={districts1}
                                  selectedDistricts={values.agent_assigned_district}
                                  districts={allCity}
                                  selectedCity={values.agent_assigned_city}
                                  selectedcity={values.agent_assigned_city}
                                  onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(values.agent_assigned_city, id, handleChange, true, type)}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_city" />
                                </Box>
                              </div>
                            </div>
                          </div> */}



                          {
                            bazaarList.length ? bazaarWiseData.map((item: any, index: number) => {
                              let bazaar: any = bazaarList.find((ele: any) => ele.value === item.bazaar_id);
                              console.log("Bazaar LOG ====> ", bazaar, bazaarWiseData, bazaarList)
                              let allStates: any = bazaar.bazaar_state_names.map((item: any) => (
                                {
                                  label: item.state,
                                  value: item.id
                                }
                              ))

                              let allDistricts: any = bazaar.bazaar_district_names.filter((item: any) => bazaarWiseData[index]?.state?.includes(item.state)) || []

                              let allCities: any = bazaar.bazaar_city_names.filter((item: any) => bazaarWiseData[index]?.district?.includes(item.district)) || []

                              console.log("Bazaar LOG ====> ", bazaar, bazaarWiseData, allDistricts, bazaarList, bazaarWiseData[index].allDistricts.map((item: any, index: number) => {
                                return (
                                  {
                                    ...item,
                                    district: item?.district?.filter((ele: any) => allDistricts.map((item: any) => item.id).includes(ele.id)) || []
                                  }
                                )
                              }))
                              
                              return (

                                <div className="formContainer flex flex-col">

                                  <p className=" font-bold">{bazaar?.label}</p>

                                  <div className="grid grid-cols-2 gap-4 w-full">

                                    <div>
                                      <p className="title-main">Assigned States <span className=" text-red-600">*</span></p>
                                      <Select
                                        label="Age"
                                        variant={"standard"}
                                        fullWidth={true}
                                        multiple={true}
                                        name="agent_assigned_state"
                                        value={bazaarWiseData[index]?.state}
                                        onBlur={handleBlur}
                                        onChange={(e: any) => {
                                          bazaarWiseData[index].state = e.target.value;
                                          bazaarWiseData[index].district = bazaarData.find((ele: any) => ele.state.includes(Number(e.target.value)) && ele.bazaar_id === item.bazaar_id)?.district || [];
                                          bazaarWiseData[index].city = bazaarData.find((ele: any) => ele.state.includes(Number(e.target.value)) && ele.bazaar_id === item.bazaar_id)?.city || [];
                                          setBazaarWiseData([...bazaarWiseData]);
                                          handleChange(e);
                                          getAllDis(
                                            {
                                              ids: e.target.value.join(",")
                                            },
                                            "multiple",
                                            index
                                          );
                                          setFieldValue("agent_assigned_district", [])
                                          setFieldValue("agent_assigned_city", [])
                                        }}
                                        renderValue={(selected) => {
                                          return allStates.filter((item: any) => selected.includes(item.value)).map((item: any) => item?.label)?.join(", ");
                                        }
                                        }
                                      >
                                        {allStates?.map((items: any) => (
                                          <MenuItem key={index} value={items.value}>
                                            <Checkbox checked={bazaarWiseData[index]?.state?.includes(items.value)} />
                                            <ListItemText primary={items.label} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_state" />
                                      </Box>
                                    </div>

                                    <div>
                                      <div className="Selectdistrict">
                                        <DistrictPicker
                                          label={"Assigned District"}
                                          required
                                          hint={"Search"}
                                          variant={"outlined"}
                                          states={allStates.map((item: any) => ({ state: item.label, id: item.value }))}
                                          selectedStates={[...bazaarWiseData[index]?.state]}
                                          districts={bazaarWiseData[index].allDistricts.map((item: any, index: number) => {
                                            return (
                                              {
                                                ...item,
                                                district: item?.district?.filter((ele: any) => allDistricts.map((item: any) => item.id).includes(ele.id)) || []
                                              }
                                            )
                                          })}
                                          selectedDistricts={bazaarWiseData[index]?.district}
                                          onSelectItem={(e: any, type: any) => {
                                            handleStateChange(bazaarWiseData[index]?.district, e, index, false, type)
                                            bazaarWiseData[index].city = bazaarData.find((ele: any) => ele.state.includes(Number(item.state)) && ele.bazaar_id === item.bazaar_id && ele.district.some((element: any) => bazaarWiseData[index]?.district.includes(element)))?.city || [];
                                            setBazaarWiseData([...bazaarWiseData]);
                                            setFieldValue("agent_assigned_city", [])
                                          }}
                                        />

                                      </div>

                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_district" />
                                      </Box>
                                    </div>
                                    <div>

                                      <CityPickerNew label={"Assigned Cities"}
                                        hint={"Search"}
                                        required
                                        variant={"outlined"}
                                        states={bazaarWiseData[index].allDistricts}
                                        selectedStates={bazaarWiseData[index]?.district}
                                        selectedDistricts={bazaarWiseData[index]?.district}
                                        districts={bazaarWiseData[index].allCities.map((item: any, index: number) => {
                                          // console.log("LOGGER123====> efwefwef", bazaarWiseData[index].allCities[index]?.city, allCities)
                                          return (
                                            {
                                              ...item,
                                              city: item?.city?.filter((ele: any) => allCities.map((item: any) => item.id).includes(ele.id)) || []
                                            }
                                          )
                                        })}
                                        selectedCity={bazaarWiseData[index]?.city}
                                        selectedcity={bazaarWiseData[index]?.city}
                                        onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(bazaarWiseData[index]?.city, id, index, true, type)}
                                      />
                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_city" />
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                              )
                            }) : null
                          }

                        </div>
                        {/* </Form> */}
                      </>
                    ) : null}

                    {values.agent_type === "AGENCY" ? (
                      <>
                        <div className={"w-[50%]"}>
                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Agent Person <span className=" text-red-600">*</span></p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="agent_name"
                                  value={values.agent_name}
                                  onBlur={handleBlur}

                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default behavior of the Enter key
                                      // Do not include navigate('/agents') here
                                    }
                                  }}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_name" />
                                </Box>
                              </div>

                              <div>
                                <p className="title-main">Agency Name <span className=" text-red-600">*</span></p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="agent_firm_name"
                                  value={values.agent_firm_name}

                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default behavior of the Enter key
                                      // Do not include navigate('/agents') here
                                    }
                                  }}

                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_firm_name" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <PhoneInput
                                  country={"in"}
                                  value={values.agent_number}
                                  countryCodeEditable={false}
                                  onBlur={(e) => {
                                    validateField("agent_number");
                                    setFieldTouched("agent_number");
                                  }}
                                  onChange={(phone) =>
                                    setFieldValue("agent_number", phone)
                                  }
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_number" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">Email Address <span className=" text-red-600">*</span></p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="agent_email"
                                  value={values.agent_email}
                                  onBlur={handleBlur}

                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default behavior of the Enter key
                                      // Do not include navigate('/agents') here
                                    }
                                  }}

                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_email" />
                                </Box>
                              </div>

                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">State <span className=" text-red-600">*</span></p>
                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  multiple={false}
                                  name="agent_state"
                                  value={values.agent_state}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("agent_district", "")
                                    setFieldValue("agent_city", "")
                                    getAllDis(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleState?.map((items: any) => (
                                    <MenuItem key={items.id} value={items.id}>
                                      {items.state}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_state" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">District <span className=" text-red-600">*</span></p>
                                <Select
                                  label="Bazaar"
                                  multiple={false}
                                  variant={"standard"}
                                  fullWidth={true}
                                  name="agent_district"
                                  value={values.agent_district}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("agent_city", "")
                                    getAllCity(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleDis?.map((items: any) => {
                                    return items?.district.map((item: any) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.district}
                                        </MenuItem>
                                      );
                                    });
                                  })}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_district" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="w-[50%]">
                              <p className="title-main">City <span className=" text-red-600">*</span></p>
                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={false}
                                name="agent_city"
                                value={values.agent_city}
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                              >
                                {singleCity?.map((items: any) => {
                                  return items?.city.map((item: any) => {
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.city}
                                      </MenuItem>
                                    );
                                  });
                                })}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                <ErrorMessage name="agent_city" />
                              </Box>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Bazaar <span className=" text-red-600">*</span></p>
                                <Autocomplete
                                  multiple
                                  id="bazaar-autocomplete"
                                  options={bazaarList}
                                  disableCloseOnSelect
                                  value={bazaarList?.filter((ele: any) => values.agent_bazaar.includes(ele.id)) || []}
                                  onChange={(event: any, value: any) => {
                                    console.log("Bazaar_DATA", event, value, event?.target, event.target.value);
                                    const values = value.map((ele: any) => ele.id);
                                    // return;
                                    let data: any = [];
                                    values?.map((item: any, index: number) => {
                                      // if(data[index]?.bazaar_id !== item){
                                      data = [...data, {
                                        bazaar_id: item,
                                        state: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.state || [],
                                        district: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.district || [],
                                        city: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.city || [],
                                        allDistricts: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.allDistricts || [],
                                        allCities: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.allCities || []
                                      }]
                                      // }
                                    })
                                    setBazaarWiseData(data);
                                    // handleChange(event);
                                    setFieldValue("agent_bazaar", values)
                                    setBazaar(values);
                                    console.log("Bazaar_DATA", event.target.value)
                                    setFieldValue("agent_assigned_state", "")
                                    setFieldValue("agent_assigned_district", [])
                                    setFieldValue("agent_assigned_city", [])
                                  }}
                                  getOptionLabel={(option) => option.label}
                                  isOptionEqualToValue={(option, value) => option.value === value}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox checked={values.agent_bazaar.includes(option.value)} />
                                      <ListItemText primary={option.label} />
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Bazaar" variant="standard" fullWidth />
                                  )}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_bazaar" />
                                </Box>
                              </div>

                              {/* <div>
                                <p className="title-main">Assigned States</p>

                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  // multiple={true}
                                  name="agent_assigned_state"
                                  value={values.agent_assigned_state}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setStates1(e.target.value)
                                    setFieldValue("agent_assigned_district", [])
                                    setFieldValue("agent_assigned_city", [])
                                    setDistricts1([])
                                  }}
                                  renderValue={(selected) => {
                                    return stateList1.find((item: any) => item.value === selected).label;
                                    var data: any = [];
                                    stateList1.map((item: any) => {
                                      if (selected = item.value) {
                                        data.push(item.label)
                                      }
                                    })
                                    return data.join(', ')
                                  }
                                  }
                                >
                                  {stateList1?.map((items: any, index: number) => (
                                    <MenuItem key={index} value={items.value}>
                                      <Checkbox checked={values.agent_assigned_state === items.value} />
                                      <ListItemText primary={items.label} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_state" />
                                </Box>
                              </div> */}
                            </div>
                          </div>

                          {/* <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <div className="Selectdistrict">
                                  <DistrictPicker
                                    label={"Assigned District"}
                                    hint={"Search"}
                                    variant={"outlined"}
                                    states={stateList1.map((item: any) => ({ state: item.label, id: item.value }))}
                                    selectedStates={[states1]}
                                    districts={allDis}
                                    selectedDistricts={values.agent_assigned_district}
                                    onSelectItem={(e: any, type: any) => {
                                      handleStateChange(values.agent_assigned_district, e, handleChange, false, type)
                                      setFieldValue("agent_assigned_city", [])
                                    }}
                                  />

                                </div>

                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_district" />
                                </Box>
                              </div>
                              <div>

                                <CityPickerNew label={"Assigned Cities"}
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allDis}
                                  selectedStates={districts1}
                                  selectedDistricts={values.agent_assigned_district}
                                  districts={allCity}
                                  selectedCity={values.agent_assigned_city}
                                  selectedcity={values.agent_assigned_city}
                                  onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(values.agent_assigned_city, id, handleChange, true, type)}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_city" />
                                </Box>
                              </div>
                            </div>
                          </div> */}


                          {
                            bazaarList.length ? bazaarWiseData.map((item: any, index: number) => {
                              let bazaar: any = bazaarList.find((ele: any) => ele.value === item.bazaar_id);
                              console.log("Bazaar LOG ====> ", bazaar, bazaarWiseData, bazaarList)
                              let allStates: any = bazaar.bazaar_state_names.map((item: any) => (
                                {
                                  label: item.state,
                                  value: item.id
                                }
                              ))

                              let allDistricts: any = bazaar.bazaar_district_names.filter((item: any) => bazaarWiseData[index]?.state?.includes(item.state)) || []

                              let allCities: any = bazaar.bazaar_city_names.filter((item: any) => bazaarWiseData[index]?.district?.includes(item.district)) || []

                              return (

                                <div className="formContainer flex flex-col">

                                  <p className=" font-bold">{bazaar?.label}</p>

                                  <div className="grid grid-cols-2 gap-4 w-full">

                                    <div>
                                      <p className="title-main">Assigned States <span className=" text-red-600">*</span></p>
                                      <Select
                                        label="Age"
                                        variant={"standard"}
                                        fullWidth={true}
                                        multiple={true}
                                        name="agent_assigned_state"
                                        value={bazaarWiseData[index]?.state}
                                        onBlur={handleBlur}
                                        onChange={(e: any) => {
                                          bazaarWiseData[index].state = e.target.value;
                                          bazaarWiseData[index].district = bazaarData.find((ele: any) => ele.state.includes(Number(e.target.value)) && ele.bazaar_id === item.bazaar_id)?.district || [];
                                          bazaarWiseData[index].city = bazaarData.find((ele: any) => ele.state.includes(Number(e.target.value)) && ele.bazaar_id === item.bazaar_id)?.city || [];
                                          setBazaarWiseData([...bazaarWiseData]);
                                          handleChange(e);
                                          getAllDis(
                                            {
                                              ids: e.target.value.join(",")
                                            },
                                            "multiple",
                                            index
                                          );
                                          setFieldValue("agent_assigned_district", [])
                                          setFieldValue("agent_assigned_city", [])
                                        }}
                                        renderValue={(selected) => {
                                          return allStates.filter((item: any) => selected.includes(item.value)).map((item: any) => item?.label)?.join(", ");
                                        }
                                        }
                                      >
                                        {allStates?.map((items: any) => (
                                          <MenuItem key={index} value={items.value}>
                                            <Checkbox checked={bazaarWiseData[index]?.state?.includes(items.value)} />
                                            <ListItemText primary={items.label} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_state" />
                                      </Box>
                                    </div>

                                    <div>
                                      <div className="Selectdistrict">
                                        <DistrictPicker
                                          label={"Assigned District"}
                                          hint={"Search"}
                                          variant={"outlined"}
                                          states={allStates.map((item: any) => ({ state: item.label, id: item.value }))}
                                          selectedStates={[...bazaarWiseData[index]?.state]}
                                          districts={bazaarWiseData[index].allDistricts.map((item: any, index: number) => {
                                            return (
                                              {
                                                ...item,
                                                district: item?.district?.filter((ele: any) => allDistricts.map((item: any) => item.id).includes(ele.id)) || []
                                              }
                                            )
                                          })}
                                          selectedDistricts={bazaarWiseData[index]?.district}
                                          onSelectItem={(e: any, type: any) => {
                                            handleStateChange(bazaarWiseData[index]?.district, e, index, false, type)
                                            bazaarWiseData[index].city = bazaarData.find((ele: any) => ele.state.includes(Number(item.state)) && ele.bazaar_id === item.bazaar_id && ele.district.some((element: any) => bazaarWiseData[index]?.district.includes(element)))?.city || [];
                                            setBazaarWiseData([...bazaarWiseData]);
                                            setFieldValue("agent_assigned_city", [])
                                          }}
                                        />

                                      </div>

                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_district" />
                                      </Box>
                                    </div>
                                    <div>

                                      <CityPickerNew label={"Assigned Cities"}
                                        hint={"Search"}
                                        variant={"outlined"}
                                        states={bazaarWiseData[index].allDistricts}
                                        selectedStates={bazaarWiseData[index]?.district}
                                        selectedDistricts={bazaarWiseData[index]?.district}
                                        districts={bazaarWiseData[index].allCities.map((item: any, index: number) => {
                                          // console.log("LOGGER123====> efwefwef", bazaarWiseData[index].allCities[index]?.city, allCities)
                                          return (
                                            {
                                              ...item,
                                              city: item?.city?.filter((ele: any) => allCities.map((item: any) => item.id).includes(ele.id)) || []
                                            }
                                          )
                                        })}
                                        selectedCity={bazaarWiseData[index]?.city}
                                        selectedcity={bazaarWiseData[index]?.city}
                                        onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(bazaarWiseData[index]?.city, id, index, true, type)}
                                      />
                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_city" />
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                              )
                            }) : null
                          }


                        </div>
                      </>
                    ) : null}

                    {values.agent_type === "SALESMAN" ? (
                      <>
                        <div className={"w-[50%]"}>
                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Agent Person <span className=" text-red-600">*</span></p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="agent_name"
                                  value={values.agent_name}

                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default behavior of the Enter key
                                      // Do not include navigate('/agents') here
                                    }
                                  }}

                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_name" />
                                </Box>
                              </div>
                              <div>
                                <PhoneInput
                                  country={"in"}
                                  value={values.agent_number}
                                  countryCodeEditable={false}
                                  onBlur={(e) => {
                                    validateField("agent_number");
                                    setFieldTouched("agent_number");
                                  }}
                                  onChange={(phone) =>
                                    setFieldValue("agent_number", phone)
                                  }
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_number" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">State <span className=" text-red-600">*</span></p>
                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  multiple={false}
                                  name="agent_state"
                                  value={values.agent_state}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("agent_district", "")
                                    setFieldValue("agent_city", "")
                                    getAllDis(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleState?.map((items: any) => (
                                    <MenuItem key={items.id} value={items.id}>
                                      {items.state}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_state" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">District <span className=" text-red-600">*</span></p>

                                <Select
                                  label="Bazaar"
                                  multiple={false}
                                  variant={"standard"}
                                  fullWidth={true}
                                  name="agent_district"
                                  value={values.agent_district}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("agent_city", "")
                                    getAllCity(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleDis?.map((items: any) => {
                                    return items?.district.map((item: any) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.district}
                                        </MenuItem>
                                      );
                                    });
                                  })}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_district" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="w-[50%]">
                              <p className="title-main">City <span className=" text-red-600">*</span></p>
                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={false}
                                name="agent_city"
                                value={values.agent_city}
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                              >
                                {singleCity?.map((items: any) => {
                                  return items?.city.map((item: any) => {
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.city}
                                      </MenuItem>
                                    );
                                  });
                                })}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                <ErrorMessage name="agent_city" />
                              </Box>
                            </div>

                            <div className="w-[50%]">
                              <div>
                                <p className="title-main">Email Address <span className=" text-red-600">*</span></p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="agent_email"
                                  value={values.agent_email}
                                  onBlur={handleBlur}

                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default behavior of the Enter key
                                      // Do not include navigate('/agents') here
                                    }
                                  }}

                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_email" />
                                </Box>
                              </div>
                            </div>

                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Bazaar <span className=" text-red-600">*</span></p>

                                <Autocomplete
                                  multiple
                                  id="bazaar-autocomplete"
                                  options={bazaarList}
                                  disableCloseOnSelect
                                  value={bazaarList?.filter((ele: any) => values.agent_bazaar.includes(ele.id)) || []}
                                  onChange={(event: any, value: any) => {
                                    console.log("Bazaar_DATA", event, value, event?.target, event.target.value);
                                    const values = value.map((ele: any) => ele.id);
                                    // return;
                                    let data: any = [];
                                    values?.map((item: any, index: number) => {
                                      // if(data[index]?.bazaar_id !== item){
                                      data = [...data, {
                                        bazaar_id: item,
                                        state: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.state || [],
                                        district: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.district || [],
                                        city: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.city || [],
                                        allDistricts: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.allDistricts || [],
                                        allCities: bazaarData.find((ele: any) => ele.bazaar_id === Number(item))?.allCities || []
                                      }]
                                      // }
                                    })
                                    setBazaarWiseData(data);
                                    // handleChange(event);
                                    setFieldValue("agent_bazaar", values)
                                    setBazaar(values);
                                    console.log("Bazaar_DATA", event.target.value)
                                    setFieldValue("agent_assigned_state", "")
                                    setFieldValue("agent_assigned_district", [])
                                    setFieldValue("agent_assigned_city", [])
                                  }}
                                  getOptionLabel={(option) => option.label}
                                  isOptionEqualToValue={(option, value) => option.value === value}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox checked={values.agent_bazaar.includes(option.value)} />
                                      <ListItemText primary={option.label} />
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Bazaar" variant="standard" fullWidth />
                                  )}
                                />

                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_bazaar" />
                                </Box>
                              </div>

                              {/* <div>
                                <p className="title-main">Assigned States</p>

                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  // multiple={true}
                                  name="agent_assigned_state"
                                  value={values.agent_assigned_state}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setStates1(e.target.value)
                                    setFieldValue("agent_assigned_district", [])
                                    setFieldValue("agent_assigned_city", [])
                                    setDistricts1([])
                                  }}
                                  renderValue={(selected) => {
                                    return stateList1.find((item: any) => item.value === selected).label;
                                    var data: any = [];
                                    stateList1.map((item: any) => {
                                      if (selected = item.value) {
                                        data.push(item.label)
                                      }
                                    })
                                    return data.join(', ')
                                  }
                                  }
                                >
                                  {stateList1?.map((items: any, index: number) => (
                                    <MenuItem key={index} value={items.value}>
                                      <Checkbox checked={values.agent_assigned_state === items.value} />
                                      <ListItemText primary={items.label} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_state" />
                                </Box>
                              </div> */}
                            </div>
                          </div>

                          {/* <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <div className="Selectdistrict">
                                  <DistrictPicker
                                    label={"Assigned District"}
                                    hint={"Search"}
                                    variant={"outlined"}
                                    states={stateList1.map((item: any) => ({ state: item.label, id: item.value }))}
                                    selectedStates={[states1]}
                                    districts={allDis}
                                    selectedDistricts={values.agent_assigned_district}
                                    onSelectItem={(e: any, type: any) => {
                                      handleStateChange(values.agent_assigned_district, e, handleChange, false, type)
                                      setFieldValue("agent_assigned_city", [])
                                    }}
                                  />

                                </div>

                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_district" />
                                </Box>
                              </div>
                              <div>
                                <CityPickerNew label={"Assigned Cities"}
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allDis}
                                  selectedStates={districts1}
                                  selectedDistricts={values.agent_assigned_district}
                                  districts={allCity}
                                  selectedCity={values.agent_assigned_city}
                                  selectedcity={values.agent_assigned_city}
                                  onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(values.agent_assigned_city, id, handleChange, true, type)}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="agent_assigned_city" />
                                </Box>
                              </div>
                            </div>
                          </div> */}



                          {
                            bazaarList.length ? bazaarWiseData.map((item: any, index: number) => {
                              let bazaar: any = bazaarList.find((ele: any) => ele.value === item.bazaar_id);
                              console.log("Bazaar LOG ====> ", bazaar, bazaarWiseData, bazaarList)
                              let allStates: any = bazaar.bazaar_state_names.map((item: any) => (
                                {
                                  label: item.state,
                                  value: item.id
                                }
                              ))

                              let allDistricts: any = bazaar.bazaar_district_names.filter((item: any) => bazaarWiseData[index]?.state?.includes(item.state)) || []

                              let allCities: any = bazaar.bazaar_city_names.filter((item: any) => bazaarWiseData[index]?.district?.includes(item.district)) || []

                              return (

                                <div className="formContainer flex flex-col">

                                  <p className=" font-bold">{bazaar?.label}</p>

                                  <div className="grid grid-cols-2 gap-4 w-full">

                                    <div>
                                      <p className="title-main">Assigned States <span className=" text-red-600">*</span></p>
                                      <Select
                                        label="Age"
                                        variant={"standard"}
                                        fullWidth={true}
                                        multiple={true}
                                        name="agent_assigned_state"
                                        value={bazaarWiseData[index]?.state}
                                        onBlur={handleBlur}
                                        onChange={(e: any) => {
                                          bazaarWiseData[index].state = e.target.value;
                                          bazaarWiseData[index].district = bazaarData.find((ele: any) => ele.state.includes(Number(e.target.value)) && ele.bazaar_id === item.bazaar_id)?.district || [];
                                          bazaarWiseData[index].city = bazaarData.find((ele: any) => ele.state.includes(Number(e.target.value)) && ele.bazaar_id === item.bazaar_id)?.city || [];
                                          setBazaarWiseData([...bazaarWiseData]);
                                          handleChange(e);
                                          getAllDis(
                                            {
                                              ids: e.target.value.join(",")
                                            },
                                            "multiple",
                                            index
                                          );
                                          setFieldValue("agent_assigned_district", [])
                                          setFieldValue("agent_assigned_city", [])
                                        }}
                                        renderValue={(selected) => {
                                          return allStates.filter((item: any) => selected.includes(item.value)).map((item: any) => item?.label)?.join(", ");
                                        }
                                        }
                                      >
                                        {allStates?.map((items: any) => (
                                          <MenuItem key={index} value={items.value}>
                                            <Checkbox checked={bazaarWiseData[index]?.state?.includes(items.value)} />
                                            <ListItemText primary={items.label} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_state" />
                                      </Box>
                                    </div>

                                    <div>
                                      <div className="Selectdistrict">
                                        <DistrictPicker
                                          label={"Assigned District"}
                                          hint={"Search"}
                                          variant={"outlined"}
                                          states={allStates.map((item: any) => ({ state: item.label, id: item.value }))}
                                          selectedStates={[...bazaarWiseData[index]?.state]}
                                          districts={bazaarWiseData[index].allDistricts.map((item: any, index: number) => {
                                            return (
                                              {
                                                ...item,
                                                district: item?.district?.filter((ele: any) => allDistricts.map((item: any) => item.id).includes(ele.id)) || []
                                              }
                                            )
                                          })}
                                          selectedDistricts={bazaarWiseData[index]?.district}
                                          onSelectItem={(e: any, type: any) => {
                                            handleStateChange(bazaarWiseData[index]?.district, e, index, false, type)
                                            bazaarWiseData[index].city = bazaarData.find((ele: any) => ele.state.includes(Number(item.state)) && ele.bazaar_id === item.bazaar_id && ele.district.some((element: any) => bazaarWiseData[index]?.district.includes(element)))?.city || [];
                                            setBazaarWiseData([...bazaarWiseData]);
                                            setFieldValue("agent_assigned_city", [])
                                          }}
                                        />

                                      </div>

                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_district" />
                                      </Box>
                                    </div>
                                    <div>

                                      <CityPickerNew label={"Assigned Cities"}
                                        hint={"Search"}
                                        variant={"outlined"}
                                        states={bazaarWiseData[index].allDistricts}
                                        selectedStates={bazaarWiseData[index]?.district}
                                        selectedDistricts={bazaarWiseData[index]?.district}
                                        districts={bazaarWiseData[index].allCities.map((item: any, index: number) => {
                                          // console.log("LOGGER123====> efwefwef", bazaarWiseData[index].allCities[index]?.city, allCities)
                                          return (
                                            {
                                              ...item,
                                              city: item?.city?.filter((ele: any) => allCities.map((item: any) => item.id).includes(ele.id)) || []
                                            }
                                          )
                                        })}
                                        selectedCity={bazaarWiseData[index]?.city}
                                        selectedcity={bazaarWiseData[index]?.city}
                                        onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(bazaarWiseData[index]?.city, id, index, true, type)}
                                      />
                                      <Box sx={{ color: "red" }}>
                                        <ErrorMessage name="agent_assigned_city" />
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                              )
                            }) : null
                          }



                        </div>
                      </>
                    ) : null}
                    <div className="flex gap-5 py-7">
                      <ActionButton
                        title="Cancel"
                        type="reset"
                        variant="primary"
                      />
                      {/* <button type="submit"> Save</button> */}
                      <ActionButton
                        type="submit"
                        title="Save"
                        variant="default"
                      />
                    </div>
                  </div>
                </div>
              </DashboardLayout>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default AddAgents;
