import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { HeaderSection } from "@/components/molecules/rulespermission/section";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";
import UserProfile from "@/static/images/User-profile.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { AppService } from "@/service/AllApiData.service";
import { GridOptionButton } from "@/components/atoms/Button";
import { BsTrash } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { Alert } from "@/alert/Alert";
import { Switch } from "@/components/atoms/Switch";
import { useAuth } from "@/context/authContext";

const RolePermission = () => {
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState<any>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [open, setOpen] = useState<any>(false);
  const { isAuthenticated, checkLogin, permissions } = useAuth();

  useEffect(() => {
    let params = { page: currentPage }
    fetchUsers(params);
  }, [currentPage]);

  const fetchUsers = async (params: any = {}) => {
    const res = await AppService.getUsers(params);
    setUsersList(res?.data?.results);
    setUserCount(res?.data?.count);
  };


  const handlePageChange = async (e: any, p: any) => {
    setCurrentPage(p);
  };

  const handleUserStatus = async (e: any, index: number, id: any, item: any) => {
    let textmsg = ""
    console.log(item, 'itemitem', e)
    if (e.target.checked) {
      console.log()
      textmsg = "Do you want to active user ?";
    } else {
      textmsg = "Do you want to inactive user ?";
    }

    if (window.confirm(textmsg)) {
        let body = {
          is_active: e.target.checked
        }
        let response = await AppService.patchUser(id, body);
        let params = { page: currentPage }
        if(!e.target.checked){
          Alert("User activated successfully")
        } else {
          Alert("User deactivated successfully")
        }
        fetchUsers(params);
    }

  }


  const deleteUser = async (id:any) => {
    try{
      const  response = await AppService.deleteUser(id);
      let params = { page: currentPage }
        fetchUsers(params);
        Alert("User deleted successfully")
    } catch (err:any) {
        console.log("ERROR ===> ",err)
    }
  }

  return (
    <DashboardLayout sectionName="roles_premission_section">
      <Box className="w-full px-[49px] mb-[50px] min-h-[calc(100vh_-_195px)]">
        <HeaderSection 
          open={open}
          setOpen={setOpen}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          reloadUsers={()=>fetchUsers({page: currentPage})}
        />
        <div className="mt-[35px]">
          <table className="w-full text-left">
            <thead className="border-b border-[#EBEAED]">
              <tr>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Sr No.
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Name
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Mobile Number
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Role
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Zonal Manager
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Bazaar
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  State
                </th><th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  District
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  City
                </th>

                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Status
                </th>
                <th className="text-[#84818A] text-right text-[14px] font-[600] font-[Manrope] px-[20px] pb-[11px]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {usersList.map((item: any, index: any) => (
                <tr className="border-b border-[#EBEAED]">
                  <td className="py-[22px] px-[20px]">
                    <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                      {index + 1 + ((currentPage - 1) * 10)}
                    </p>
                  </td>
                  <td className="py-[22px] px-[20px]">
                    <div className="flex gap-[10px] items-center">
                      <div className="flex flex-col">
                        <p className="text-[#2E2C34] text-[14px] font-[600] font-[Manrope]">
                          {item.first_name}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="py-[22px] px-[20px]">
                    <div className="flex gap-[6px] items-center">
                      <span className="text-[#84818A] text-[12px]">
                        <LocalPhoneIcon />
                      </span>
                      <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                        {item.username}
                      </p>
                    </div>
                  </td>
                  <td className="py-[22px] px-[20px]">
                    <div className="flex gap-[8px] items-center">
                      <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                        {item?.roles?.name}
                      </p>
                    </div>
                  </td>
                  <td className="py-[22px] px-[20px]">
                    <div className="flex gap-[6px] items-center">
                      <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                        {item?.zonal_manager?.first_name}
                      </p>
                    </div>
                  </td>
                  <td className="py-[22px] px-[20px]">
                    <div className="flex justify-between items-center">
                      {/* <span className="text-[#00A5FF] pt-[2px] pr-[8px] pb-[4px] pl-[8px] rounded font-[600] font-[Manrope] text-[12px] bg-[#00A5FF]/10">
                        {item?.bazzar?.bazaar_name}
                      </span> */}
                      <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                      {item?.bazzar?.bazaar_name}
                      </p>
                    </div>
                  </td>
                  <td className="py-[22px] px-[20px]">
                    <div className="flex gap-[6px] items-center">

                    <Tooltip title={item?.state_many?.map((item:any,index:number)=>(item?.state))?.join(", ")}>
                   <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                    {item?.state_many-2 > 0 ? `${item?.state_many[0]}, ${item?.state_many[0]}, + ${item?.state_many.length-2}` : item?.state_many?.map((item:any)=>item?.state)?.join(", ") }
                   </p>
                    </Tooltip>


                    </div>
                  </td>
                  <td className="py-[22px] px-[20px]">
                    <div className="flex gap-[6px] items-center">

                    <Tooltip title={item?.district_many?.map((item:any,index:number)=>(item?.district))?.join(", ")}>
                   <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                    {item?.district_many-2 > 0 ? `${item?.district_many[0]}, ${item?.district_many[0]}, + ${item?.district_many.length-2}` : item?.district_many?.map((item:any)=>item?.district)?.join(", ") }
                   </p>
                    </Tooltip>

                    </div>
                  </td>
                  <td className="py-3 pl-4 dataTitle cursor-pointer">
                    <div className="flex gap-[6px] items-center">
                    <Tooltip title={item?.city_many?.map((item:any,index:number)=>(item?.city))?.join(", ")} componentsProps={{
                                tooltip: {
                                  sx: {
                                    maxWidth: "80vw", // Adjust the width as needed
                                  },
                                },
                              }}>
                 <p className="py-3 px-4 text-left truncate">
                    {item?.city_many.length > 2 ? `${item?.city_many[0].city}, ${item?.city_many[1].city}, + ${item?.city_many.length-2}` : item?.city_many?.map((item:any)=>item?.city)?.join(", ") }
                   </p>
                    </Tooltip>
                    </div>
                  </td>
                  <td>
                    <div>
                      <Switch checked={item.is_active} onChange={(e: any) => handleUserStatus(e, index, item.id, item)} />
                    </div>
                  </td>
                  <td className="py-4 px-6 tableData cursor-pointer" style={{ justifyContent: "end", display: "flex", paddingRight: 0 }}>
                          {
                            <GridOptionButton
                              icon={"vertical-options"}
                              menus={[
                                {
                                  label: (
                                    <Tooltip title={!permissions.includes("permissions.update") ? "Not Authorized" : ""} placement="bottom">
                                    <span style={{display:'flex',alignItems:'center'}}>
                                      <span className="icon" style={{marginRight:'15px'}}>
                                        {/* <img src={complete} alt="dots" /> */}
                                        <BiEdit />
                                      </span>
                                      <p onClick={() => {
                                        if(!permissions.includes("permissions.update")){
                                          return;
                                        }
                                        setSelectedUser(item);
                                        setOpen(true);
                                      }}>
                                        Edit User
                                      </p>

                                    </span>
                                    </Tooltip>
                                  ),
                                },
                                {
                                  label: (
                                    <Tooltip title={!permissions.includes("permissions.delete") ? "Not Authorized" : ""} placement="bottom">
                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                      <span className="icon" style={{ marginRight: '15px' }}>
                                        <BsTrash />
                                      </span>
                                      <p onClick={() => {
                                        if (!permissions.includes("permissions.delete")) {
                                        return;
                                        }
                                        deleteUser(item.id);
                                      }}>
                                        Delete User
                                      </p>
                                      </span>
                                    </Tooltip>
                                  ),
                                },

                              ]}
                            />
                          }

                        </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>
      <div
        className="w-full h-[60px] bg-white"
        style={{ boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)" }}
      >
        <div className="flex justify-between items-center w-full h-full px-[39px]">
          <p className="text-[#84818A] font-[Manrope] text-[12px] font-[600]">
            Show {usersList.length} from {userCount} users
          </p>
          <div>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(userCount / 10)}
                page={currentPage}
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#84818A",
                    fontFamily: "Manrope",
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                  "& .Mui-selected": {
                    backgroundColor: "rgba(85, 66, 246, 0.1) !important",
                    color: "rgba(85, 66, 246, 1)",
                  },
                }}
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RolePermission;
