export function getDateRange(key) {
    const today = new Date();
    const start_date = new Date();
    const end_date = new Date();
  
    if (key === "today") {
      // Do nothing, start_date and end_date are already set to today.
    } else if (key === "this_week") {
      start_date.setDate(today.getDate() - today.getDay()); // Set to the beginning of the current week (Sunday).
      end_date.setDate(today.getDate() + (6 - today.getDay())); // Set to the end of the current week (Saturday).
    } else if (key === "last_week") {
      start_date.setDate(today.getDate() - today.getDay() - 7); // Set to the beginning of the last week (Sunday).
      end_date.setDate(today.getDate() - today.getDay() - 1); // Set to the end of the last week (Saturday).
    } else if (key === "this_month") {
      start_date.setDate(1); // Set to the first day of the current month.
      end_date.setMonth(today.getMonth() + 1, 0); // Set to the last day of the current month.
    } else if (key === "last_month") {
      start_date.setMonth(today.getMonth() - 1, 1); // Set to the first day of the last month.
      end_date.setMonth(today.getMonth(), 0); // Set to the last day of the last month.
    } else {
      throw new Error("Invalid key. Supported keys: 'today', 'this_week', 'last_week', 'this_month', 'last_month'");
    }
  
    return { 
        start_date: `${start_date.getFullYear()}-${start_date.getMonth()+1}-${start_date.getDate()}`, 
        end_date: `${end_date.getFullYear()}-${end_date.getMonth()+1}-${end_date.getDate()}` 
    };
  }
  
  
  export const getFormatedDateRange = (dateRange) => {
    let start_date = dateRange[0];
    let end_date = dateRange[1];

    return {
        start_date: `${start_date.year}-${start_date.month.number}-${start_date.day}`, 
        end_date: `${end_date.year}-${end_date.month.number}-${end_date.day}` 
    }
  }