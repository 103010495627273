import React, { useEffect, useState } from 'react';
import { AuthenticationLayout } from "@/components/layouts";
import { LoginForm, OtpForm, OtpSuccess } from "@/components/molecules/Login";
import { AppService } from '@/service/AllApiData.service';
// import { toast } from 'react-toastify';
import { isValidPhoneNumber } from '@/helper/verification';
import { useAuth } from '@/context/authContext';
import { Alert, AlertError } from '@/alert/Alert';

const Login = () => {
  const [isOtpVerification, setIsOtpVerification] = useState<boolean>(false);
  const [phonenumber, setPhonenumber] = useState('');
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const [count, setcount] = useState<any>(1);
  const [dataarray, setDataarray] = useState<any>([]);
  const [countryCode,setCountryCode] = useState("");
  const { isAuthenticated, checkLogin } = useAuth();

  // const agentdata = async (page: any) => {
  //   const responseJson = await AppService.getAllAgentList({ page: page ? page : 1 });
  //   if (responseJson.status == 200) {
  //     setcount((prev: any) => prev + 1)
  //     setDataarray((prev: any) => [...prev, ...responseJson.data.results])
  //   }
  // }

  // useEffect(() => {
  //   agentdata(count)
  // }, [count])

  useEffect(() => {
    if (!isOtpVerification && phonenumber != '') {
    }
  }, [])

  const inputvalues = (val: string) => {
    setPhonenumber(val)
  }

  // const handleCheckLogin = async () => {
  //   let hasValueLessThanTen = false
  //   if (phonenumber?.length == 10) {
  //     dataarray?.map((data: any, ind: any) => {
  //       if (data?.agent_number == `+91${phonenumber}`) {
  //         hasValueLessThanTen = true;
  //         if (data?.agent_type == "AGENCY") {
  //           if (data?.agent_status == "KYCAPPROVED") {
  //             setIsOtpVerification(true)
  //             toast.success("Number is valid!")
  //             localStorage.setItem("agency",JSON.stringify(data))
  //           }
  //           else{
  //             toast.error("kyc of agency is still pending!")
  //           }
  //         }
  //         else{
  //             toast.error("This number is not of agency!")
  //         }
  //       }
  //       else{
  //         if(dataarray.length == ind+1 && !hasValueLessThanTen){
  //           toast.error("Phone Number is invalid!")
  //         }
  //       }
  //     })
  //   }
  //   else {
  //     toast.error("please Enter valid Phone Number!")
  //   }
  // }


  const handleSendOTP = async (countryCode:string) => {
    setCountryCode(countryCode);
    if(isValidPhoneNumber(phonenumber)){
      let body = {
        phone_number: countryCode+phonenumber
      }
      try{
        const response = await AppService.sendOTP(body);
        setIsOtpVerification(true);
        Alert("OTP Sent Successfully");
      } catch (err:any) {
        // console.log(err)
        AlertError(err?.response?.data?.details || err?.message)
      }

    } else {
      AlertError("Please enter valid Phone Number!")
    }
  }

  const handleOTPVerification = async (otp:string) => {
    if(otp.length === 6){
      try{
        let body = {
          user_otp: otp,
          phone_number: countryCode+phonenumber
        }
        const { data } = await AppService.verifyOTP(body);

        // if(data.agent_data.agent_type === "AGENCY"){
          let token = {
            access_token: data.access_token,
            refresh_token: data.refresh_token
          }
          localStorage.setItem("token",JSON.stringify(token))
          // let body = {
          //   ...data.agent_data,
          //   agency_id: data.agency_id
          // }
          localStorage.setItem("user", JSON.stringify(data.data));
          Alert("Welcome Back!")
          checkLogin();
          setOtpSuccess(true);
        // } else {
        //   toast.error("Only agency login allowed!")
        // }
      } catch (err:any) {
        console.log(err)
        AlertError(err?.response?.data?.details || err?.message)
      }
    } else {
      AlertError("Please enter valid OTP!")
    }
  }



  return (
    <div>
      <AuthenticationLayout>
        {!isOtpVerification ? (
          <LoginForm
            onSuccess={handleSendOTP}
            inputvalues={inputvalues}
          />
        ) : (
          <>
            {!otpSuccess ?
              <OtpForm
                inputvalues={phonenumber}
                onGoBack={() => setIsOtpVerification(false)}
                onSuccess={handleOTPVerification}
              /> :
              <OtpSuccess />
            }
          </>
        )}
      </AuthenticationLayout>
    </div>
  );
};

export default Login;
