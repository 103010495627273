import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { AuthProvider } from './authContext';

interface AppProviderProps {
  children: ReactNode;
}



const AppContext = createContext<any>(undefined);


export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {


  return (
    <AppContext.Provider value={{}}>
      <AuthProvider>
      {children}
      </AuthProvider>
    </AppContext.Provider>
  );
};
