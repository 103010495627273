import { NavigateBefore } from '@mui/icons-material';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  isAuthenticated: boolean;
  checkLogin: () => void;
  permissions:any,
  userData:any,
  logout: any
}


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [ permissions, setPermissions ] = useState<any>([]);
  const [ userData,setUserData ] = useState<any>({});
  const [ loader, setLoader ] = useState<any>(true);


  const checkLogin = () => {
    const userToken = localStorage.getItem('token');
    const token = userToken ? JSON.parse(userToken).access_token : null;
    const user = localStorage.getItem('user');
    const data = user ? JSON.parse(user) : null;
    setIsAuthenticated(!!token); 
    setUserData(data || {});
    setPermissions(data?.permissions || []);
  };

  const logout = async () => {
    localStorage.clear()
    setIsAuthenticated(false); 
    setUserData({});
    setPermissions([]);
  }
  
  useEffect(()=>{
    console.log("logger1234...5",permissions.includes("dashboard.summary"))
    

  },[permissions])

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, checkLogin, permissions, userData, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
