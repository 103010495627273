import React, { useEffect, useState } from "react";
import { useAgentDetailsStyle } from "@/static/stylesheets/screens";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { Grid } from "@mui/material";
import { AppService } from "@/service/AllApiData.service";
import { formatIndianRupee } from "../AgentReport";
import { getDateRange, getFormatedDateRange } from "@/helper/date";
import moment from "moment";


interface props {
    id: any
}

const AgentPayment: React.FC<props> = ({ id }) => {

    const classes = useAgentDetailsStyle();
    const [walletData, setWalletData] = useState<any>();
    const [transactionHistory, setTransactionHistory] = useState<any>([]);
    const [reportFilter, setReportFilter] = React.useState<any>("today");
    const [dateRange, setDateRange] = React.useState<any>();
    const [year, setYear] = useState(new Date().getFullYear());

    const getWallet = async (params: any) => {
        try {
            const response = await AppService.getData("agent/data/" + id + "/wallet/", params)
            setWalletData(response?.data)
        } catch (err) {
            console.log("Wallet ERROR ====> ", err)
        }
    }

    const getTransactionHistory = async (params: any) => {
        try {
            const response = await AppService.getAgentsTransactionHistory(params);
            setTransactionHistory(response.data.results)
        } catch (err: any) {
            console.log("ERROR ====> ", err)
        }
    }

    useEffect(() => {
        let params = {};
        if (reportFilter) {
            const date = reportFilter === "range" ? getFormatedDateRange(dateRange) : getDateRange(reportFilter) as any
            params = { ...params, start_date: date?.start_date, end_date: date?.end_date }
        }
        getWallet(params);
        getTransactionHistory(params);
    }, [reportFilter])




    return (
        <>
            <div className={classes.root}>
                <div className="flex gap-2" style={{ flexWrap: "wrap" }}>
                    <ActionButton variant={reportFilter === 'today' ? "primary" : "default"} title={"Today"} onClick={() => setReportFilter('today')} />
                    <ActionButton variant={reportFilter === 'this_week' ? "primary" : "default"} title={"This Week"} onClick={() => setReportFilter('this_week')} />
                    <ActionButton variant={reportFilter === 'last_week' ? "primary" : "default"} title={"Last Week"} onClick={() => setReportFilter('last_week')} />
                    <ActionButton variant={reportFilter === 'this_month' ? "primary" : "default"} title={"This Month"} onClick={() => setReportFilter('this_month')} />
                    <ActionButton variant={reportFilter === 'last_month' ? "primary" : "default"} title={"Last Month"} onClick={() => setReportFilter('last_month')} />
                </div>
                <div className="py-[30px]">
                    {/*First Grid*/}
                    <Grid container spacing={2}>
                        <Grid item lg={5} md={5} sm={6} className="bazaarplan-div">
                            <div className="bg-[#F7F7F7] flex justify-between p-[30px] rounded-md">
                                <p className="agentpaymentTitle">Year - {year}</p>
                                <div>
                                    <p className="statusTitle">Agent Balance</p>
                                    <div className="flex gap-3 pt-[15px]">
                                        <p className="blancetitle">{formatIndianRupee(walletData?.agent_balance)}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} className="bazaarplan-div">
                            <div className="bg-[#F7F7F7] flex justify-between p-[30px] rounded-md">
                                <p className="agentpaymentTitle">Year - {year}</p>
                                <div>
                                    <p className="statusTitle">Agent Withdrawable Balance</p>
                                    <div className="flex gap-3 pt-[15px]">
                                        <p className="blancetitle">{formatIndianRupee(walletData?.agent_withdrawable_balance)}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} className="bazaarplan-div">
                            <div className="bg-[#F7F7F7] flex justify-between p-[30px] rounded-md">
                                <p className="agentpaymentTitle">Year - {year}</p>
                                <div>
                                    <p className="statusTitle">Total Amount Earned</p>
                                    <div className="flex gap-3 pt-[15px]">
                                        <p className="blancetitle">{formatIndianRupee(walletData?.total_amount_earned)}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} className="bazaarplan-div">
                            <div className="bg-[#F7F7F7] flex justify-between p-[30px] rounded-md">
                                <p className="agentpaymentTitle">Year - {year}</p>
                                <div>
                                    <p className="statusTitle">Total Amount Withdrawn</p>
                                    <div className="flex gap-3 pt-[15px]">
                                        <p className="blancetitle">{formatIndianRupee(walletData?.total_amount_withdrawn)}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        {/*Second Grid*/}
                        {/* <Grid item lg={5} md={5} sm={6} className="bazaarplan-div">
                            <div className="bg-[#F7F7F7] flex justify-between p-[30px] rounded-md">
                                <p className="agentpaymentTitle">Year - {year}</p>
                                <div>
                                    <p className="statusTitle">Available Balance</p>
                                    <div className="flex gap-3 pt-[15px]">
                                        <ActionButton variant={"primary"} title={"Pay Now"} />
                                        <p className="blancetitle">₹ 70,000</p>
                                    </div>
                                </div>
                            </div>
                        </Grid> */}
                    </Grid>


                    {/*Payment Request Table*/}
                    {/* <div>
                        <p className="mainheadYTitle py-[20px]">Payment Request</p>
                        <div className="prcontainer">
                            <div className="grid grid-cols-3 gap-4" style={{ alignItems: "center", borderBottom: "1px solid #e1e1e1", padding: "7px 20px" }}>
                                <div className="prtitle justify-center">21-07-2-22</div>
                                <div className="prtitle">Agent has requisted a payment of <span className="text-[#4E2FA9]">Rs 10,000</span></div>
                                <div className="text-end"><ActionButton variant={"primary"} title={"Pay Now"} /></div>
                            </div>
                            <div className="grid grid-cols-3 gap-4" style={{ alignItems: "center", padding: "15px 20px" }}>
                                <div className="prtitle justify-center">21-07-2-22</div>
                                <div className="prtitle">Agent has requisted a payment of <span className="text-[#4E2FA9]">Rs 10,000</span></div>
                                <div className="text-end"><ActionButton variant={"primary"} title={"Pay Now"} /></div>
                            </div>
                        </div>
                    </div> */}


                    {/*Transaction Table*/}
                    <div>
                        <p className="mainheadYTitle py-[20px]">Transaction History</p>
                        <div className="prcontainer" style={{ overflowX: "auto" }}>
                            <table className="w-full text-left">
                                <thead style={{ borderBottom: "1px solid #e1e1e1" }}>
                                    <tr className="color-[#2E2C34;]">
                                        <th scope="col" className="tableTitle py-3 px-6">
                                            Date
                                        </th>
                                        <th scope="col" className="tableTitle py-3 px-6">
                                            Translation
                                        </th>
                                        <th scope="col" className="tableTitle py-3 px-6">
                                            Transaction Id
                                        </th>
                                        <th scope="col" className="tableTitle py-3 px-6">
                                            Status
                                        </th>
                                        <th scope="col" className="tableTitle py-3 px-6">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactionHistory.map((item: any) => (
                                            <tr className="border-b">

                                                <th scope="row"
                                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    <div className="flex gap-[20px] items-center ">
                                                        <p className="mb-3 tableContentTitle cursor-pointer">
                                                            <div className="flex gap-5 items-center">
                                                                <p className="tableData">
                                                                    {moment(item?.created_at).format("DD-MM-YYYY")}
                                                                </p>
                                                            </div>
                                                        </p>
                                                    </div>
                                                </th>
                                                <td className="py-4 px-6 tableData">
                                                    {item?.message}
                                                </td>
                                                <td className="py-4 px-6 tableData">
                                                    {item?.order_id}
                                                </td>
                                                <td className="py-4 px-6 tableData">
                                                    {item.status == 'pending' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]" >
                                                        <p className="text-[#FFA043]">{item.status?.toUpperCase()}</p>
                                                    </div>}
                                                    {item.status == 'success' && <div className="bg-[#e6fcf7] flex justify-center rounded-md p-[10px] w-[150px]" >
                                                        <p className="text-[#00e0c0]">{item.status?.toUpperCase()}</p>
                                                    </div>}
                                                    {item.status == 'failed' && <div className="bg-[#ffeae5] flex justify-center rounded-md p-[10px] w-[150px]">
                                                        <p className="text-[#ff0000]">{item.status?.toUpperCase()}</p>
                                                    </div>}

                                                </td>
                                                <td className="py-4 px-6 tableData text-[#FC3400]">
                                                    {formatIndianRupee(item?.amount)}
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentPayment;
