import { useAuth } from "@/context/authContext";
import { routePaths } from "@/routes/routePaths";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CheckRoute: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, checkLogin, permissions } = useAuth();

  const getDefaultRoute = () => {
    if (permissions.includes("dashboard.summary")) {
      return routePaths.dashboard;
    }
    else if (permissions.includes("bazaar.list")) {
        return routePaths.admin.bazaars;
    }
    else if (permissions.includes("plans.list")) {
      return routePaths.plans;
    }
    else if (permissions.includes("agent.list")) {
      return routePaths.agents;
    }
    else if (permissions.includes("bazaar.create")) {
      return routePaths.newbazaars;
    } else if (permissions.includes("wholeseller.list")) {
      return routePaths.wholesellerlist;
    }  else if (permissions.includes("ads_client.create")) {
      return routePaths.CreateAdclient;
    } else if (permissions.includes("ads_plan.create")) {
      return routePaths.CreateAdsplan;
    } else if (permissions.includes("ads_invoices.list")) {
      return routePaths.Createinvoiceview;
    } else if (permissions.includes("agent.approve")) {
      return routePaths.agentkyc;
    }  else if (permissions.includes("wholeseller.update")) {
      return routePaths.Editwholeseller;
    } else if (permissions.includes("wholeseller.create")) {
      return routePaths.renewplan;
    } else if (permissions.includes("ads.create")) {
      return routePaths.addnewadvertisement;
    } else if (permissions.includes("activity_tracking.list")) {
      return routePaths.trackorder;
    } else if (permissions.includes("agent.list")) {
      return routePaths.agentdetails;
    }  else if (permissions.includes("master.list")) {
      return routePaths.admin.masterList;
    } else if (permissions.includes("reedem_model.list")) {
      return routePaths.agentCommissionRedeemModel;
    } else if (permissions.includes("users.list")) {
      return routePaths.rolePermission;
    } else if (permissions.includes("roles.list")) {
      return routePaths.rolesPermissions;
    } else {
      return "/404";
    }
  };

  useEffect(() => {
    if (permissions.length) {
      navigate(getDefaultRoute());
    }
  }, [permissions]);
  return (
    <div>
      {/* Your component content goes here */}
      {/* <h1>Check Route Component</h1> */}
    </div>
  );
};

export default CheckRoute;
