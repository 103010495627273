// import React from "react";
// import { DashboardLayout } from "@/components/layouts";
// import { useaddAdvertisementStyles } from "@/static/stylesheets/screens";
// import PaymentDetails from "@/components/molecules/PaymentDetails/PaymentDetails";
// const AddAdvertisement = () => {
//   const classes = useaddAdvertisementStyles();
//   return (
//     <>
//       <DashboardLayout>
//         <div className={classes.root}>
//           <div>
//             <PaymentDetails />
//           </div>
//         </div>
//       </DashboardLayout>
//     </>
//   );
// };
// export default AddAdvertisement;
import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { DashboardLayout } from "@/components/layouts";
import { useaddAdvertisementStyles } from "@/static/stylesheets/screens";
import { AppService } from "@/service/AllApiData.service";
import { MenuItem, Select, Checkbox, ListItemText, Autocomplete, TextField, Avatar, Dialog } from "@mui/material";
import { BiChevronLeft } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import ImageIcon from "@mui/icons-material/Image";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Alert, AlertError } from "@/alert/Alert";
import { readFileAsBase64 } from "@/helper/base64";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup"
import IcImage from "@/static/svg/ic_image.svg";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ActionButton } from "@/components/atoms/Button/ActionButton";

interface AddAdvertisementProps {
  type?: string
}
const AddAdvertisement = ({ type }: AddAdvertisementProps) => {
  const classes = useaddAdvertisementStyles();
  const [isOpen, setisOpen] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const [isOpen3, setisOpen3] = useState(false);
  const [isOpen4, setisOpen4] = useState(false);
  const [isOpen5, setisOpen5] = useState(false);
  const [isOpen6, setisOpen6] = useState(false);

  const [statedata, setstatedata] = useState<any>([]);
  const [bazaarsData, setBazaarsData] = useState<any>([]);
  const [refAlldata, setrefAlldata] = useState<any>([1]);
  const [Allwholeseller, setWholeSeller] = useState<any>([]);
  console.log(Allwholeseller, "all");
  const params = useParams();
  const [AllState, setAllState] = useState<any>([]);
  const [media, setMedia] = useState<any>("");
  const [media1, setMedia1] = useState<any>(null);
  console.log(media1, "medaiaa");
  const navigate = useNavigate();
  const getAllLists = async () => {
    const responseJson = await AppService.getTotalStates();
    setAllState(responseJson.data);
  };
  const [AddAdvertisement, setaddAdvertisement] = useState<any>({
    bazaar_name: "",
    created_for: "",
    choose_plan: "",
    wholeseller_number: "",
    ad_image: ""
    // state:[0],
    // city: [0],
    // district: [0],
    // mobile_number: "",
  });
  const [wholesaler, setWholesaler] = useState<any>();
  console.log(AddAdvertisement, "addd");

  const [Addrefdata, setAddrefdata] = useState({
    commission: "",
    enter_percentage: "",
    selectref: null,
  });
  const [Addrefdataerr, setAddrefdataerr] = useState({
    commission: "",
    enter_percentage: "",
    selectref: null,
  });
  const [clientsData, setClientsData] = useState<any>([]);
  console.log(clientsData, "clientss");
  const [plansData, setPlansData] = useState<any>([]);
  const adPickerRef = useRef<any>(undefined);
  const [imageModal, setImageModal] = useState<any>(false);
  const [image, setImage] = useState<any>("");


  const handleSaveAds = async () => {
    try {
      if (
        Addrefdata?.selectref !== null &&
        Addrefdata?.commission !== "" &&
        Addrefdata?.enter_percentage !== ""
      ) {
        const findbyid = refAlldata?.find(
          (it: any) => it?.id === Addrefdata?.selectref
        );
        const payload = {
          referred_by: findbyid?.referred_by,
          commission: Addrefdata?.commission?.toUpperCase(),
          enter_percentage: Addrefdata?.enter_percentage + "%",
        };
        const Appdata = await AppService?.Postrefrence(payload);
        if (Appdata?.status === 201) {
          setAddrefdata({
            commission: "",
            enter_percentage: "",
            selectref: null,
          });
          Alert("SuccessFully Added");
        }
      } else {
        if (Addrefdata?.selectref === null) {
          // setAddrefdataerr((prv: any) => ({
          //   ...prv,
          //   selectref: "Please Select Reference.",
          // }));
        } else {
          setAddrefdataerr((prv: any) => ({ ...prv, selectref: null }));
        }
        if (Addrefdata?.commission === "") {
          setAddrefdataerr((prv: any) => ({
            ...prv,
            commission: "Please Fill This Field.",
          }));
        } else {
          setAddrefdataerr((prv: any) => ({ ...prv, commission: "" }));
        }
        if (Addrefdata?.enter_percentage === "") {
          setAddrefdataerr((prv: any) => ({
            ...prv,
            enter_percentage: "Please Enter Any Number.",
          }));
        } else {
          setAddrefdataerr((prv: any) => ({ ...prv, enter_percentage: "" }));
        }
      }
    } catch (error: any) {
      let message = error.response.data.type + "\n";
      error.response.data.errors.map((row: any) => {
        message += row.attr + " : " + row.detail + "\n";
      });
      AlertError(message);
    }
  };
  const handleChangemedia = async (e: any) => {
    const { name, files } = e.target;
    const reader = new FileReader();
    if (files.length > 0) {
      reader.onloadend = () => {
        setMedia(reader.result);
      };
      // if (files[0] && files?.length > 0) {
      //   reader.readAsDataURL(files[0]);
      // }
      // const base64data = await readFileAsBase64(files[0]);
      setMedia1(files[0]);
    }
  };
  const handleChangeAllads = async (e: any, data: any = {}) => {
    const { name, value } = e?.target;
    console.log("logger123...", value, name, value, data, AddAdvertisement);
    if (name === "wholeseller_number") {
      console.log("logger123..", value, name, Allwholeseller.find((item: any) => item.wholeseller_number === value))
      setaddAdvertisement({ ...AddAdvertisement, wholeseller_number: Number(data.value) });
      setWholesaler(Allwholeseller.find((item: any) => item.id === data.value));
    }
    // if (name === "district") {
    //   setaddAdvertisement({
    //     ...AddAdvertisement,
    //     district: [parseInt(value)],
    //   });
    // }
    // if (name === "city") {
    //   setaddAdvertisement({ ...AddAdvertisement, city: [parseInt(value)] });
    // }
    if (name === "created_for") {
      setaddAdvertisement({
        ...AddAdvertisement,
        created_for: Number(data.value),
      });
    }
    // if (name === "bazaar_name") {
    //   setaddAdvertisement({
    //     ...AddAdvertisement,
    //     bazaar_name: [parseInt(value)],
    //   });
    // }

    // if (name === "choose_plan") {
    //   console.log(value, "casas");
    //   setaddAdvertisement({
    //     ...AddAdvertisement,
    //     choose_plan: value,
    //   });
    // }
  };

  const refencelistAll = async () => {
    const responceJson = await AppService.getAllrefrence();
    setrefAlldata(responceJson?.data?.results);
  };
  const fetchClients = async () => {
    const responceJson = await AppService.getAdsClient();
    setClientsData(responceJson?.data?.results);
  };
  const fetchPlans = async () => {
    const responceJson = await AppService.getAdsPlan();
    setPlansData(responceJson?.data?.results);
  };
  const getBazaars = async () => {
    const responceJson = await AppService.getAllBazaar();
    setBazaarsData(responceJson.data.results);
  };
  const getchWholeSailer = async () => {
    const responceJson = await AppService.getAllWholesellerListM();
    setWholeSeller(responceJson.data);
  };

  const getData = async () => {
    try {
      const response = await AppService.getAd(params.id);
      setaddAdvertisement({
        bazaar_name: response.data.bazaar,
        created_for: response.data.created_for ? {
          label: response.data.client_data.client_name,
          value: response.data.client_data.id
        } : null,
        choose_plan: response.data.choose_plan,
        wholeseller_number: response.data.wholeseller ? {
          label: response.data.wholeseller_data.wholeseller_number,
          value: response.data.wholeseller_data.id
        } : null,
        ad_image: "https://api.mwbbazaar.com/media" + response.data.media.split("media")[1]
      })

      if (response.data.wholeseller) {
        setWholesaler(Allwholeseller.find((item: any) => item.id === response.data.wholeseller));
      }

    } catch (err) {
      console.log("ERROR ====> ", err)
    }
  }

  useEffect(() => {
    getAllLists();
    refencelistAll();
    fetchClients();
    fetchPlans();
    getBazaars();
    getchWholeSailer();
    if (type) {
      getData()
    }
  }, []);

  const handleSubmit = async (values: any) => {
    if (type === "edit") {
      let data = {};

      data = {
        bazaar: values.bazaar_name,
        created_for: values.created_for.value,
        choose_plan: values.choose_plan,
        wholeseller: values.wholeseller_number.value,
      }

      if (!values.ad_image.includes("https://")) {
        data = {
          ...data,
          media: values.ad_image
        }
      }
      await AppService.patchAds(params.id, data);
      Alert("Advertisement updated successfully")
      navigate("/advertisement?active=1");
    } else {
      createAd(values)
    }
  }

  const createAd = async (values: any) => {
    const data = {
      bazaar: values.bazaar_name,
      created_for: values.created_for.value,
      choose_plan: values.choose_plan,
      wholeseller: values.wholeseller_number.value,
      media: values.ad_image
    };
    await AppService.createAds(data);
    Alert("Advertisement created successfully")
    navigate("/advertisement?active=1");
  };
  // const selectedBazaar = bazaarsData.find(
  //   (item: any) => item.id === AddAdvertisement.bazaar_name[0]
  // );
  // console.log(selectedBazaar, "seee");

  const validationSchema = yup.object({
    bazaar_name: yup.number().required("Bazaar is required"),
    created_for: yup.object(undefined).when("wholeseller_number", {
      is: (value: any) => !value,
      then: (schema: any) => schema.required("Created For is Required")
    }),
    choose_plan: yup.number().required("Bazaar is required"),
    ad_image: yup.string().required("Ad image is required")
  })

  const insertPicture = async (e: any, type: any, setFieldValue: any) => {
    console.log("insertPicture ddddddddidnt rrrrrrrr:");
    if (e.target.files[0]) {
      console.log("insertPicture  rrrrrrrr:");
      const url = await readFileAsBase64(e.target.files[0]);
      console.log("insertPicture =====>", url);
      setFieldValue(type, url);
    } else {
      console.log("dddddidnt runn=====");
    }
  };
  const removePicture = async (values: any, type: any, setFieldValue: any) => {
    if (values[type]) {
      setFieldValue(type, null);
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>, name:any, setState:any ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          if (img.width === 330 && img.height === 110) {
            insertPicture(
              event,
              name,
              setState
            )
          } else {
            alert('Selected image does not have the required resolution (330x110).');
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <div className="container">
            <div className="flex items-center gap-[24px]">
              <button
                className="text-[#84818A] text-[24px]"
                onClick={() => {
                  navigate("/advertisement?active=1");
                }}
              >
                <BiChevronLeft />
              </button>
              <div className="commonTitle">New Advertisement</div>
            </div>
            <Formik
              initialValues={AddAdvertisement}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {
                ({ values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setValues,
                  touched,
                  handleSubmit,
                  errors,
                  resetForm }) => (
                  <div className="mt-[54px]">
                    <div className="w-[50%]">
                      <div className="w-full">
                        <div className="flex gap-[32px] mb-[39px]">
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Select Bazaar
                            </label>

                            <Select
                              open={isOpen}
                              disabled={type === "view"}
                              onClose={() => setisOpen(false)}
                              onOpen={() => setisOpen(true)}
                              multiple={false}
                              variant="standard"
                              name="bazaar_name"
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              value={values.bazaar_name}
                              fullWidth={true}
                              onChange={handleChange}
                            >
                              {bazaarsData?.map((items: any) => {
                                return (
                                  <MenuItem key={items?.id} value={items?.id}>
                                    <ListItemText primary={items?.bazaar_name} />
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <div className="text-red-600">
                              <ErrorMessage name="bazaar_name" />
                            </div>
                          </div>
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Select Wholeseller/Retailer
                            </label>

                            <Autocomplete
                              value={values.wholeseller_number}
                              disabled={type === "view"}
                              fullWidth={true}
                              onChange={(e, data) => {
                                handleChange({ target: { name: "wholeseller_number", value: data } });
                                setWholesaler(Allwholeseller.find((item: any) => item.id === data.value));
                                setFieldValue("created_for", "")
                              }}
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              open={isOpen2}
                              onClose={() => setisOpen2(false)}
                              onOpen={() => setisOpen2(true)}
                              options={Allwholeseller?.map((item: any) => ({ label: item.wholeseller_number, value: item.id }))}
                              renderInput={(params) => <TextField {...params} variant="standard" name="wholeseller_number" />}
                            />

                            {/* <Select
                        open={isOpen2}
                        onClose={() => setisOpen2(false)}
                        onOpen={() => setisOpen2(true)}
                        multiple={false}
                        variant="standard"
                        name="wholeseller_number"
                        sx={{
                          height: 20,
                          width: 288,
                          borderBottomColor: "#EBEAED",
                        }}
                        value={AddAdvertisement.wholeseller_number}
                        fullWidth={true}
                        onChange={handleChangeAllads}
                      >
                        {Allwholeseller?.map((items: any) => {
                          return (
                            <MenuItem key={items?.id} value={items?.id}>
                              <ListItemText
                                primary={items?.wholeseller_number}
                              />
                            </MenuItem>
                          );
                        })}
                      </Select> */}
                          </div>
                          {/* <div>
                      <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                        Select State
                      </label>

                      <Select
                        open={isOpen2}
                        onClose={() => setisOpen2(false)}
                        onOpen={() => setisOpen2(true)}
                        multiple={false}
                        variant="standard"
                        name="state"
                        sx={{
                          height: 20,
                          width: 288,
                          borderBottomColor: "#EBEAED",
                        }}
                        value={AddAdvertisement.state}
                        fullWidth={true}
                        onChange={handleChangeAllads}
                      >
                        {selectedBazaar?.bazaar_state_names?.map(
                          (items: any) => {
                            return (
                              <MenuItem key={items?.id} value={items?.id}>
                                <ListItemText primary={items?.state} />
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </div> */}
                        </div>

                        <div className="flex gap-[32px] mb-[39px]">
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Wholesaler Firm Name
                            </label>

                            <TextField
                              value={wholesaler?.wholeseller_firm_name}
                              fullWidth={true}
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              variant="standard"
                              disabled

                            />
                          </div>
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Wholesaler name
                            </label>

                            <TextField
                              value={wholesaler?.wholeseller_firm_name}
                              fullWidth={true}
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              variant="standard"
                              disabled

                            />
                          </div>
                        </div>

                        <div className="flex gap-[32px] mb-[39px]">
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Wholesaler State
                            </label>

                            <TextField
                              value={wholesaler?.wholeseller_state?.state}
                              fullWidth={true}
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              variant="standard"
                              disabled

                            />
                          </div>
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Wholesaler District
                            </label>

                            <TextField
                              value={wholesaler?.wholeseller_district?.district}
                              fullWidth={true}
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              variant="standard"
                              disabled

                            />
                          </div>
                        </div>

                        <div className="flex gap-[32px] mb-[39px]">
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Wholesaler City
                            </label>

                            <TextField
                              value={wholesaler?.wholeseller_city?.city}
                              fullWidth={true}
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              variant="standard"
                              disabled

                            />
                          </div>
                        </div>
                        {/* <div className="flex gap-[32px] mb-[39px] items-center">
                    <div>
                      <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                        Select District
                      </label>

                      <Select
                        open={isOpen3}
                        onClose={() => setisOpen3(false)}
                        onOpen={() => setisOpen3(true)}
                        multiple={false}
                        variant="standard"
                        name="district"
                        sx={{
                          height: 20,
                          width: 288,
                          borderBottomColor: "#EBEAED",
                        }}
                        value={AddAdvertisement.district}
                        fullWidth={true}
                        onChange={handleChangeAllads}
                      >
                        {selectedBazaar?.bazaar_district_names?.map(
                          (items: any) => {
                            return (
                              <MenuItem key={items?.id} value={items?.id}>
                                <ListItemText primary={items?.district} />
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </div>
                    <div>
                      <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                        Select City
                      </label>
                      <Select
                        open={isOpen4}
                        onClose={() => setisOpen4(false)}
                        onOpen={() => setisOpen4(true)}
                        multiple={false}
                        variant="standard"
                        name="city"
                        sx={{
                          height: 20,
                          width: 288,
                          borderBottomColor: "#EBEAED",
                        }}
                        value={AddAdvertisement.city}
                        fullWidth={true}
                        onChange={handleChangeAllads}
                      >
                        {selectedBazaar?.bazaar_city_names?.map(
                          (items: any) => {
                            return (
                              <MenuItem key={items?.id} value={items?.id}>
                                <ListItemText primary={items?.city} />
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </div>
                  </div> */}

                        <div className="flex gap-[32px] mb-[39px] items-center">
                          {/* <div>
                      <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                        Plan Name
                      </label>
                      <input
                        type="text"
                        className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                        onChange={handleChangeAllads}
                      />
                    </div> */}
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Created for
                            </label>

                            <Autocomplete
                              disabled={
                                (values.wholeseller_number || type === "view")
                                  ? true
                                  : false
                              }
                              value={values.created_for}
                              fullWidth={true}
                              onChange={(e, data) => {
                                handleChange({ target: { name: "created_for", value: data } });
                              }}
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              open={isOpen5}
                              onClose={() => setisOpen5(false)}
                              onOpen={() => setisOpen5(true)}
                              options={clientsData.map((item: any) => ({ label: item.client_name, value: item.id }))}
                              renderInput={(params) => <TextField {...params} variant="standard" name="created_for" />}
                            />

                            <div className="text-red-600 mt-3">
                              <ErrorMessage name="created_for" />
                            </div>
                            {/* <Select
                        open={isOpen5}
                        onClose={() => setisOpen5(false)}
                        onOpen={() => setisOpen5(true)}
                        multiple={false}
                        disabled={
                          AddAdvertisement.wholeseller_number !== ""
                            ? true
                            : false
                        }
                        variant="standard"
                        name="created_for"
                        sx={{
                          height: 20,
                          width: 288,
                          borderBottomColor: "#EBEAED",
                        }}
                        value={AddAdvertisement.created_for}
                        fullWidth={true}
                        onChange={handleChangeAllads}
                      >
                        {clientsData?.map((items: any) => {
                          return (
                            <MenuItem key={items?.id} value={items?.id}>
                              <ListItemText primary={items?.client_name} />
                            </MenuItem>
                          );
                        })}
                      </Select> */}
                          </div>
                          <div>
                            <NavLink
                              to={"/createadclient"}
                              className={
                                "text-[#4E2FA9] underline text-[15px] font-[Manrope] font-[600]"
                              }
                            >
                              Add New Client
                            </NavLink>
                          </div>
                        </div>
                        <div className="flex items-center gap-10">
                          <div>
                            <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                              Choose Plan
                            </label>

                            <Select
                              open={isOpen6}
                              disabled={type === "view"}
                              onClose={() => setisOpen6(false)}
                              onOpen={() => setisOpen6(true)}
                              multiple={false}
                              variant="standard"
                              name="choose_plan"
                              sx={{
                                height: 20,
                                width: 288,
                                borderBottomColor: "#EBEAED",
                              }}
                              value={values.choose_plan}
                              fullWidth={true}
                              onChange={handleChange}
                            >
                              {plansData?.map((items: any) => {
                                return (
                                  <MenuItem key={items?.id} value={items?.id}>
                                    <ListItemText primary={items?.plan_name} />
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <div className="text-red-600">
                              <ErrorMessage name="choose_plan" />
                            </div>
                          </div>
                          <div>
                            <NavLink
                              to={"/createadsplan"}
                              className={
                                "text-[#4E2FA9] underline text-[15px] font-[Manrope] font-[600]"
                              }
                            >
                              Create new Plan
                            </NavLink>
                          </div>
                        </div>

                        <div className="mb-[30px] mt-10">
                          <div style={{ textAlign: "center" }} className="w-32">
                            <p className="pb-[10px]">Add Image</p>
                            <Avatar
                              onClick={() => type !== "view" && adPickerRef?.current?.click()}
                              className="cursor-pointer border-2"
                              sx={{
                                width: 110,
                                height: 100,
                                borderRadius: 2,
                                background: "white",
                              }}
                              variant="square"
                              style={{ margin: "0 auto" }}
                            >
                              <div className="flex gap-2">
                                {!values.ad_image ? (
                                  <img src={IcImage} alt={"Logo"} />
                                ) : (
                                  <img src={values.ad_image} />
                                )}
                                <p className="browseButton" style={{
                                  fontFamily: "Manrope, serif",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  lineHeight: "18px",
                                  textAlign: "center",
                                  color: "rgb(78, 47, 169)"
                                }}>Browse</p>
                              </div>
                            </Avatar>
                            <input
                              className="w-0 h-0 absolute"
                              type={"file"}
                              accept="image/*"
                              ref={adPickerRef}
                              onChange={(e: any) => {
                                handleImageChange(e,"ad_image",setFieldValue)
                              }
                              }
                            />
                            <div className="w-[110px] border-2 p-[10px] rounded-md flex gap-4" style={{ margin: "0 auto", marginTop: "15px" }}>
                              <div
                                onClick={() => {
                                  if(values.ad_image){
                                    setImageModal(true);
                                    setImage(values.ad_image);
                                  }
                                }}
                              >
                                <RemoveRedEyeOutlinedIcon className="cursor-pointer" />
                              </div>
                              <div className="border-l-2"></div>
                              {
                                type !== "view" &&
                                <div
                                  onClick={() => {
                                    removePicture(
                                      values,
                                      "ad_image",
                                      setFieldValue
                                    );
                                    adPickerRef.current.value = null
                                  }}
                                >
                                  <DeleteOutlinedIcon className="cursor-pointer text-[red]" />
                                </div>
                              }
                            </div>
                          </div>
                          <p className=" text-gray-400 text-sm">(Supported Resolution 330px X 110px)</p>
                          <div className="text-red-600 mt-2">
                            <ErrorMessage name="ad_image" />
                          </div>
                        </div>

                        <div className="flex gap-[12px]">
                          <button
                            className="h-[36px] border rounded bg-[#FFFFFF] px-[16px] py-[8px] capitalize text-[14px] flex justify-center items-center font-[Manrope] font-[600] text-[#2E2C34]"
                            onClick={() => {
                              navigate("/advertisement");
                            }}
                          >
                            cancel
                          </button>

                          {
                            type !== "view" &&
                            <button
                              className="h-[36px] border rounded bg-[#4E2FA9] px-[16px] py-[8px] capitalize text-[14px] flex justify-center items-center font-[Manrope] font-[600] text-[#fff]"
                              type="submit"
                              onClick={() => handleSubmit()}
                            >
                              Create Ad
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </Formik>
          </div>
        </div>



        <Dialog
          open={imageModal}
          maxWidth={"lg"}
          sx={{
            ".MuiPaper-root": {
              borderRadius: "20px",
            },
          }}
          onClose={() => {
            setImageModal(false);
            setImage("");
          }}
        >
          {/* <div className={classes.addDialog}> */}
          <img src={image} />

          <div className={"action-bar"}>
            <ActionButton
              variant={"default"}
              title={"Cancel"}
              onClick={() => {
                setImageModal(false);
                setImage("");
              }}
            />

            {/* <ActionButton
            variant={"primary"}
            title={"Submit"}
            onClick={() => {
              setImageModal(false);
              setImage("");
            }}
          /> */}
          </div>
          {/* </div> */}
        </Dialog>

      </DashboardLayout>
    </>
  );
};
export default AddAdvertisement;
