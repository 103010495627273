import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '@/components/layouts';
import { useNavigate, useParams } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AppService } from '@/service/AllApiData.service';
import { formatIndianRupee } from '../AgentDetails/AgentReport';
function InvoiceView() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [invoice,setInvoice] = useState<any>({});


    const getInvoiceData = async () => {
        try{
            const res = await AppService.getInvoiceData(id);
            setInvoice(res.data);
        } catch (err) {
            console.log("ERROR ====> ",err)
        }
    }

    useEffect(()=>{
        getInvoiceData();
    },[])

    return (
        <DashboardLayout>
            <div className='w-full py-[20px]'>
                <div className='container'>
                    <div className='flex items-center gap-[24px]'>
                        <button className='text-[#84818A] text-[24px]' onClick={() => { navigate('/advertisement?active=3') }}><BiChevronLeft /></button>
                        <div className="text-[#2e2c34] leading-[20px] text-[24px] font-[600] font-[Manrope]">Invoice - {invoice?.client_data?.client_name || invoice?.ad_data?.wholeseller_data?.wholeseller_firm_name}</div>
                    </div>
                    <div className='flex w-full items-center gap-[24px] mt-[41px] mb-[24px]'>
                        <div className='w-[50%] flex flex-col gap-[10px] py-[20px] pl-[24px]'>
                            <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Paid</span>
                            <p className='text-[#2e2c34] text-[24px] font-[600] font-[inter]'> {formatIndianRupee(invoice?.bill_amount || 0)}</p>
                        </div>
                        <div className='w-[50%] flex flex-col gap-[10px] py-[20px] pl-[24px]'>
                            <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>To be paid</span>
                            <p className='text-[#2e2c34] text-[24px] font-[600] font-[inter]'>{formatIndianRupee(invoice?.bill_amount || 0)}</p>
                        </div>
                    </div>
                    <div className='mb-[48px]'>
                        <p className='text-[#2e2c34] text-[14px] font-[700] font-[Manrope]'>{invoice.status}</p>
                        <div className='mt-[17px]'>
                            <div className='w-full flex flex-col gap-[24px]'>
                                <div className='flex justify-between items-center'>
                                    <div className='flex items-center gap-[22px]'>
                                        <span className='text-[#84818A]'><CreditCardIcon fontSize={'small'} /></span>
                                        <div className='flex flex-col'>
                                            <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Invoice # {invoice?.id}</span>
                                            <span className='text-[#84818A] text-[12px] font-[500] font-[Manrope]'>{invoice?.bill_date}</span>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-[52px]'>
                                        <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>{formatIndianRupee(invoice?.bill_amount || 0)}</span>
                                        <button className='text-[#84818A]' onClick={() => { navigate('/invoiceData/'+invoice?.id) }}><MoreHorizIcon fontSize={'small'} /></button>
                                    </div>
                                </div>
                                {/* <div className='flex justify-between items-center'>
                                    <div className='flex items-center gap-[22px]'>
                                        <span className='text-[#84818A]'><CreditCardIcon fontSize={'small'} /></span>
                                        <div className='flex flex-col'>
                                            <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Invoice # 12345</span>
                                            <span className='text-[#84818A] text-[12px] font-[500] font-[Manrope]'>Jul 29, 2022</span>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-[52px]'>
                                        <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Rs. 10,000.00</span>
                                        <button className='text-[#84818A]'><MoreHorizIcon fontSize={'small'} /></button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='mb-[24px]'>
                        <p className='text-[#2e2c34] text-[14px] font-[700] font-[Manrope]'>Paid (2)</p>
                        <div className='mt-[17px]'>
                            <div className='w-full flex flex-col gap-[24px]'>
                                <div className='flex justify-between items-center'>
                                    <div className='flex items-center gap-[22px]'>
                                        <span className='text-[#84818A]'><CreditCardIcon fontSize={'small'} /></span>
                                        <div className='flex flex-col'>
                                            <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Invoice # 12345</span>
                                            <span className='text-[#84818A] text-[12px] font-[500] font-[Manrope]'>Jul 29, 2022</span>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-[52px]'>
                                        <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Rs. 10,000.00</span>
                                        <button className='text-[#84818A]'><MoreHorizIcon fontSize={'small'} /></button>
                                    </div>
                                </div>
                                <div className='flex justify-between items-center'>
                                    <div className='flex items-center gap-[22px]'>
                                        <span className='text-[#84818A]'><CreditCardIcon fontSize={'small'} /></span>
                                        <div className='flex flex-col'>
                                            <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Invoice # 12345</span>
                                            <span className='text-[#84818A] text-[12px] font-[500] font-[Manrope]'>Jul 29, 2022</span>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-[52px]'>
                                        <span className='text-[#2e2c34] text-[14px] font-[600] font-[Manrope]'>Rs. 10,000.00</span>
                                        <button className='text-[#84818A]'><MoreHorizIcon fontSize={'small'} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </DashboardLayout>
    );
}

export default InvoiceView;