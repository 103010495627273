import React from "react";
import { useAgentDetailsStyle } from "@/static/stylesheets/screens";
import { RiAttachment2 } from "react-icons/ri";
import { HiPhone } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
interface AgentProfileType {
    data?: any;
    id?: any;
}
const AgentProfile: React.FC<AgentProfileType> = ({ data }) => {
    const classes = useAgentDetailsStyle();

    return (
        <>
            <div className={classes.root}>
                {/*Contact Container*/}
                {
                    data?.agent_type === "AGENCY" ? (
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={8} sm={12}>
                                <p className="border-b-2 py-[20px] agencydetailstitle">Agency Details</p>
                                <div className="flex justify-between py-[15px]">
                                    <p className="agentTitle">Contact person</p>
                                    <div className="agentSubtitle text-end">
                                        <p className="py-[7px]">{data?.agent_name}</p>
                                        <div className="flex gap-2 items-center justify-end">
                                            <HiPhone />
                                            <p className="py-[7px]">{data?.agent_number !== "" ? data?.agent_number : data?.agent_altranate_mobile_number}</p>
                                        </div>
                                        <p className="py-[7px]">{data?.agent_email}</p>
                                    </div>
                                </div>

                                <div className="flex justify-between py-[20px] border-t-2">
                                    <p className="agentTitle py-[7px]">Bazaar</p>
                                    <div className="agentSubtitle text-end">
                                        <div className="flex gap-2">

                                            {
                                                data?.agent_bazaar_data.map((item: any) => (

                                                    <div className="group relative">
                                                        <button className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm">
                                                            {item.bazaar_name}
                                                        </button>
                                                        <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                                            {item.bazaar_name}
                                                        </span>
                                                    </div>

                                                ))
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between py-[20px] border-t-2">
                                    <p className="agentTitle py-[7px]">Agency Address</p>
                                    <div className="agentSubtitle text-end">
                                        <p className="py-[7px]">{data?.agency?.address},{data?.agency?.city_name}, {data?.agency?.state_name}</p>
                                        <div className="flex gap-2 items-center justify-end text-[#FF6652] cursor-pointer">
                                            <FaMapMarkerAlt />
                                            <p className="py-[7px]" onClick={() => alert('clicked')}>Show Direction</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between py-[10px] border-t-2">
                                    <div>
                                        <p className="agentTitle py-[7px]">GST</p>
                                        <p className="agentTitle py-[7px]">PAN</p>
                                    </div>
                                    <div className="agentSubtitle text-end">
                                        <p className="py-[7px]">{data?.agency?.gst_number || "NA"}</p>
                                        <p className="py-[7px]">{data?.agency?.pan_number}</p>
                                    </div>
                                </div>

                                <div className="flex gap-2 items-center border-t-2 mt-5 pt-[10px]" style={{ paddingBottom: "10px" }}>
                                    <RiAttachment2 />
                                    <p>Document GST & PAN</p>
                                </div>
                                <div className="flex gap-[3px]" style={{ flexWrap: "wrap" }}>
                                    <img src={"https://api.mwbbazaar.com/media" + data?.agency?.gst_image?.split("media")[1]} alt={"Logo"} style={{ width: "215px", height: "140px", border: "1px solid #e1e1e1", marginRight: "15px", borderRadius: "15px" }} />
                                    <img src={"https://api.mwbbazaar.com/media" + data?.agency?.pancard_image?.split("media")[1]} alt={"Logo"} style={{ width: "215px", height: "140px", border: "1px solid #e1e1e1", borderRadius: "15px" }} />
                                </div>
                            </Grid>
                        </Grid>
                    ) : null
                }

                {/*Agent Container*/}
                <Grid container spacing={2}>
                    <Grid item lg={6} md={8} sm={12}>
                        <div className="py-10px">
                            <p className="border-b-2 py-[20px] agencydetailstitle">Agent Details</p>
                            <div className="flex justify-between py-[15px]">
                                <p className="agentTitle">Agent Name</p>
                                <div className="agentSubtitle text-end">
                                    <p className="py-[7px]">{data?.agent_name}</p>
                                    <div className="flex gap-2 items-center justify-end">
                                        <HiPhone />
                                        <p className="py-[7px]">{data?.agent_number !== "" ? data?.agent_number : data?.agent_altranate_mobile_number}</p>
                                    </div>
                                    <p className="py-[7px]">{data?.agent_email}</p>
                                </div>
                            </div>
                            <div className="flex justify-between py-[15px] border-t-2">
                                <p className="agentTitle">Date of Birth</p>
                                <div className="agentSubtitle text-end">
                                    <p className="py-[7px]">{data?.agent_date_of_birth}</p>
                                </div>
                            </div>

                            <div className="flex justify-between py-[20px] border-t-2">
                                <p className="agentTitle py-[7px]">Agency Address</p>
                                <div className="agentSubtitle text-end">
                                    <p className="py-[7px]">{data?.agent_address}</p>
                                    <div className="flex gap-2 items-center justify-end text-[#FF6652] cursor-pointer">
                                        <FaMapMarkerAlt />
                                        <p className="py-[7px]" onClick={() => alert('clicked')}>Show Direction</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between py-[20px] border-t-2">
                                <p className="agentTitle py-[7px]">Bazaar</p>
                                <div className="agentSubtitle text-end">
                                    <div className="flex gap-2">

                                        {
                                            data?.agent_bazaar_data.map((item: any) => (

                                                <div className="group relative">
                                                    <button className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm">
                                                        {item.bazaar_name}
                                                    </button>
                                                    <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                                        {item.bazaar_name}
                                                    </span>
                                                </div>

                                            ))
                                        }

                                    </div>
                                </div>
                            </div>


                            <div className="datContainer w-full flex-col" style={{ padding: "22px   0px" }}>
                                <p className="agentTitle py-[7px]">Assigned Bazaars</p>
                                <div className="mt-3">
                                    {
                                        data?.bazaar_assgined_data?.map((item: any, index: number) => (
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <div className="flex items-center gap-3">
                                                        <p className="mb-0">{data?.agent_bazaar_data.find((ele: any, index: number) => ele.id === item.bazaar_id).bazaar_name}</p>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <div className="flex items-center gap-3">
                                                                <p className="mb-0">Assigned States</p>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>


                                                            {
                                                                item.state.map((child: any, index1: number) => (
                                                                    <Accordion>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMore />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <div className="flex items-center gap-3">
                                                                                <p className="mb-0">{item.state_names[index1]}</p>
                                                                            </div>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>

                                                                            <Accordion>
                                                                                <AccordionSummary
                                                                                    expandIcon={<ExpandMore />}
                                                                                    aria-controls="panel1a-content"
                                                                                    id="panel1a-header"
                                                                                >
                                                                                    <div className="flex items-center gap-3">
                                                                                        <p className="mb-0">Assigned Districts</p>
                                                                                    </div>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>

                                                                                    {

                                                                                        item.allDistricts?.find((state: any) => state.state === child)?.district?.filter((dis: any) => item.district.includes(dis.id)).map((ele: any, index: number) => {



                                                                                            return (
                                                                                                <Accordion>
                                                                                                    <AccordionSummary
                                                                                                        expandIcon={<ExpandMore />}
                                                                                                        aria-controls="panel1a-content"
                                                                                                        id="panel1a-header"
                                                                                                    >
                                                                                                        <div className="flex items-center gap-3">
                                                                                                            <p className="mb-0">{ele.district}</p>
                                                                                                        </div>
                                                                                                    </AccordionSummary>
                                                                                                    <AccordionDetails>

                                                                                                        <Accordion>
                                                                                                            <AccordionSummary
                                                                                                                expandIcon={<ExpandMore />}
                                                                                                                aria-controls="panel1a-content"
                                                                                                                id="panel1a-header"
                                                                                                            >
                                                                                                                <div className="flex items-center gap-3">
                                                                                                                    <p className="mb-0">Assigned Cities</p>
                                                                                                                </div>
                                                                                                            </AccordionSummary>
                                                                                                            <AccordionDetails>

                                                                                                                {
                                                                                                                    item.allCities?.find((dis: any) => dis.district === ele.id)?.city?.filter((city: any) => item.city.includes(city.id)).map((city: any, index: number) => (
                                                                                                                        <p>{city.city}</p>

                                                                                                                    ))
                                                                                                                }


                                                                                                            </AccordionDetails>
                                                                                                        </Accordion>


                                                                                                    </AccordionDetails>
                                                                                                </Accordion>

                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </AccordionDetails>
                                                                            </Accordion>

                                                                        </AccordionDetails>
                                                                    </Accordion>

                                                                ))
                                                            }

                                                        </AccordionDetails>
                                                    </Accordion>

                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }


                                </div>
                            </div>

                            <div className="flex justify-between py-[10px] border-t-2">
                                <div>
                                    <p className="agentTitle py-[7px]">Adhaar</p>
                                    <p className="agentTitle py-[7px]">PAN</p>
                                </div>
                                <div className="agentSubtitle text-end">
                                    <p className="py-[7px]">{data?.agent_adharcard_no}</p>
                                    <p className="py-[7px]">{data?.agent_pancard_no}</p>
                                </div>
                            </div>


                            <div className="flex gap-2 items-center border-t-2 mt-5 pt-[10px]" style={{ paddingBottom: "10px" }}>
                                <RiAttachment2 />
                                <p>Document Aadhaar & PAN</p>
                            </div>
                            <div className="flex gap-[3px]" style={{ flexWrap: "wrap" }}>
                                <img src={data?.agent_adhar_front_image} alt={"Logo"} style={{ width: "215px", height: "140px", border: "1px solid #e1e1e1", marginRight: "15px", borderRadius: "15px" }} />
                                <img src={data?.agent_pancard_image} alt={"Logo"} style={{ width: "215px", height: "140px", border: "1px solid #e1e1e1", borderRadius: "15px" }} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default AgentProfile;
