import React from 'react'
import { createPortal } from 'react-dom';
import "./loading.css";

function Loading() {
  return (
  <div className='contentdatas'>
   <div className="loading">
    <div></div>
    <div></div>
  </div>  
    </div>
  )
}

export default Loading