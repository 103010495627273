import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '@/components/layouts';
import { BiChevronLeft } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { AppService } from '@/service/AllApiData.service';
import { formatIndianRupee } from '../AgentDetails/AgentReport';
function InvoiceViewData() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [invoice,setInvoice] = useState<any>({});


    const getInvoiceData = async () => {
        try{
            const res = await AppService.getInvoiceData(id);
            setInvoice(res.data);
        } catch (err) {
            console.log("ERROR ====> ",err)
        }
    }

    useEffect(()=>{
        getInvoiceData();
    },[])


    return (
        <DashboardLayout>
            <div className='w-[70%] py-[20px]'>
                <div className='container'>
                    <div className='flex items-center gap-[24px]'>
                        <button className='text-[#84818A] text-[24px]' onClick={() => { navigate('/invoiceview') }}><BiChevronLeft /></button>
                        <div className="text-[#2e2c34] leading-[20px] text-[24px] font-[600] font-[Manrope]">Invoice # {invoice?.id}</div>
                    </div>
                    <div className='mt-[26px] mb-[30px]'>
                        <div className='flex justify-between items-center'>
                            <div className='flex items-center gap-[14px]'>
                                <div className='w-[48px] h-[48px] rounded-xl'>
                                    <img src={"https://api.mwbbazaar.com"+invoice?.ad_data?.media} alt="" className='w-full h-full rounded-xl object-cover' />
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-[#2e2c34] text-[20px] font-[700] font-[Manrope]'>{invoice?.client_data?.client_name || invoice?.ad_data?.wholeseller_data?.wholeseller_firm_name}</span>
                                    <span className='text-[#84818A] text-[16px] font-[400] font-[Manrope]'>{invoice?.bill_date}</span>
                                </div>
                            </div>
                            <div>
                                {
                                    invoice?.status === "Awaiting Payment" ?
                                    <button className='text-[#ffc04c] bg-[#ffc04c]/[0.1] w-[110px] rounded-lg h-[36px] font-[Lato] font-[700] text-[14px]'>{invoice?.status}</button>
                                    :
                                    <button className='text-[#7CE7AC] bg-[#7CE7AC]/[0.1] w-[110px] rounded-lg h-[36px] font-[Lato] font-[700] text-[14px]'>{invoice?.status}</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='mb-[50px]'>
                        <div className='flex justify-between items-center mb-[20px]'>
                            <p className='text-[#84818A] font-[Lato] font-[700] text-[12px]'>From:</p>
                            <p className='text-[#84818A] font-[Lato] font-[700] text-[12px]'>Bill to:</p>
                        </div>
                        <div className='flex justify-between items-center mb-[20px]'>
                            <p className='text-[#84818A] font-[Lato] font-[700] text-[14px]'>MBW Bazaar</p>
                            <p className='text-[#84818A] font-[Lato] font-[700] text-[14px]'>{invoice?.client_data?.client_name}</p>
                        </div>
                        <div className='border-[#EEEEEE] border-b pb-[27px]'>
                            <ul className='list-none m-0 p-0'>
                                <li className='flex justify-between items-center'>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>The Business Centre</p>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'><p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'> {invoice?.client_data?.city?.city}</p></p>
                                </li>
                                <li className='flex justify-between items-center'>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>61 Wellfield Road</p>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'><p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'> {invoice?.client_data?.district?.district || ""}</p></p>
                                </li>
                                <li className='flex justify-between items-center'>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>Roath</p>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'><p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'> {invoice?.client_data?.state?.state}</p></p>
                                </li>
                                <li className='flex justify-between items-center'>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>Cardiff</p>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>India</p>
                                </li>
                                {/* <li className='flex justify-between items-center'>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>CF24 3DG</p>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>BA1 2FJ</p>
                                </li> */}
                                <li className='flex justify-between items-center'>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>mail@wonw.xyz</p>
                                    {/* <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>mail@dropdox.com</p> */}
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'> {invoice?.client_data?.phoneNumber}</p>
                                </li>
                                <li className='flex justify-between items-center'>
                                    <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>+1 456-980-3004</p>
                                    {/* <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'> {invoice?.client_data?.phoneNumber}</p> */}
                                </li>
                            </ul>
                        </div>
                        <div className='mt-[22px]'>
                            <div className='mb-[17px]'>
                                <p className='text-[#2E2C34] text-[18px] font-[700] font-[Lato]'>
                                    Description
                                </p>
                            </div>
                            <table className='w-full text-left'>
                                <thead className='h-[39px] bg-[#F6F6F6]'>
                                    <tr>
                                        <th className='text-[#84818A] rounded-tl-[6.5px] rounded-bl-[6.5px] px-[14px] py-[10px] font-[Lato] font-[700] text-[12px]'>Item</th>
                                        <th className='text-[#84818A] font-[Lato] font-[700] text-[12px] w-[10%] px-[14px] py-[10px]'>Price</th>
                                        <th className='text-[#84818A] font-[Lato] font-[700] text-[12px] w-[10%] rounded-tr-[6.5px] rounded-br-[6.5px] px-[14px] py-[10px]'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='px-[14px] py-[8px]'>
                                            <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>
                                                {invoice?.ad_data?.choose_plan_data?.plan_name}
                                            </p>
                                        </td>
                                        <td className='px-[14px] py-[8px]'>
                                            <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>
                                                {formatIndianRupee(invoice?.ad_data?.choose_plan_data?.price-((invoice?.ad_data?.choose_plan_data?.price*10)/100))}
                                            </p>
                                        </td>
                                        <td className='px-[14px] py-[8px]'>
                                            <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>
                                            {formatIndianRupee(invoice?.ad_data?.choose_plan_data?.price-((invoice?.ad_data?.choose_plan_data?.price*10)/100))}
                                            </p>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td className='px-[14px] py-[8px]'>
                                            <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>
                                                Item Name
                                            </p>
                                        </td>
                                        <td className='px-[14px] py-[8px]'>
                                            <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>
                                                Rs. 10
                                            </p>
                                        </td>
                                        <td className='px-[14px] py-[8px]'>
                                            <p className='text-[#2E2C34] font-[Lato] font-[400] text-[14px]'>
                                                Rs.3560
                                            </p>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <div className='flex justify-end'>
                                <div className='w-[250px]'>
                                    <div className='flex justify-between items-center mb-[16px]'>
                                        <p className='text-[#1C1D21] font-[Lato] font-[700] text-[14px]'>Subtotal</p>
                                        <p className='text-[#1C1D21] font-[Lato] font-[400] text-[14px]'>{formatIndianRupee(invoice?.ad_data?.choose_plan_data?.price)}</p>
                                    </div>
                                    <div className='flex justify-between items-center mb-[14px]'>
                                        <p className='text-[#1C1D21] font-[Lato] font-[700] text-[14px]'>GST 10%</p>
                                        <p className='text-[#1C1D21] font-[Lato] font-[400] text-[14px]'>{formatIndianRupee((invoice?.ad_data?.choose_plan_data?.price*10)/100)}</p>
                                    </div>
                                    <div className='flex justify-between items-center mb-[16px]'>
                                        <p className='text-[#1C1D21] font-[Lato] font-[700] text-[16px]'>Total</p>
                                        <p className='text-[#1C1D21] font-[Lato] font-[700] text-[16px]'>{formatIndianRupee(invoice?.ad_data?.choose_plan_data?.price)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default InvoiceViewData;