import React from "react";
import { useAgentDetailsStyle } from "@/static/stylesheets/screens";
import { RiAttachment2 } from "react-icons/ri";
import { HiPhone } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Grid } from "@mui/material";
import { AppService } from "@/service/AllApiData.service";
import { PlanCard } from "@/components/molecules/PlanCard";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useNavigate } from "react-router-dom";
import IcCheckMark from "@/static/svg/ic_checkmark.svg";
import moment from "moment";

interface WholesalerPlanType {
  data?: any;
  id?: any;
}
const WholesalerPlan: React.FC<WholesalerPlanType> = ({ data, id }) => {
  const classes = useAgentDetailsStyle();
  const [Allagents, setAllagents] = React.useState<any>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    getAgentAllLists()
  }, [])

  const getAgentAllLists = async () => {
    const responseJson = await AppService.getAllAgents();
    setAllagents(responseJson.data.results)
  }
  console.log('Allagents', Allagents)
  return (
    <>
      <div className={classes.root}>
        {/*Contact Container*/}
        <Grid container spacing={2}>
          <Grid item lg={6} md={8} sm={12}>
            <p className="py-[20px] agencydetailstitle" style={{ borderBottom: "1px solid #e1e1e1" }}>My Plan</p>
            <div className="flex justify-between py-[15px]">
              <p className="agentTitle whitespace-nowrap mr-10">Plan Expiry</p>
              <div>
              <button style={{ color: "#00A5FF", fontWeight: 600, fontSize: "13px", lineHeight: "18px", background: "rgba(0, 165, 255, 0.1)", padding: "6px 15px", margin: "20px 20px 10px", borderRadius: "4px" }}>Expiry day: {moment().add(data?.no_of_days_remaining_plan_expiry, "days").format("DD MMM, YYYY")}</button>
              {
                !(data?.no_of_days_remaining_plan_expiry > 0) ?
                <ActionButton title="Renew Plan" variant="primary" onClick={() => navigate(`/wholesalerdetails/${id}/plans/`)} /> : null
              }
              </div>
            </div>
            <div className="flex justify-between py-[15px]">
              <p className="agentTitle whitespace-nowrap mr-10">Current Plan</p>
            </div>
              <div className="agentSubtitle text-end flex gap-5 flex-wrap justify-end">
                  {
                    data.wholeseller_plan.map((item:any,index:number)=>(
                      <PlanCard item={item.plan} active={!!item.is_active} no_of_days_remaining_plan_expiry={data?.no_of_days_remaining_plan_expiry} disabled={true} />
                    ))
                  }
              </div>


            <div className="flex justify-between py-[15px]">
              <p className="agentTitle whitespace-nowrap mr-10">Active Addons</p>
              <div className="agentSubtitle flex gap-5">

                {
                  data.wholeseller_plan_addons ? [data.wholeseller_plan_addons].map((item: any, index: number) => (
                    // <Grid item lg={4} md={4} sm={6} style={{ paddingTop: 0 }} >
                    <div className="h-full " >
                      <div className={`border border-[#4E2FA9] rounded-xl p-[30px] h-full relative`}>

                        <div
                          className="flex justify-between"
                          style={{ alignItems: "center" }}
                        >
                          <div>
                            <p className="text-[#4E2FA9] text-[22px] font-bold">
                              {item?.plan_name}
                            </p>

                          </div>
                          <div className="bg-[#F9F5F2] top-[20px] left-[215px] rounded-full flex flex-col w-[100px] h-[100px] text-center items-center justify-center">
                            <p className="text-[#FF6652] text-[28px] font-bold leading-none">

                              <p
                                className="text-[#FF6652] text-[28px]"
                              >
                                ₹{item?.amount}

                              </p>
                              {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                           For {localStoragedata.plan_periods_in_days}
                      </p> */}
                              {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                                            {localStoragedata.plan_periods_in_days} Days
                                          </p> */}
                              {/* </> */}
                            </p>
                          </div>
                        </div>


                        <div className="mt-[40px]">
                          <p className="font-[#121127] text-[24px] text-left font-bold mb-2">
                            Features
                          </p>
                          <div
                            className="flex gap-5 pb-6 all-features-div"
                            style={{ overflowY: "auto" }}
                          >
                            <ul>
                              {/* {previewData?.featureData?.length > 0 ? (
                        previewData?.featureData.map((it: any) => ( */}
                              {
                                item?.plan_tally &&
                                <>
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        Tally Support
                                      </p>
                                    </div>
                                  </li>
                                </>
                              }
                              {
                                item?.whatsapp_notification_limit &&
                                <>
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.whatsapp_notification_limit} Whatsapp Notification
                                      </p>
                                    </div>
                                  </li>
                                </>
                              }

                              {
                                item?.sms_notification_limit &&
                                <li className="mb-[5px]">
                                  <div className="flex gap-4 items-center">
                                    <img
                                      className="w-[15px]"
                                      src={IcCheckMark}
                                      alt={"Logo"}
                                    />
                                    <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                      {item?.sms_notification_limit} SMS Notification
                                    </p>
                                  </div>
                                </li>
                              }

                              {
                                item?.agents_limit &&
                                <li className="mb-[5px]">
                                  <div className="flex gap-4 items-center">
                                    <img
                                      className="w-[15px]"
                                      src={IcCheckMark}
                                      alt={"Logo"}
                                    />
                                    <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                      {item?.agents_limit} Agents
                                    </p>
                                  </div>
                                </li>
                              }

                              {
                                item?.products_limit &&
                                <li className="mb-[5px]">
                                  <div className="flex gap-4 items-center">
                                    <img
                                      className="w-[15px]"
                                      src={IcCheckMark}
                                      alt={"Logo"}
                                    />
                                    <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                      {item?.products_limit} Products
                                    </p>
                                  </div>
                                </li>
                              }

                              {
                                item?.orders_limit ?
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.orders_limit} Order
                                      </p>
                                    </div>
                                  </li> : ""
                              }

                              {
                                item?.branches ?
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.branches} Branches
                                      </p>
                                    </div>
                                  </li> : ""
                              }

                              {
                                item?.retailers_limit &&
                                <li className="mb-[5px]">
                                  <div className="flex gap-4 items-center">
                                    <img
                                      className="w-[15px]"
                                      src={IcCheckMark}
                                      alt={"Logo"}
                                    />
                                    <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                      {item?.retailers_limit} Retailers
                                    </p>
                                  </div>
                                </li>
                              }

                              {
                                item?.users_limit &&
                                <li className="mb-[5px]">
                                  <div className="flex gap-4 items-center">
                                    <img
                                      className="w-[15px]"
                                      src={IcCheckMark}
                                      alt={"Logo"}
                                    />
                                    <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                      {item?.users_limit} Users
                                    </p>
                                  </div>
                                </li>
                              }

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    // </Grid>
                  )) : null
                }
              </div>
            </div>


            <div className="flex justify-between py-[15px]">
              <p className="agentTitle">Email Notification Usage</p>
              <div className="agentSubtitle text-end">
                <p className="agentTitle">{data?.plan_usage?.email_notification_usage}</p>
              </div>
            </div>

            <div className="flex justify-between py-[15px]">
              <p className="agentTitle">SMS Notification Usage</p>
              <div className="agentSubtitle text-end">
                <p className="agentTitle">{data?.plan_usage?.sms_notification_usage}</p>
              </div>
            </div>

            <div className="flex justify-between py-[15px]">
              <p className="agentTitle">Whatsapp Notification Usage</p>
              <div className="agentSubtitle text-end">
                <p className="agentTitle">{data?.plan_usage?.whatsapp_notification_usage}</p>
              </div>
            </div>

            <div className="flex justify-between py-[15px]">
              <p className="agentTitle">Addon Plan</p>
              <div className="agentSubtitle text-end">

                {/* <PlanCard item={data.wholeseller_plan_addons}/> */}

                <ActionButton title="Buy Addons" variant="primary" onClick={() => navigate(`/wholesalerdetails/${id}/addons/${data?.wholeseller_plan?.find((item:any)=>item.is_active).plan.id}`)} />
              </div>
            </div>

            <div className="flex justify-between py-[15px]">
              <p className="agentTitle"> 
                {
                  !data.no_of_days_remaining_plan_expiry ?
                  "Buy Plan" : "Upgrade Plan"
                }
              </p>
              <div className="agentSubtitle text-end">

                {/* <PlanCard item={data.wholeseller_plan_addons}/> */}

                <ActionButton title="Upgrade Plan" variant="primary" onClick={() => navigate(`/wholesalerdetails/${id}/plans/`)} />
              </div>
            </div>

          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default WholesalerPlan;
