import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { SectionHeader } from "@/components/molecules/Bazaars";
import { MenuItem, Select, Checkbox, ListItemText } from "@mui/material";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import { useAdclientStyles } from "@/static/stylesheets/screens/addclientadStyles";
import * as Yup from "yup";
import { gstRegex, phoneRegex } from "@/constant";
import { ErrorMessage, Formik } from "formik";
import axios from "axios";
import { Alert } from "@/alert/Alert";

interface CreateClientAdProps{
  type?:string
}

function CreateClientAd({type}:CreateClientAdProps) {
  const classes = useAdclientStyles();
  const [isOpen, setisOpen] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const [isOpen3, setisOpen3] = useState(false);
  const [statedata, setstatedata] = useState<any>([0]);
  const [AllState, setAllState] = useState<any>([]);
  const [Alldistricts, setAlldistricts] = useState<any>([]);

  const [Allcity, setAllcity] = useState<any>([]);
  const [districtdata, setdistrictdata] = useState<any>([0]);
  const [citydata, setcitydata] = useState<any>([0]);
  const [clientName, setClientName] = useState<string>("");
  const [gstNo, setGstNo] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState("");
  const params = useParams();
  const [initialValues, setInitialValues] = useState({
    client_name: "",
    gst_number: "",
    state: "",
    district: "",
    city: "",
    phoneNumber: "",
  })

  const validationSchema = Yup.object({
    client_name: Yup.string().trim().required("Client name is required"),
    gst_number: Yup.string().matches(gstRegex,"GST number is invalid"),
    state: Yup.string().required("State is required"),
    district: Yup.string().required("District is required"),
    city: Yup.string().required("City is required"),
    phoneNumber: Yup.string().trim().matches(phoneRegex,"Mobile number is invalid").required("Mobile number is required"),
  })

  const navigate = useNavigate();
  const getAllLists = async () => {
    const responseJson = await AppService.getTotalStates();
    setAllState(responseJson.data);
  };

  const getAllDisData = async () => {
    const responseJson = await AppService.getAllDistricByState("ids=1,2,3,4");

    setAlldistricts(responseJson.data.results);
  };
  const getAllCityData = async () => {
    const responseJson = await AppService.getAllCityByDis();

    setAllcity(responseJson.data.results);
  };
  const handleChangestate = (e: any) => {
    const { value } = e.target;
    const int = value.map(Number);
    setstatedata([...int]);
  };
  const handleChangedistrict = (e: any) => {
    const { value } = e.target;
    const int = value.map(Number);
    setdistrictdata([...int]);
  };
  const handleChangecity = (e: any) => {
    const { value } = e.target;
    const int = value.map(Number);
    setcitydata([...int]);
  };

  const getData = async () => {
    try{
      const response = await AppService.getSingleAdsClient(params.id);
      console.log("response", response.data)
      setInitialValues({...response.data, phoneNumber: response.data.phoneNumber.replace("+91","")})
      setstatedata([response.data.state])
      setdistrictdata([response.data.district])
    } catch (err) {
      console.log("ERROR ====> ",err)
    }
  }

  const handleSubmit = async (values:any) => {
    if(type === "edit"){
      let body = {
        ...values
      }
      const response = await AppService.updateAdsClient(params.id,body);
      if (response.data) {
        Alert("Ad client updated successfully")
        navigate("/advertisement?active=0");
      }
    } else {
      let body = {
        ...values
      }
      const response = await AppService.createAdsClient(body);
      if (response.data) {
        Alert("Ad client created successfully")
        navigate("/advertisement?active=0");
      }
    }
  }
  const createClient = async () => {
    const data = {
      client_name: clientName,
      gst_number: gstNo,
      state: statedata[0],
      district: districtdata[0],
      city: citydata[0],
      phoneNumber: mobileNumber,
    };

    const res = await AppService.createAdsClient(data);
    if (res.data) {
      navigate("/advertisement?active=0");
    }
  };
  useEffect(() => {
    getAllLists();
    getAllCityData();
    getAllDisData();
    if(type){
      getData()
    }
  }, []);
  const filterDistrict = Alldistricts.find(
    (item: any) => item.state === statedata[0]
  );

  const filterCity = Allcity.find(
    (item: any) => item.district === districtdata[0]
  );
  return (
    <DashboardLayout>
      <div className={classes.root}>
        <SectionHeader />
        <div className="container">
          <div className="flex items-center gap-[24px]">
            <button
              className="text-[#84818A] text-[24px]"
              onClick={() => {
                navigate("/advertisement??active=0");
              }}
            >
              <BiChevronLeft />
            </button>
            <div className="commonTitle">Create New Client</div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {
              ({values,handleChange,handleSubmit,setFieldValue})=>(
          <div className="mt-[54px]">
            <div className="w-[50%]">
              <div className="w-full">
                <div className="flex gap-[32px] mb-[34px]">
                  <div>
                    <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                      Client Name
                    </label>
                    <input
                      type="text"
                      disabled={type === "view"}
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      value={values.client_name}
                      name="client_name"
                      onChange={handleChange}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="client_name"/>
                    </div>
                  </div>
                  <div>
                    <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      disabled={type === "view"}
                      value={values.phoneNumber}
                      maxLength={10}
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      name="phoneNumber"
                      onChange={handleChange}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="phoneNumber"/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-[32px] mb-[34px]">
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Select State
                    </label>
                    <Select
                      open={isOpen}
                      disabled={type === "view"}
                      onClose={() => setisOpen(false)}
                      onOpen={() => setisOpen(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.state}
                      name="state"
                      fullWidth={true}
                      onChange={(e) => {
                        handleChange(e)
                        setstatedata([parseInt(e.target.value)])
                        setFieldValue("district","")
                        setFieldValue("city","")

                      }}
                    >
                      {AllState.map((items: any) => {
                        return (
                          <MenuItem key={items.id} value={items.id}>
                            <ListItemText primary={items.state} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="state"/>
                    </div>
                  </div>
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Select District
                    </label>
                    <Select
                      open={isOpen2}
                      disabled={type === "view"}
                      onClose={() => setisOpen2(false)}
                      onOpen={() => setisOpen2(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.district}
                      name="district"
                      fullWidth={true}
                      onChange={(e) =>{
                        handleChange(e)
                        setdistrictdata([parseInt(e.target.value)])
                        setFieldValue("city","")
                      }
                      }
                    >
                      {filterDistrict?.district?.map((items: any) => {
                        return (
                          <MenuItem key={items.id} value={items.id}>
                            <ListItemText primary={items.district} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="district"/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-[32px] mb-[41px]">
                  <div>
                    <label className="text-[14px] block font-[Manrope] font-[500] text-[#84818A]">
                      Select CIty
                    </label>
                    <Select
                      open={isOpen3}
                      disabled={type === "view"}
                      onClose={() => setisOpen3(false)}
                      onOpen={() => setisOpen3(true)}
                      multiple={false}
                      variant="standard"
                      sx={{
                        height: 20,
                        width: 288,
                        borderBottomColor: "#EBEAED",
                      }}
                      value={values.city}
                      name="city"
                      fullWidth={true}
                      onChange={(e) => {
                        handleChange(e)
                        setcitydata([parseInt(e.target.value)])
                      }}
                    >
                      {filterCity?.city?.map((items: any) => {
                        return (
                          <MenuItem key={items?.id} value={items?.id}>
                            <ListItemText primary={items?.city} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="text-red-600">
                      <ErrorMessage name="city"/>
                    </div>
                  </div>
                  <div>
                    <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                      GST Number
                    </label>
                    <input
                      type="text"
                      disabled={type === "view"}
                      className="border-b block border-[#EBEAED] w-[288px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                      value={values.gst_number}
                      maxLength={15}
                      name="gst_number"
                      onChange={handleChange}
                    />
                    <div className="text-red-600">
                      <ErrorMessage name="gst_number"/>
                    </div>
                  </div>
                </div>
                <div className="flex gap-[12px]">
                  <button
                    className="h-[36px] border rounded bg-[#FFFFFF] px-[16px] py-[8px] capitalize text-[14px] flex justify-center items-center font-[Manrope] font-[600] text-[#2E2C34]"
                    onClick={() => {
                      navigate("/advertisement??active=0");
                    }}
                  >
                    cancel
                  </button>
                  {
                    type !== "view" &&
                  <button
                    className="h-[36px] border rounded bg-[#4E2FA9] px-[16px] py-[8px] capitalize text-[14px] flex justify-center items-center font-[Manrope] font-[600] text-[#fff]"
                    onClick={(e)=>handleSubmit()}
                  >
                    {
                      type === "edit" ?
                      "Create Ad" :
                      "Update Ad"
                    }
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>
              )
            }
          </Formik>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CreateClientAd;
