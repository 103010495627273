import React, { useEffect, useState } from "react";
import { GridOptionButton } from "@/components/atoms/Button";
import { useAgentStyles } from "@/static/stylesheets/molecules";
import { AppService } from "../../../service/AllApiData.service";
import {
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Switch,
  Tooltip,
  Pagination
} from "@mui/material";
import { AddButton } from "@/components/atoms/Button";
import { SearchField } from "@/components/atoms/SearchField";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { Alert, AlertError } from "@/alert/Alert";
import complete from "@/static/icons/complete.svg";
import icPhoneHandle from "@/static/icons/phoneHandle.svg";
import fill from "@/static/icons/fill.svg";
import calendar from "@/static/icons/calendar.svg";
import deleteagent from "@/static/icons/delete-agent.svg";
import { useNavigate, useParams } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import Loading from "@/components/LoadingCom/Loading";
import contact from "@/static/icons/contact-phone.svg";


const ElectronicBazaarAgents = (props: any) => {
  const classes = useAgentStyles();
  const navigate = useNavigate()
  const { id } = useParams()
  const [addRejectModalOpen, setAddRejectModalOpen] = useState(false);
  const [addCommisionModalOpen, setAddCommitionModalOpen] = useState(false);
  const [getAllAgent, setGetAllAgent] = useState<any>([]);
  const [filterallAgent, setfilterallAgent] = useState<any>([]);
  const [filterAllAgentM, setfilterAllAgentM] = useState<any>([]);
  const [totalCount, SetTotalCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editFormData, setEditFormData] = useState<any>({});
  const [manageCommissionData, setManageCommissionData] = useState<any>({});
  const [allDis, setAllDis] = useState<any>([]);
  const [AllState, setAllState] = useState<any>([]);
  const [AllCity, setAllCity] = useState<any>([]);
  const [AllBazaarData, setAllBazaarData] = useState<any>([]);
  const [bazaarList, setBazaarList] = useState<any>([])
  const [stateList, setstateList] = useState<any>([])
  const [disList, setdisList] = useState<any>([])
  const [cityList, setcityList] = useState<any>([])
const [Wholesellerlist, setWholesellerlist] = useState<any>([]);
  const [AllAgentType, setAllAgentType] = useState<any>([]);
  const [agentTypeList, setagentTypeList] = useState<any>([]);
  const [AllActiveInactive, setAllActiveInactive] = useState<any>([]);
  const [activeInactiveList, setactiveInactiveList] = useState<any>([
      {
          label:"ENABLE",
          value:1
      },
      {
          label:"DISABLE",
          value:0
      }
  ]);
  const [Allstatus, setAllstatus] = useState<any>([]);
  const [statusList, setstatusList] = useState<any>([
      {
          label:"CREATED",
          value:"CREATED"
      },
      {
          label:"PENDING",
          value:"PENDING"
      },
      {
          label:"KYCREJECTED",
          value:"KYCREJECTED"
      },
      {
          label:"KYCAPPROVED",
          value:"KYCAPPROVED"
      }
  ]);
  const [searchK, setSearchK] = useState("");
  const [isshowmore, setisshowmore] = useState(false);
  const [AgentImage, setAgentImage] = useState<any>("")
  const [loading, setloading] = useState(true)
  const [agentId, setAgentId] = useState(0)
  const [filterData, setFilterData] = useState<any>({
      city_id: [],
      state_id: [],
      district_id: [],
      agent_type: [],
      agent_status:[],
      agent_active: []
  })
  const [rejectReason, setRejectReason] = useState<any>("");

  console.log("AllBazaarData", AllBazaarData)


  const getAgents = (loader=true) => {
      const bazaarIds = bazaarList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const stateIds = stateList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const districtIds = disList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const cityIds = cityList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const statusIds = statusList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const activeIds = activeInactiveList.filter((item:any)=>item.status === true).map((item:any)=>item.value);

      let params = {
          page: currentPage
      } as any
      if(bazaarIds.length){
          params = {
              ...params,
              agent_bazaar:bazaarIds.join(",")
          }
      }

      if(stateIds.length){
          params = {
              ...params,
              agent_state:stateIds.join(",")
          }
      }

      if(districtIds.length){
          params = {
              ...params,
              agent_district:districtIds.join(",")
          }
      }

      if(cityIds.length){
          params = {
              ...params,
              agent_city:cityIds.join(",")
          }
      }

      if(statusIds.length){
          params = {
              ...params,
              agent_status:statusIds.join(",")
          }
      }

      if(activeIds.length == 1){
          params = {
              ...params,
              agent_active:activeIds.join(",")
          }
      }

      if(searchK){
          params = {
              ...params,
              search: searchK
          }
      }

      getAllLists(params,loader);

  }

  useEffect(()=>{
      getAgents(false)
  },[searchK])


  useEffect(()=>{ 
      setCurrentPage(1);

      const bazaarIds = bazaarList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const stateIds = stateList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const districtIds = disList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const cityIds = cityList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const statusIds = statusList.filter((item:any)=>item.status === true).map((item:any)=>item.value);
      const activeIds = activeInactiveList.filter((item:any)=>item.status === true).map((item:any)=>item.label);

      if(bazaarIds.length || stateIds.length || districtIds.length || cityIds.length || statusIds.length || activeIds.length){
          getAgents()
      }
  },[bazaarList,stateList,disList,cityList,statusList,activeInactiveList])


  const handlePageChange = (event: any, value: any) => {

      setCurrentPage(value);
  };

  useEffect(()=>{
      getAgents();
  },[currentPage])

  const getAllLists = async (params:any,loader=true) => {
      if(loader){
          setloading(true)
      }

      params = {
        ...params,
        bazaar_id: id
      }

      try{
          const responseJson = await AppService.getAgentByfilter(params);
          if (responseJson.data.results) {
              setloading(false)
          }
          setGetAllAgent(responseJson.data.results);
          SetTotalCount(responseJson.data.count)
          console.log("all agents list===>", responseJson);
      } catch (err:any) {

      }
  };

  const [selectedTab, setSelectedTab] = useState<
      "PERPLAN" | "PERCUSTOMER"
  >("PERCUSTOMER");

  console.log("selectedTab", selectedTab);




  // useEffect(() => {
  //     setTimeout(() => {
  //         getAllLists(1);
  //     }, 10);
  // }, []);

  const manageCommissionDataChange = (e: any, valueType: string) => {
      let tempobj = {
          ...manageCommissionData,
          [e.target.name]: e.target.value
      };
      tempobj.agent_commission_value_type = valueType
      setManageCommissionData(tempobj);
  }

  const handleEdit = (index: number) => {
      console.log(getAllAgent[index], "getAllAgent[index]getAllAgent[index]")
      setEditFormData({ ...getAllAgent[index], index });
      setisshowmore(true)
  }

  const handleDeleteAgent = async (index: number) => {
      let deleteuserid = getAllAgent[index].id;
      const responseJson = await AppService.deleteAgent(deleteuserid);

      if (responseJson.status == 204) {
          Alert('Agent Deleted Successfully');
          // let tempArr = getAllAgent;
          // const indexid = tempArr.indexOf(index);
          // if (indexid > -1) { 
          //     tempArr.splice(indexid, 1);
          // }
          // setGetAllAgent([...tempArr]);
          // getAllLists(currentPage);
          getAgents();
      }
      console.log("all agents list===>", responseJson);

  }

  const handleKycApproveAgent = async (index: number, agent_status: string) => {
      if (agent_status == 'KYCREJECTED') {
          setAddRejectModalOpen(true)
          setisshowmore(false)
          setAgentId(index)
      } else {
          let textmsg = ""
          if (agent_status == 'KYCAPPROVED') {
              textmsg = "Do You want to approve agent KYC ?";
          }
          if (agent_status == 'KYCREJECTEDWITHREASON') {
              textmsg = "Do You want to reject agent KYC ?";
          }
          if (window.confirm(textmsg)) {
              let agentData = getAllAgent[index];
             
              let body = {
                  agent_status: agent_status === "KYCREJECTEDWITHREASON" ? "KYCREJECTED" : agent_status,
                  reject_reason: rejectReason
              }
              const responseJson = await AppService.kycApproveAgent(agentData.id, body);

              if (responseJson.status == 200) {
                  setisshowmore(false)
                  if (agent_status == 'KYCAPPROVED') {
                      Alert('Agent KYC APPROVED Successfully');
                  }
                  if (agent_status == 'KYCREJECTEDWITHREASON') {
                      Alert('Agent KYC REJECTED Successfully');
                  }
                  // getAllLists(currentPage);
                  getAgents()
                  setAddRejectModalOpen(false)
              }
              console.log("all agents list===>", responseJson);
          }

      }

  }
  const handleAgentStatus = async (e: any, index: number, item: any) => {
      let textmsg = ""
      if (e.target.checked) {
          textmsg = "Do you want to active Agent ?";
      } else {
          textmsg = "Do you want to Inactive Agent ?";
      }

      if(!window.confirm(textmsg)){
          return;
      }

      let body = {
          agent_active : e.target.checked
      }

      const responseJson = await AppService.kycApproveAgent(item.id, body);
      console.log(item, responseJson, "item")
      if (responseJson.status == 200) {
          if (e.target.checked) {
              Alert('Agent Inactive Successfully');
          }
          else {
              Alert('Agent Active Successfully');
          }

          // let tempArr = getAllAgent;
          // const indexid = tempArr.indexOf(index);
          // if (indexid > -1) { 
          //     tempArr.splice(indexid, 1);
          // }
          // setGetAllAgent([...tempArr]);
      }
      // getAllLists(currentPage);
      getAgents();
      console.log("all agents list===>", responseJson);

  }

  const handleAddCommissionModal = async (index: number) => {
      setEditFormData({ ...getAllAgent[index], index });
      let obj = {
          agent_commission_value_type: getAllAgent[index].agent_commission_value_type,
          agent_commission_type: getAllAgent[index].agent_commission_type,
          agent_commission_value: getAllAgent[index].agent_commission_value
      }
      setManageCommissionData(obj);
      setSelectedTab(getAllAgent[index].agent_commission_type);
      setAddCommitionModalOpen(true)
  }

  const handleAddCommissionSubmit = async () => {

      if(!manageCommissionData.agent_commission_value || isNaN(Number(manageCommissionData.agent_commission_value))){
         alert("Please enter Valid Commmission");
         return;
      }
      

      let data = manageCommissionData;
      data.agent_commission_type = selectedTab;
      let agentData = getAllAgent[editFormData.index];
      // delete agentData.agent_commission_value;

      agentData = { ...agentData, ...data };
      console.log("agentData.agent_pancard_image", agentData.agent_pancard_image);

      let body = {
          agent_commission_type: selectedTab,
          agent_commission_value: agentData.agent_commission_value,
          agent_commission_value_type: selectedTab === "PERCUSTOMER" ? "AMOUNT" : "PERCENTAGE"
      }

      if (!agentData.agent_commission_value.includes("-")) {
          try {
              // agentData.agency = agentData?.agency?.id
              console.log(agentData, "agentDataagentData")
              const responseJson = await AppService.kycApproveAgent(agentData.id, body);
              if (responseJson.status == 200) {
                  setAddCommitionModalOpen(false);
                  Alert('Commission updated Successfully');
                  // getAllLists(currentPage);
                  getAgents();
              }
          } catch (error: any) {
              setAddCommitionModalOpen(false);
              console.log("error", error);
              let message = error.response.data.type + "\n"
              error.response.data.errors.map((row: any) => {
                  message += row.attr + " : " + row.detail + "\n"
              })
              AlertError(message);
          }
      }
      else {
          AlertError('enter a positive value')
          setAddCommitionModalOpen(false);

      }
  }
  useEffect(() => {
      getAllAgentTypes()
      getAllAgentTypeData()
      getAllActiveInactive()
      getAllActiveInactiveData()
  }, [])
  useEffect(() => {
      // filterallAgent?.map((item: any) => {
      //     getAllDis({ ids: item?.district }, "")
      //     getAllState({ ids: item?.state }, "")
      //     getAllCity({ ids: item?.city }, "")
      // });
      // getAllBazaar()
      // getAllStateData()
      getAllStateData()
      // getAllDisData()
      // getAllCityData()
      getAllState()
      getAllAgentTypes()
      getAllAgentTypeData()
      // getAllActiveInactive()
      // getAllActiveInactiveData()
      // getAllStatus()
      // getAllStatusData()
  }, [])

  useEffect(() => {
      // getAllBazaarData()
      getAllStateData()
  }, [])

  const getAllDis = async (param: any, type: any) => {
      const responseJson = await AppService.getTotalDistrict(param)
      setAllDis(responseJson.data);
  };
  const getAllState = async (param: any = {}, type: any="") => {
      const responseJson = await AppService.getTotalCity(param)
      setAllState(responseJson.data);
  };
  const getAllCity = async (param: any, type: any) => {
      const responseJson = await AppService.getAllCity(param)
      setAllCity(responseJson.data.results);
  };
  const getAllBazaarData = async () => {
      const responseJson = await AppService.getAllBazaar()
      setAllBazaarData(responseJson.data.results);
  };
  const getAllBazaar = async () => {
      const responseJson = await AppService.getAllBazaar();
      let tempBazaar = await responseJson.data.results.map((row: any) => {
          return {
              label: row.bazaar_name,
              value: row.id,
          }
      })
      setBazaarList(tempBazaar);
  };


  const getAllStateData = async (params: any={}) => {
      setstateList([]);
      setdisList([]);
      setcityList([]);
      try {
        const responseJson = await AppService.getAllStates(params);
  
        // const tempState = responseJson.data.results.flatMap((row: any) =>
        //   row.bazaar_state.map((id: any) => {
        //     const stateItem = AllState.find((item: any) => item.id === id);
        //     if (stateItem) {
        //       return {
        //         label: stateItem.state,
        //         value: id,
        //       };
        //     }
        //     return null; // Handle cases where a matching state is not found
        //   })
        // ).filter((item:any)=>item !== null);

        const tempState = responseJson.data.results.map((item:any, index:number)=>{
          return {
            label: item.state,
            value: item.id,
          };
        })
  
        console.log("logger123...", AllState, responseJson.data, tempState);
        setstateList(tempState);
      } catch (error) {
        // Handle errors here
        console.error(error);
      }
    };


    const getAllDisData = async (params: any) => {
      setdisList([]);
      setcityList([]);
      const responseJson = await AppService.getAllDistricByState(params);
      const tempState = responseJson.data.results.flatMap((row: any) =>
        row.district.map((item: any) => {
          return {
            label: item.district,
            value: item.id,
          };
        })
      ).filter((item:any)=>item !== null);
  
      console.log("logger123...", allDis, responseJson.data, tempState);
      setdisList(tempState);
    };

    const getAllCityData = async (params: any) => {
      const responseJson = await AppService.getAllCityByDis(params);
      const tempState = responseJson.data.results.flatMap((row: any) =>
        row.city.map((item: any) => {
          return {
            label: item.city,
            value: item.id,
          };
        })
      ).filter((item:any)=>item !== null);
      setcityList(tempState);
    };


  const getAllAgentTypes = async () => {
      const responseJson = await AppService.getAllAgents({ page: currentPage});
      const arr = responseJson?.data?.results?.map((item: any) => item.agent_type);
      var uniqueArray = Array.from(new Set(arr));
      setAllAgentType(uniqueArray);
  }
  const getAllAgentTypeData = async () => {
      if (AllAgentType.length > 0) {
          let tempState = AllAgentType?.map((row: any) => {
              return {
                  label: row,
                  value: row,
              }
          })
          setagentTypeList(tempState);
      }
  };
  const getAllActiveInactive = async () => {
      const responseJson = await AppService.getAllAgents({ page: currentPage});
      const arr = responseJson.data.results?.map((item: any) => {
          if (item?.agent_active === true) {
              return true
          } else {
              return false
          }
      });
      var uniqueArray = Array.from(new Set(arr));
      setAllActiveInactive(uniqueArray);
  }
  const getAllActiveInactiveData = async () => {
      if (AllActiveInactive.length > 0) {
          let tempState = AllActiveInactive.map((row: any) => {
              return {
                  label: row === true ? "Enable" : "Disable",
                  value: row === true ? true : false,
              }
          })
          setactiveInactiveList(tempState);
      }
  };
  const getAllStatus = async () => {
      const responseJson = await AppService.getAllAgents({ page: currentPage});
      const arr = responseJson.data.results?.map((item: any) => item?.agent_status);
      var uniqueArray = Array.from(new Set(arr));
      console.log("logger123...",uniqueArray,arr)
      getAllStatusData(uniqueArray);
  }
  const getAllStatusData = async (uniqueArray:any[]) => {
      if (uniqueArray?.length > 0) {
          let tempState = uniqueArray?.map((row: any) => {
              return {
                  label: row,
                  value: row,
              }
          })
          setstatusList(tempState);
      }
  };

  const handleChange = (selectboxName: string, id: any) => {  
      setCurrentPage(1);
      if (selectboxName === 'State') {
        const updatedData = stateList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
        const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
        console.log("logger123...", updatedData, id, AllState)
        getAllDisData({ ids });
        setstateList(updatedData);
      }
      if (selectboxName === 'District') {
  
        const updatedData = disList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
        const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
        getAllCityData({ ids });
  
        setdisList(updatedData);
      }
      if (selectboxName === 'City') {
        setcityList((prev: any) => {
          return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
        });
      }
      if (selectboxName === 'Wholeseller Type') {
        setWholesellerlist((prev: any) => {
          return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
        });
      }
      if (selectboxName === 'Agent Type') {
        setagentTypeList((prev: any) => {
          return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
        });
      }
      if (selectboxName === 'Status') {
        setstatusList((prev: any) => {
          return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
        });
      }
      if (selectboxName === 'Bazaar') {
        const updatedData = bazaarList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
        const ids = updatedData.filter((item: any) => item.status === true).map((item: any) => item.value).join(",");
        getAllStateData({ ids });
        setBazaarList(updatedData);
      }
      if (selectboxName === 'Active/Inactive') {
        setactiveInactiveList((prev: any) => {
          return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
        });
      }
    }


  // const filterdata = async (param: any) => {
  //     const data = await AppService.getAgentByfilter(param)
  //     if (data?.status === 200) {
  //         console.log('data',data?.data?.results)
  //         setGetAllAgent(data?.data?.results)
  //     }
  // }

  // useEffect(() => {
  //     if (filterData) {
  //         filterdata(filterData)
  //     }
  // }, [filterData])

  useEffect(() => {
      const parmsData: any = {};
      let temp1 = filterAllAgentM;
      // bazaar data
      if (filterAllAgentM?.length && AllBazaarData?.length) {
          let FBazaardata = AllBazaarData?.filter((b: any) => b.status === true)?.map((item: any) => item?.id)
          if (FBazaardata?.length > 0) {
              for (const x of FBazaardata) {
                  temp1 = temp1?.filter((fp: any) => fp?.agent_bazaar?.includes(x))
              }
          }
      }
      // city
      if (temp1?.length && AllCity?.length) {
          let FCity = AllCity?.filter((b: any) => b.status === true)?.map((item: any) => item?.id)
          if (FCity?.length > 0) {
              // parmsData['city_id'] = Object.values(FCity)
              setFilterData((prev:any) => ({...prev, city_id: FCity[0]}))
              for (const x of FCity) {
                  temp1 = temp1?.filter((fc: any) => fc?.agent_assigned_city?.includes(x))
              }
          }
      }
      // state
      if (temp1?.length && AllState?.length) {
          let FState = AllState?.filter((b: any) => b.status === true)?.map((item: any) => item?.id)
          if (FState?.length > 0) {
              // parmsData['state_id'] = Object.values(FState)
              setFilterData((prev:any) => ({...prev, state_id: FState[0]}))
              for (const x of FState) {
                  temp1 = temp1?.filter((fs: any) => fs?.agent_assigned_state?.includes(x))
              }
          }
      }
      // district
      if (temp1?.length && allDis?.length) {
          let FDistrict = allDis?.filter((b: any) => b.status === true)?.map((item: any) => item?.id)
          if (FDistrict?.length > 0) {
              // parmsData['district_id'] = Object.values(FDistrict)
              setFilterData((prev:any) => ({...prev, district_id: FDistrict[0]}))
              for (const x of FDistrict) {
                  temp1 = temp1?.filter((fs: any) => fs?.agent_assigned_district?.includes(x))
              }
          }
      }
      // agent type
      if (temp1?.length && AllAgentType?.length) {
          const selectedAgentType = agentTypeList?.filter((item: any) => item.status === true).map((item: any) => item.value);
          if (selectedAgentType?.length > 0) {
              // parmsData['agent_type'] = Object.values(selectedAgentType)
              setFilterData((prev:any) => ({...prev, agent_type: selectedAgentType}))
              temp1 = temp1?.filter((item: any) => selectedAgentType?.includes(item?.agent_type));
          }
      }
      // agent status
      if (temp1?.length && statusList?.length) {
          const selectedStatus = statusList?.filter((item: any) => item.status === true).map((item: any) => item.value);
          if (selectedStatus?.length > 0) {
              // parmsData['agent_status'] = Object.values(selectedStatus)
              setFilterData((prev:any) => ({...prev, agent_status: selectedStatus[0]}))
              temp1 = temp1?.filter((item: any) => selectedStatus?.includes(item?.agent_status));
          }
      }
      // active / inactive
      if (temp1?.length && AllActiveInactive?.length) {
          const selectedActiveInactive = activeInactiveList?.filter((item: any) => item.status === true).map((item: any) => item.value);
          if (selectedActiveInactive?.length > 0) {
              // parmsData['agent_active'] = Object.values(selectedActiveInactive)
              setFilterData((prev:any) => ({...prev, agent_active: selectedActiveInactive[0]}))
              temp1 = temp1?.filter((item: any) => selectedActiveInactive?.includes(item?.agent_active));
          }
      }
  }, [filterAllAgentM, AllBazaarData, AllCity, AllState, allDis, activeInactiveList, statusList, agentTypeList])

  return (
    <>
      {
                    loading ?
                        <Loading /> :
                        <div className={classes.root}>
                            <div>
                                <div className="flex align-middle justify-between items-center agent-wrapper" style={{ flexWrap: "wrap" }}>
                                    <div className="flex gap-[30px] items-center" style={{ flexWrap: "wrap" }}>
                                        {/* <p className="commonTitle headTitle">Agents</p> */}
                                        {/* <div className="commonTitle flex gap-2 cursor-pointer" style={{ alignItems: "center" }}>
                                            <BsFileEarmarkPlus className="w-10px text-[#FF6652]" style={{ color: "#333" }} />
                                            <p
                                                className="paymentTitle"
                                                onClick={() => navigate("/paymentrequest")}
                                            >
                                                {totalCount || 0} Payment Requests
                                            </p>
                                        </div> */}
                                    </div>

                                    <div className="flex gap-2 justify-end search-div">
                                        <div className="relative" style={{ width: "576px", marginRight: "10px" }}>
                                            <input
                                            value={searchK}
                                                type="text"
                                                onChange={(e) => setSearchK(e.target.value)}
                                                placeholder="Search by City , Name, Mobile Number, Type etc"
                                                className="w-full py-[11px] pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white"
                                                style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Manrope", color: "#84818A", paddingLeft: "16px" }}
                                                // onKeyDown={(e:any)=>e.key === 'Enter' && }
                                            />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                style={{ right: "16px", color: "#504F54", width: "17.49px", height: "17.49px" }}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-5 pt-[30px] " style={{ flexWrap: "wrap" }}>
                                {/* <CommonSelectElectronicBazaar
                                    label={"Bazaar"}
                                    hint={"Select Bazaar"}
                                    options={bazaarList}
                                    handleSelect={handleChange}
                                    selectedIds={bazaarList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
                                // selectedIds={AllBazaarData}
                                /> */}
                                <CommonSelectElectronicBazaar
                                    label={"State"}
                                    hint={"Select State"}
                                    options={stateList}
                                    handleSelect={handleChange}
                                    selectedIds={stateList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
                                />
                                <CommonSelectElectronicBazaar
                                    label={"District"}
                                    hint={"Select District"}
                                    options={disList}
                                    handleSelect={handleChange}
                                    selectedIds={disList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
                                />
                                <CommonSelectElectronicBazaar
                                    label={"City"}
                                    hint={"Select City"}
                                    options={cityList}
                                    handleSelect={handleChange}
                                    selectedIds={cityList.filter((item: any) => item?.status).map((elm: any) => elm.value)}
                                />
                                {/* <CommonSelectElectronicBazaar
                                    label={"Agent Type"}
                                    hint={"Select Agent Type"}
                                    options={agentTypeList}
                                    handleSelect={handleChange}
                                    selectedIds={agentTypeList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                                /> */}
                                <CommonSelectElectronicBazaar
                                    label={"Status"}
                                    hint={"Select Status"}
                                    options={statusList}
                                    handleSelect={handleChange}
                                    selectedIds={statusList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                                />
                                <CommonSelectElectronicBazaar
                                    label={"Active/Inactive"}
                                    hint={"Select Active/Inactive"}
                                    options={activeInactiveList}
                                    handleSelect={handleChange}
                                    selectedIds={activeInactiveList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                                />
                            </div>

                            <div>
                                <div className="relative pt-[40px] pb-[125px]" style={{ overflowX: "auto" }}>
                                    <table className="w-full text-left">
                                        <thead className="">
                                            <tr className="color-[#2E2C34;]" style={{ borderBottom: "1px solid #EBEAED" }}>
                                                <th scope="col" className="tableTitle py-3 px-6" style={{ paddingLeft: 0 }}>
                                                    Name
                                                </th>
                                                <th scope="col" className="tableTitle py-3 px-6">
                                                    Mobile Number
                                                </th>
                                                <th scope="col" className="tableTitle py-3 px-6">
                                                    City
                                                </th>
                                                <th scope="col" className="tableTitle py-3 px-6">
                                                    Type
                                                </th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getAllAgent && getAllAgent?.map((item: any, index: any) => (
                                                <tr className="border-b" key={index}>
                                                    <td
                                                        scope="row"
                                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                        style={{ paddingLeft: 0 }}
                                                    >
                                                        {
                                                            item.agent_status == 'KYCAPPROVED' ?
                                                                <div className="flex items-center gap-2 text-[#4E2FA9] cursor-pointer" onClick={() => navigate(`/agentdetails/${item.id}`)}>
                                                                    <img className="brandLogo" src={contact} alt={"Logo"} />
                                                                    <span style={{ textDecoration: "underline" }}>{item.agent_name}</span>
                                                                </div> :
                                                                <div className="flex gap-[20px] items-center ">
                                                                    <p className="mb-3 tableContentTitle" style={{ marginBottom: 0 }}>
                                                                        <div className="flex gap-5 items-center">
                                                                            <p
                                                                                className="tableData"
                                                                                style={{ marginBottom: 0 }}
                                                                            >
                                                                                {item.agent_name}
                                                                            </p>
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                        }
                                                    </td>
                                                    <td className="py-4 px-6 tableData">
                                                        <div className="flex gap-2">
                                                            <img src={icPhoneHandle} alt="dots" />
                                                            {item.agent_number}
                                                        </div>
                                                    </td>
                                                    <td className="py-4 px-6 tableData">
                                                        {item.agent_city_name || "NA"}
                                                    </td>
                                                    
                                                    <td className="py-4 px-6 tableData" style={{ textTransform: "capitalize" }}>{item.agent_type}</td>
                                                    <td className="py-4 px-6 tableData">
                                                        {item.agent_status == 'CREATED' && <div className="bg-[#e5f5ff] flex justify-center rounded-md p-[10px] w-[150px]">
                                                            <p className="text-[#28a1ff]" style={{ fontSize: "12px", lineHeight: "18px" }}>{item.agent_status}</p>
                                                        </div>}
                                                        {item.agent_status == 'PENDING' && <div className="bg-[#FFF6ED] cursor-pointer flex justify-center rounded-md p-[10px] w-[150px]" onClick={() => handleEdit(index)}>
                                                            <p className="text-[#FFA043]" style={{ fontSize: "12px", lineHeight: "18px" }}>{item.agent_status}</p>
                                                        </div>}
                                                        {item.agent_status == 'KYCAPPROVED' && <div className="bg-[#e6fcf7] flex justify-center rounded-md p-[10px] w-[150px]">
                                                            <p className="text-[#00e0c0]" style={{ fontSize: "12px", lineHeight: "18px" }}>{item.agent_status}</p>
                                                        </div>}
                                                        {item.agent_status == 'KYCREJECTED' && <div className="bg-[#ffeae5] flex justify-center rounded-md p-[10px] w-[150px]">
                                                            <p className="text-[#ff0000]" style={{ fontSize: "12px", lineHeight: "18px" }}>{item.agent_status}</p>
                                                        </div>}
                                                        {item.agent_status == 'APPROVED' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]">
                                                            <p className="text-[#FFA043]" style={{ fontSize: "12px", lineHeight: "18px" }}>{item.agent_status}</p>
                                                        </div>}
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div
                                        className="flex items-center justify-center mt-5"
                                        style={{ display: "flex", marginLeft: 0 }}
                                    >
                                        <Pagination
                                            count={Math.ceil(totalCount / 10)}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                }
    </>
  );
};

export { ElectronicBazaarAgents };
