import React, { useEffect, useRef, useState } from "react";
import { useGroupCategoriesStyles } from "@/static/stylesheets/screens/groupcategoriesStyles";
import LogoEcBazaar from "@/static/images/placeholder.jpg";
import LogoDelete from "@/static/icons/ic_delete.png";
import LogoEdit from "@/static/icons/ic_edit.png";
import LogoAdd from "@/static/icons/ic_add.png";
import { AppService } from "@/service/AllApiData.service";
import { Alert, AlertError } from "@/alert/Alert";
import { useParams } from "react-router-dom";
import { readFileAsBase64 } from "@/helper/base64";
import SaveIcon from '@mui/icons-material/Save';
import { Button, Dialog, LinearProgress, Tooltip } from "@mui/material";
import { useBulkuploadStyles } from "@/static/stylesheets/screens";
import UploadLogo from "@/static/svg/ic_upload.svg";
import DownloadLogo from "@/static/svg/ic_download.svg";
import { UploadFile } from "@mui/icons-material";
import classNames from "classnames";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import Loading from "@/components/LoadingCom/Loading";


const GroupCategories = (props: {
  formData: { bazaar_name: any };
  setFormData: (arg0: any) => void;
  setParentCategoryList: (arg0: any) => void;
  onSavecategory: (payload: any) => void;
  setGroupCatCount: (payload: any) => void;
  branch_id?: any
}) => {
  const classes = useGroupCategoriesStyles();
  const classes2 = useBulkuploadStyles();
  const [groupCategories, SetGroupCategories] = useState(0);
  const [ListGroups, setListGroups] = useState([]);
  const [IDS, setIDS] = React.useState([]);
  const [HideTable, setHideTable] = React.useState(false);
  const [firstTableHide, setFirstTableHide] = React.useState(false);
  const { id } = useParams();
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addData, setAddData] = React.useState<any>([]);

  const uploaderRef = useRef<any>();
  const zipUploaderRef = useRef<any>();
  const [file, setFile] = useState<any>();
  const [zipFile, setZipFiles] = useState<any>();
  const detailIB = async (ID: any) => {
    const responseJson = await AppService.detailGroupCate(ID);
    setIDS(responseJson.data);
  };

  const [inputFields, setInputFields] = useState([
    {
      fullName: "",
      image: "",
    },
  ]);

  const addInputField = () => {
    let groupCategoriesCount = groupCategories;
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user') || "") : {}
    let data = addData;
    data.push(
      {
        parent_category_name: "",
        parent_category_description: "",
        parent_category_active: "true",
        parent_category_updated_by: user?.id,
        parent_category_ref_image: "",
        bazaar: id ? id : "",
      }
    )
    setAddData([...data]);
    setSelectedImage({ ...selectedImage, [groupCategoriesCount]: undefined });
    setHideTable(true);
    groupCategoriesCount = ++groupCategoriesCount;
    SetGroupCategories(groupCategoriesCount);
  };
  const removeInputFields = (index: number) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (
    index: string | number,
    evnt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    setInputFields(list);
  };

  const [selectedImage, setSelectedImage] = useState<any>({});
  const [selected, setSelected] = React.useState([]);
  const [node, setNode] = React.useState("");

  const imageChange = async (e: any, index: number) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      setSelectedImage({ ...selectedImage, [index]: e.target.files[0] });
      let url = await readFileAsBase64(e.target.files[0]);
      let data = addData;
      data[index].parent_category_ref_image = url;
      console.log("LOGGER ===> ", data, addData);
      setAddData([...data]);
    }
  };

  const dataSave = (e: any) => {
    // console.log(e.target.value);
    // props.setFormData({
    //   ...props.formData,
    //   bazaar_gorup_category: [selectedImage, e.target.value],
    // });
  };

  const handleSelectedItems = (event: any, nodeId: any) => {
    console.log(nodeId);
    setHideTable(false);
    setFirstTableHide(true);
    setNode(nodeId);
    detailIB(nodeId);
    props.setFormData({
      ...props.formData,
      bazaar_gorup_category: [nodeId],
    });
  };
  async function convertImageTobS4(imgUrl: string) {
    const imageToBase64 = require('image-to-base64/browser.js');
    let response = await imageToBase64(imgUrl);
    return "data:image/png;base64," + response;
  }

  const getAllLists = async () => {
    const responseJson = await AppService.listGroupCateByBazaar({ bazaar: id });
    console.log("getCategories", responseJson.data.results);
    setListGroups(responseJson.data.results);
    SetGroupCategories(responseJson.data.results.length)
    setAddData(responseJson.data.results)
    let tempObj: any = [];
    await Promise.all(
      responseJson.data.results.map(async (row: any, index: number) => {
        row.parent_category_ref_image = await convertImageTobS4(row.parent_category_ref_image);
        tempObj[index] = row;
      })
    );
    props.setGroupCatCount(responseJson.data.results.length);

    setAddData(tempObj);
  };

  const deletes = async (id: any) => {
    if (id) {
      Alert("delete successfully");
      setFirstTableHide(true);
      const responseJson = await AppService.deleteGroupCate(id);
      console.log(responseJson.data);
      getAllLists();
    }
  };
  const edit = async (id: any) => {
    setFirstTableHide(true);
    const responseJson = await AppService.updateGroupCate(id, addData);
    console.log(responseJson.data);
    Alert("updated successfully");
    getAllLists();
  };

  React.useEffect(() => {
    console.log("group-categories", props);
    if (id) {
      getAllLists();
    }
  }, [id]);
  React.useEffect(() => { props.setFormData(addData) }, [addData])


  const hideTabs = async (e: any, index: number) => {

    let groupCategoriesCount = groupCategories;
    groupCategoriesCount = --groupCategoriesCount;
    SetGroupCategories(groupCategoriesCount);
    if (groupCategories == 1) {
      setFirstTableHide(true);
      setHideTable(false);
      setAddData([]);
      SetGroupCategories(0);
      setSelectedImage({})
    }
    let id = addData[index]?.id;
    let data = addData.filter((item: any, i: number) => i !== index);
    // delete data[index];
    setAddData(data);
    deletes(id);
  };
  const handleChanges = (event: any, index: number) => {
    event.preventDefault();
    let data = addData;
    data[index][event.target.name] = event.target.value
    // let obj = { ...addData[index], [event.target.name]: event.target.value };

    setAddData([...data]);
    if (event.target.files && event.target.files.length > 0) {
      console.log(event.target.files[0]);
      setSelectedImage({ ...selectedImage, [index]: event.target.files[0] });
    }
  };
  console.log("addData[index]", addData);


  function hasDuplicates(array: any, propertyName: any) {
    const seen = new Set();

    for (const item of array) {
      const propertyValue = item[propertyName];

      if (seen.has(propertyValue)) {
        // Duplicate found
        return true;
      }

      seen.add(propertyValue);
    }

    // No duplicates found
    return false;
  }

  const save = async (index: number) => {

    // const isInValid = hasDuplicates(Object?.values(addData), "parent_category_name");

    if (!addData[index]?.parent_category_name?.length) {
      AlertError("Blank field not allowed")
      return;
    }

    let allValues = addData.map((item: any) => item?.parent_category_name?.trim()?.toLowerCase());
    allValues.splice(index, 1)

    console.log("LOGGER ===> ", allValues, addData[index]?.parent_category_name)

    if (allValues.includes(addData[index]?.parent_category_name?.trim()?.toLowerCase())) {
      AlertError("Duplicate Categories are not allowed")
      return;
    }
    // return;
    let payload: any = Object.assign({}, addData[index]);
    try {
      let responseJson;
      if (payload?.parent_category_ref_image != "") {
        if (addData[index].id) {
          responseJson = await AppService.updateGroupCate(payload.id, payload);
        } else {
          responseJson = await AppService.addGroupCate(payload);
        }
        if (responseJson.status == 201 || responseJson.status == 200) {
          Alert("Added successfully");
        }
      }
      else {
        AlertError("Please enter a image");
      }
      // getAllLists()
    }
    catch (error: any) {
      let message: any = [];
      error.response.data.errors.map((row: any, i: number) => {
        message.push(i + 1 + ". " + row.detail)
      })
      AlertError(message);
    }
    //  props.onSavecategory(addData[index])
  };


  const submitBulkUpload = async () => {
    
    if (file?.length && zipFile?.length) {
      setLoader(true);
      try {
        let formData = new FormData();
        formData.append("excel_file", file?.[0]);

        if (zipFile?.length) {
          formData.append("images", zipFile?.[0]);
        }

        formData.append("bazaar_id", id ? id : "");
        const response = await AppService.categoryBulkUpload(formData);
        console.log("RESPONSE ====> ", response)
        Alert(response.data.message);
        getAllLists();
        setFile(null)
        setShowBulkUpload(false)
        setLoader(false);
      } catch (error: any) {
        let message = error.response.data.type + "\n"
        error.response.data.errors.map((row: any) => {
          message += row.attr + " : " + row.detail + "\n"
        })

        AlertError(message);
        setFile(null)
        setShowBulkUpload(false)
        setLoader(false);
      }
    } else {
      AlertError("Please select both excel and zip file")
      setShowBulkUpload(false)
      // setFile(null)
    }
  }

  useEffect(() => {
    console.log("file123...", file)
  }, [file])

  useEffect(() => {
    console.log("logger123...12345", addData);
  }, [addData])


  return (
    <>
      {
        loader ?
          <Loading />
          :
          <div className={classes.root}>

            <div>
              <Dialog
                open={showBulkUpload}
                maxWidth={"lg"}
                sx={{
                  ".MuiPaper-root": {
                    borderRadius: "6px",
                  },
                }}
                onClose={() => setShowBulkUpload(false)}
              >
                <div className={`${classes2.addDialog} w-full !p-0 !px-[56px]`}>
                  <div className="flex justify-between modalHead items-center">
                    <p className="reportTitle tracking-[1px] py-[35px]">Bulk Upload</p>

                    <div onClick={() => {
                      setShowBulkUpload(false);
                    }}>
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                        <line
                          x1="24.7305"
                          y1="12.4228"
                          x2="12.4268"
                          y2="24.7265"
                          stroke="#84818A"
                          stroke-width="1.5"
                          strokeLinecap="round"
                        />
                        <line
                          x1="24.3104"
                          y1="24.7266"
                          x2="12.0068"
                          y2="12.4229"
                          stroke="#84818A"
                          stroke-width="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>

                  {/* <p>
                *Bulk Upload Guide: Matching Excel Data with Image Files*

                This guide outlines the steps to perform a bulk upload of images based on information provided in an Excel sheet. The process involves matching the data in the Excel sheet with images stored in a zip folder.

                ### Prerequisites:

                - Excel file with three columns: Group Category, Category, and Sub Category.
                - Zip folder containing images with filenames corresponding to the entries in the Excel sheet.

                ### Steps:

                1. *Download the Required Files:*
                - Download the Excel format sheet from the provided link below.
                - Ensure you have the zip folder containing the images.

                2. *Inspect Excel Sheet:*
                - Open the Excel sheet and review the three columns: Group Category, Category, and Sub Category.
                - Ensure that the data is accurate, and there are no discrepancies.

                3. *Prepare Image Folder:*
                - Make sure the images are zipped into a folder.
                - Confirm that the image filenames match the entries in the Excel.

                7. *Import:*
                - Once the script has executed successfully, you have completed the bulk upload.
              </p> */}


                  <div className={classes2.root}>
                    <div className="container mt-0  h-full overflow-y-auto">


                      <div className="p-4">
                        <p className="mb-4">
                          <span className="font-bold">Bulk Upload Guide: Matching Excel Data with Image Files</span>
                        </p>

                        <div className="mb-4">
                          <p className="mb-2">
                            This guide outlines the steps to perform a bulk upload of images based on information provided in an Excel sheet.
                            The process involves matching the data in the Excel sheet with images stored in a zip folder.
                          </p>
                        </div>

                        <div className="mb-4">
                          <h3 className="text-lg font-semibold mb-2">Prerequisites:</h3>
                          <ul className="list-disc pl-4">
                            <li>Excel file with three columns: Group Category, Category, and Sub Category.</li>
                            <li>Zip folder containing images with filenames corresponding to the entries in the Excel sheet.</li>
                          </ul>
                        </div>

                        <div className="mb-4">
                          <h3 className="text-lg font-semibold mb-2">Steps:</h3>

                          <ol className="list-decimal pl-4">
                            <li className="mb-2">
                              <span className="font-semibold">Download the Required Files:</span>
                              <ul className="list-disc pl-4">
                                <li>Download the Excel format sheet from the provided link below.</li>
                                <li>Ensure you have the zip folder containing the images.</li>
                              </ul>
                            </li>

                            <li className="mb-2">
                              <span className="font-semibold">Inspect Excel Sheet:</span>
                              <ul className="list-disc pl-4">
                                <li>Open the Excel sheet and review the three columns: Group Category, Category, and Sub Category.</li>
                                <li>Ensure that the data is accurate, and there are no discrepancies.</li>
                              </ul>
                            </li>

                            <li className="mb-2">
                              <span className="font-semibold">Prepare Image Folder:</span>
                              <ul className="list-disc pl-4">
                                <li>Make sure the images are zipped into a folder.</li>
                                <li>Confirm that the image filenames match the entries in the Excel.</li>
                              </ul>
                            </li>

                            <li>
                              <span className="font-semibold">Import:</span>
                              <ul className="list-disc pl-4">
                                <li>Once the script has executed successfully, you have completed the bulk upload.</li>
                              </ul>
                            </li>
                          </ol>
                        </div>
                      </div>
                      {/* <LinearProgress variant="buffer" value={10} /> */}
                      <div className="uploadArea cursor-pointer mt-5" onClick={() => uploaderRef?.current?.click()}>
                        <input type="file" ref={uploaderRef} className="hidden" onChange={(e: any) => setFile(e.target.files)} accept=".xlsx, .xls" />
                        <div className="placeholder">
                          <img className="w-[50px]" src={UploadLogo} alt={"Logo"} />
                        </div>
                        <div className="contentContainer">
                          {
                            file?.[0]?.name ?
                              <p className="headTitle">
                                Change Excel here
                              </p>
                              :
                              <p className="headTitle">Upload Excel here</p>
                          }
                          <p className="headDescription py-4">
                            {
                              file?.length &&
                              "Selected " + file[0]?.name
                            }
                            {/* Image can be size of 512 PX by 512 PX Only */}
                          </p>
                        </div>
                      </div>
                      <div className="gap-5 actonButton pt-[20px] pb-[40px]" onClick={() => window.open('/templates/Category-Bulk-Upload-Template.xlsx', '_blank')}>
                        <img src={DownloadLogo} alt={"Logo"} />
                        <p className="downloadTitle cursor-pointer">
                          Download Excel Template
                        </p>
                      </div>


                      <div className="uploadArea cursor-pointer" onClick={() => zipUploaderRef?.current?.click()}>
                        <input type="file" ref={zipUploaderRef} className="hidden" onChange={(e: any) => setZipFiles(e.target.files)} accept=".zip" />
                        <div className="placeholder">
                          <img className="w-[50px]" src={UploadLogo} alt={"Logo"} />
                        </div>
                        <div className="contentContainer">
                          {
                            zipFile?.[0]?.name ?
                              <p className="headTitle">
                                Change zip file here
                              </p>
                              :
                              <p className="headTitle">Upload images zip files here</p>
                          }
                          <p className="headDescription py-4">
                            {
                              zipFile?.length &&
                              "Selected " + zipFile[0]?.name
                            }
                            {/* Image can be size of 512 PX by 512 PX Only */}
                          </p>
                        </div>
                      </div>
                      <div className="gap-5 actonButton pt-[20px] pb-[40px]" onClick={() => window.open('/templates/Bulk-Upload-Category-Images-template.zip', '_blank')}>
                        <img src={DownloadLogo} alt={"Logo"} />
                        <p className="downloadTitle cursor-pointer">
                          Download Zip Template
                        </p>
                      </div>

                    </div>
                  </div>

                  <div className="flex justify-end pb-5">
                    <Button
                      onClick={() => {
                        submitBulkUpload()
                      }}
                      sx={{
                        background: '#4e2fa9',
                        color: "#ffffff",
                        textTransform: "capitalize",
                        fontFamily: "Manrope",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",

                        ".title": {
                          marginLeft: "12.5px",
                        }
                      }}
                      style={{ width: 'auto' }}
                      variant={"contained"}
                      disableElevation={true} >
                      UPLOAD
                    </Button>
                  </div>

                </div>
              </Dialog>
            </div>

            <div className="container flex flex-col " style={{ display: "flex", gap: "80px" }}>
              <div className="flex justify-end items-center">
                <Button
                  onClick={() => setShowBulkUpload(true)}
                  sx={{
                    background: '#4e2fa9',
                    color: "#ffffff",
                    textTransform: "capitalize",
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",

                    ".title": {
                      marginLeft: "12.5px",
                    }
                  }}
                  style={{ width: 'auto' }}
                  variant={"contained"}
                  disableElevation={true} >
                  <UploadFile className="mr-2" />
                  Bulk Upload
                </Button>
              </div>
              <div className="rightContainer">
                <table>
                  {ListGroups.filter((x: any) => x.bazaar == id)?.length > 0 && (
                    <tr>
                      <th className="title">Image <span className="text-red-600">*</span></th>
                      <th className="title">Group Category Name <span className="text-red-600">*</span></th>
                      <th></th>
                    </tr>
                  )}
                  <tr hidden={ListGroups?.length > 0 ? true : !HideTable}>
                    <th className="title">Image <span className="text-red-600">*</span></th>
                    <th className="title">Group Category Name <span className="text-red-600">*</span></th>
                    <th></th>
                  </tr>

                  {addData.map((row: any, index: number) => (
                    <tr >
                      <td>
                        <div className="brandLogo" style={{ position: "relative" }}>
                          {!row?.parent_category_ref_image ? (
                            <img src={LogoEcBazaar} alt={"Logo"} className={"w-[52px]"} />
                          ) : (
                            row?.parent_category_ref_image && (
                              <img src={row?.parent_category_ref_image} />
                            )
                          )}
                          <input
                            accept="image/*"
                            style={{
                              position: "absolute",
                              top: 0,
                              width: "40px",
                              height: "37px",
                              cursor: "pointer",
                              opacity: "0",
                            }}
                            type="file"
                            onChange={(e) => imageChange(e, index)}
                            name="image"
                          />
                        </div>
                      </td>
                      <td>

                        <div>
                          <input
                            type="text"
                            name="parent_category_name"
                            className="bg-gray-50 border border-gray-300 text-sm rounded-lg text-[#2E2C34] block w-full p-[15px] dark:border-[#EBEAED]"
                            placeholder="Group category"
                            value={row?.parent_category_name}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                              handleChanges(e, index)
                            }}
                            disabled={row?.is_locked}
                          />
                          {
                            (addData?.filter((item: any) => item.parent_category_name?.trim().toLowerCase() === addData[index]?.parent_category_name?.trim()?.toLowerCase())?.length > 1 && addData[index]?.parent_category_name?.length) ?
                              <p className="mb-0 text-red-600 text-xs">Duplicate Group categories are not allowed</p>
                              : ""
                          }
                        </div>
                      </td>
                      <td>
                        {
                          row?.is_locked ?
                            <div className="ActionLogo flex justify-center" style={{ width: "55px" }}>
                              <Tooltip title={"Edit is locked for this Group Category as wholesellers have added product to this group category"}>
                                <LockClosedIcon style={{ color: "#5542F6", cursor: "pointer" }} fontSize={10} />
                              </Tooltip>
                            </div>
                            :
                            <div className="ActionLogo flex justify-center">

                              <SaveIcon style={{ color: "#5542F6", cursor: "pointer" }} onClick={() => save(index)} />

                              <div className="dividor"></div>
                              <img
                                src={LogoDelete}
                                onClick={() => window.confirm("Are you sure") ? hideTabs(true, index) : ""}
                                alt={"Logo"}
                              />

                            </div>
                        }
                      </td>
                    </tr>
                  ))}


                </table>
                <div>
                  <div className="addButton" onClick={addInputField}>
                    <img src={LogoAdd} alt={"Logo"} />
                    <p>Add New Group Category</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default GroupCategories;
