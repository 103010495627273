import React from "react";
import { PlanCard } from "./PlanCard";
import { Grid } from "@mui/material";

const Planview = (props: any) => {
  return (
    <div>
      {/* <Grid container rowSpacing={1} columnSpacing={5}> */}
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 px-2 gap-5 pt-5">
        {props?.allPlans?.map((item: any) => (
          // <Grid item lg={3} md={4} sm={6} xs={12}>
            <PlanCard selectedPlan={props.selectedPlan} setSelectedPlan={props.setSelectedPlan} item={item} />
            // </Grid>
            ))}
            </div>
      {/* </Grid> */}
    </div>
  );
};

export { Planview };
